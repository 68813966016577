import PRSwitch from "~components/Generic/PRSwitch";

import ProjectSettingsEdit from "./ProjectSettingsEdit";
import ProjectSettingsList from "./ProjectSettingsList";

function ProjectSettings() {
  const routes = [
    { path: "/settings/project/form/:id?", component: ProjectSettingsEdit },
    { path: "/settings/project/", component: ProjectSettingsList },
    { path: "/settings/", to: "/settings/project/", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}

export default ProjectSettings;
