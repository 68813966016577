import { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import styled from "@emotion/styled";
import { Grid, Tab, Tabs } from "@mui/material";

import useLoading from "~common/hooks/useLoading";
import PRChart from "~components/Generic/PRChart";
import chartTheme from "~components/Generic/PRChart/theme.json";
import PalContainer from "~components/mui/PalContainer";
import PalPage from "~components/mui/PalPage";
import ExcelButton from "~components/Statistics/ExcelButton";
import FilterCard from "~components/Statistics/FilterCard";
import AlertHelper from "~helpers/AlertHelper";
import DateHelper from "~helpers/DateHelper";
import FileHelper from "~helpers/FileHelper";
import {
  getAgentAvailableTimeStatistics,
  getAgentLikeDislikeStatistics,
  getAgentRateStatistics,
  getAgentResponseTimeStatistics,
  getAgentSessionCountStatistics,
  getAgentTicketResolveCountStatistics,
  getAgentTicketResolveStatistics,
  getAgentTotalChatTimeStatistics,
} from "~store/statistics/actions";
import { selectAgents, selectAgentSpesificStatistics, selectStatisticsFilter } from "~store/statistics/selectors";

const chartStyle = {
  height: "600px",
  maxHeight: "50vh",
};

const chartTooltip = {
  trigger: "axis",
  formatter: function (params) {
    const date = new Date(params[0].data[0]);
    const formattedDate = date.toLocaleDateString();
    let tooltipText = `${formattedDate}<br/>`;
    if (params.value === 0) {
      return "";
    }
    params.forEach((item) => {
      if (item.value[1] !== 0) {
        tooltipText += `${item.marker} ${item.seriesName}: ${item.data[1]}<br/>`;
      }
    });
    return tooltipText;
  },
};
const chartSecondTimeTooltip = {
  trigger: "axis",
  formatter: function (params) {
    const date = new Date(params[0].data[0]);
    const formattedDate = date.toLocaleDateString();
    let tooltipText = `${formattedDate}<br/>`;
    if (params.value === 0) {
      return "";
    }
    params.forEach((item) => {
      if (item.value[1] !== 0) {
        tooltipText += `${item.marker} ${item.seriesName}: ${DateHelper.formatMoment(
          item.data[1],
          "seconds",
          "d [day] h [hour] m [min] s [sec]"
        )}<br/>`;
      }
    });
    return tooltipText;
  },
};

const AgentSessionRateStatistics = () => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectAgentSpesificStatistics);
  const agents = useSelector(selectAgents);
  const agentIds = useMemo(() => agents.map((agent) => agent.value), [agents]);
  useEffect(() => {
    q(dispatch(getAgentRateStatistics(projectId, agentIds, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate, agentIds]);

  // color: [
  //   chartTheme.theme.color[3],
  //   chartTheme.theme.color[6],
  //   chartTheme.theme.color[2],
  //   chartTheme.theme.color[9],
  //   chartTheme.theme.color[5],
  // ],
  const series = Object.entries(statistics || {})
    .map(([id, value]) => {
      return [
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + " Rating 1",
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_RATING_1 || {}),
          itemStyle: {
            color: chartTheme.theme.color[3],
          },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + " Rating 2",
          type: "bar",
          stack: id,

          data: Object.entries(value?.AGENT_RATING_2 || {}),
          itemStyle: {
            color: chartTheme.theme.color[6],
          },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + " Rating 3",
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_RATING_3 || {}),
          itemStyle: {
            color: chartTheme.theme.color[2],
          },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + " Rating 4",
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_RATING_4 || {}),
          itemStyle: {
            color: chartTheme.theme.color[9],
          },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + " Rating 5",
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_RATING_5 || {}),
          itemStyle: {
            color: chartTheme.theme.color[5],
          },
        },
      ];
    })
    .reduce((acc, cur) => acc.concat(cur), [])
    .filter((s) => agentIds.includes(parseInt(s.stack)));

  const barWidth = 80 / (series.length / 5); //80 optimal width, 5 is the number of stacks

  const options = {
    tooltip: chartTooltip,
    xAxis: {
      type: "time",
      min: filter.beginDate,
      max: filter.endDate,
    },
    yAxis: {
      type: "value",
    },
    series: series.map((series) => ({
      ...series,
      barWidth: `${barWidth}%`,
    })),
  };
  return <PRChart notMerge loading={loading} option={options} style={chartStyle} title="Agent Ratings" />;
};
const AgentSessionResponseTimeStatistics = () => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectAgentSpesificStatistics);
  const agents = useSelector(selectAgents);
  const agentIds = useMemo(() => agents.map((agent) => agent.value), [agents]);
  useEffect(() => {
    q(dispatch(getAgentResponseTimeStatistics(projectId, agentIds, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate, agentIds]);

  const series = Object.entries(statistics || {})
    .map(([id, value]) => {
      return [
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + " 5+ Min",
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_RESPONSE_TIME_300_PLUS || {}),
          itemStyle: {
            color: chartTheme.theme.color[3],
          },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + " 5 Min",
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_RESPONSE_TIME_300 || {}),
          itemStyle: {
            color: chartTheme.theme.color[6],
          },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + " 1 Min",
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_RESPONSE_TIME_60 || {}),
          itemStyle: {
            color: chartTheme.theme.color[2],
          },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + " 30 Sec",
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_RESPONSE_TIME_30 || {}),
          itemStyle: {
            color: chartTheme.theme.color[9],
          },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + " 15 Sec",
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_RESPONSE_TIME_15 || {}),
          itemStyle: {
            color: chartTheme.theme.color[5],
          },
        },
      ];
    })
    .reduce((acc, cur) => acc.concat(cur), [])
    .filter((s) => agentIds.includes(parseInt(s.stack)));

  const barWidth = 80 / (series.length / 5); //80 optimal width, 5 is the number of stacks
  const options = {
    tooltip: chartTooltip,
    xAxis: {
      type: "time",
      min: filter.beginDate,
      max: filter.endDate,
    },
    yAxis: {
      type: "value",
    },
    series: series.map((series) => ({
      ...series,
      barWidth: `${barWidth}%`,
    })),
  };
  return <PRChart notMerge loading={loading} option={options} style={chartStyle} title="Agent Response Time" />;
};
const AgentSessionLikeDislikeStatistics = () => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectAgentSpesificStatistics);
  const agents = useSelector(selectAgents);
  const agentIds = useMemo(() => agents.map((agent) => agent.value), [agents]);
  useEffect(() => {
    q(dispatch(getAgentLikeDislikeStatistics(projectId, agentIds, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate, agentIds]);

  const series = Object.entries(statistics || {})
    .map(([id, value]) => {
      return [
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + " Dislike",
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_DISLIKE_COUNT || {}),
          itemStyle: {
            color: chartTheme.theme.color[3],
          },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + " Like",
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_LIKE_COUNT || {}),
          itemStyle: {
            color: chartTheme.theme.color[5],
          },
        },
      ];
    })
    .reduce((acc, cur) => acc.concat(cur), [])
    .filter((s) => agentIds.includes(parseInt(s.stack)));

  const barWidth = 80 / (series.length / 2); //80 optimal width, 2 is the number of stacks
  const options = {
    tooltip: chartTooltip,
    xAxis: {
      type: "time",
      min: filter.beginDate,
      max: filter.endDate,
    },
    yAxis: {
      type: "value",
    },
    series: series.map((series) => ({
      ...series,
      barWidth: `${barWidth}%`,
    })),
  };
  return <PRChart notMerge loading={loading} option={options} style={chartStyle} title="Agent Like/Dislike" />;
};

const AgentSessionCountStatistics = () => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectAgentSpesificStatistics);
  const agents = useSelector(selectAgents);
  const agentIds = useMemo(() => agents.map((agent) => agent.value), [agents]);
  useEffect(() => {
    q(dispatch(getAgentSessionCountStatistics(projectId, agentIds, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate, agentIds]);

  const series = Object.entries(statistics || {})
    .map(([id, value]) => {
      return [
        {
          name: agents.find((a) => a.value === parseInt(id))?.label,
          type: "line",
          stack: id,
          data: Object.entries(value?.AGENT_SESSION_COUNT || {}),
        },
      ];
    })
    .reduce((acc, cur) => acc.concat(cur), [])
    .filter((s) => agentIds.includes(parseInt(s.stack)));

  const options = {
    tooltip: chartTooltip,
    xAxis: {
      type: "time",
      min: filter.beginDate,
      max: filter.endDate,
    },
    yAxis: {
      type: "value",
    },
    series: series.map((series) => ({
      ...series,
      // barWidth: `${barWidth}%`,
    })),
  };
  return <PRChart notMerge loading={loading} option={options} style={chartStyle} title="Agent Session Count" />;
};

const AgentAvailableTimeStatistics = () => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectAgentSpesificStatistics);
  const agents = useSelector(selectAgents);
  const agentIds = useMemo(() => agents.map((agent) => agent.value), [agents]);
  useEffect(() => {
    q(dispatch(getAgentAvailableTimeStatistics(projectId, agentIds, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate, agentIds]);

  const series = Object.entries(statistics || {})
    .map(([id, value]) => {
      return [
        {
          name: agents.find((a) => a.value === parseInt(id))?.label,
          type: "line",
          stack: id,
          data: Object.entries(value?.AGENT_AVAILABLE_TIME || {}),
        },
      ];
    })
    .reduce((acc, cur) => acc.concat(cur), [])
    .filter((s) => agentIds.includes(parseInt(s.stack)));

  // const barWidth = 100 / series.length;
  const options = {
    tooltip: chartSecondTimeTooltip,
    xAxis: {
      type: "time",
      min: filter.beginDate,
      max: filter.endDate,
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: function (value) {
          return DateHelper.formatMoment(value, "seconds", "h [hrs] m [min]");
        },
      },
    },
    series: series.map((series) => ({
      ...series,
      // barWidth: `${barWidth}%`,
    })),
  };
  return <PRChart notMerge loading={loading} option={options} style={chartStyle} title="Agent Available Time" />;
};
const AgentTotalChatTimeStatistics = () => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectAgentSpesificStatistics);
  const agents = useSelector(selectAgents);
  const agentIds = useMemo(() => agents.map((agent) => agent.value), [agents]);
  useEffect(() => {
    q(dispatch(getAgentTotalChatTimeStatistics(projectId, agentIds, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate, agentIds]);

  const series = Object.entries(statistics || {})
    .map(([id, value]) => {
      return [
        {
          name: agents.find((a) => a.value === parseInt(id))?.label,
          type: "line",
          stack: id,
          data: Object.entries(value?.AGENT_TOTAL_CHAT_TIME || {}),
        },
      ];
    })
    .reduce((acc, cur) => acc.concat(cur), [])
    .filter((s) => agentIds.includes(parseInt(s.stack)));

  // const barWidth = 100 / series.length;
  const options = {
    tooltip: chartSecondTimeTooltip,
    xAxis: {
      type: "time",
      min: filter.beginDate,
      max: filter.endDate,
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: function (value) {
          return DateHelper.formatMoment(value, "seconds", "h [hrs] m [min]");
        },
      },
    },
    series: series.map((series) => ({
      ...series,
      // barWidth: `${barWidth}%`,
    })),
  };
  return <PRChart notMerge loading={loading} option={options} style={chartStyle} title="Agent Total Chat Time" />;
};

const AgentTicketResolveCountStatistics = () => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectAgentSpesificStatistics);
  const agents = useSelector(selectAgents);
  const agentIds = useMemo(() => agents.map((agent) => agent.value), [agents]);
  useEffect(() => {
    q(dispatch(getAgentTicketResolveCountStatistics(projectId, agentIds, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate, agentIds]);

  const series = Object.entries(statistics || {})
    .map(([id, value]) => {
      return [
        {
          name: agents.find((a) => a.value === parseInt(id))?.label,
          type: "line",
          stack: id,
          data: Object.entries(value?.AGENT_TICKET_RESOLVE_COUNT || {}),
        },
      ];
    })
    .reduce((acc, cur) => acc.concat(cur), [])
    .filter((s) => agentIds.includes(parseInt(s.stack)));

  // const barWidth = 100 / series.length;
  const options = {
    tooltip: chartTooltip,
    xAxis: {
      type: "time",
      min: filter.beginDate,
      max: filter.endDate,
    },
    yAxis: {
      type: "value",
    },
    series: series.map((series) => ({
      ...series,
      // barWidth: `${barWidth}%`,
    })),
  };
  return <PRChart notMerge loading={loading} option={options} style={chartStyle} title="Agent Ticket Resolve Count" />;
};

const AgentTicketResolveStatistics = () => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectAgentSpesificStatistics);
  const agents = useSelector(selectAgents);
  const agentIds = useMemo(() => agents.map((agent) => agent.value), [agents]);
  useEffect(() => {
    q(dispatch(getAgentTicketResolveStatistics(projectId, agentIds, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate, agentIds]);

  const series = Object.entries(statistics || {})
    .map(([id, value]) => {
      return [
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + " 15 min.",
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_TICKET_RESOLVE_15 || {}),
          itemStyle: { color: chartTheme.theme.color[5] },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + " 60 min.",
          type: "bar",
          stack: id,

          data: Object.entries(value?.AGENT_TICKET_RESOLVE_60 || {}),
          itemStyle: { color: chartTheme.theme.color[9] },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + " 2 hrs.",
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_TICKET_RESOLVE_120 || {}),
          itemStyle: { color: chartTheme.theme.color[2] },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + " 6 hrs.",
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_TICKET_RESOLVE_360 || {}),
          itemStyle: { color: chartTheme.theme.color[10] },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + " 1 day",
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_TICKET_RESOLVE_1440 || {}),
          itemStyle: { color: chartTheme.theme.color[11] },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + " 2 day",
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_TICKET_RESOLVE_2880 || {}),
          itemStyle: { color: chartTheme.theme.color[6] },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + " 2+ day",
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_TICKET_RESOLVE_2880_PLUS || {}),
          itemStyle: { color: chartTheme.theme.color[3] },
        },
      ];
    })
    .reduce((acc, cur) => acc.concat(cur), [])
    .filter((s) => agentIds.includes(parseInt(s.stack)));

  const barWidth = 80 / (series.length / 7); //80 optimal width, 7 is the number of stacks
  const options = {
    tooltip: chartTooltip,
    xAxis: {
      type: "time",
      min: filter.beginDate,
      max: filter.endDate,
    },
    yAxis: {
      type: "value",
    },
    series: series.map((series) => ({
      ...series,
      barWidth: `${barWidth}%`,
    })),
  };
  return <PRChart notMerge loading={loading} option={options} style={chartStyle} title="Agent Ticket Resolve" />;
};

const StyledTab = styled(Tab)`
  font-weight: 600;
`;

const StyledGrid = styled(Grid)`
  align-items: center;
  display: flex;
  justify-content: center;
  align-self: center;
`;

const AgentStatistics = () => {
  const [tab, setTab] = useState(0);
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const agents = useSelector(selectAgents);

  const agentIds = useMemo(() => agents.map((agent) => agent.value), [agents]);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleExcelExport = async () => {
    let fn;
    switch (tab) {
      case 0:
        fn = getAgentRateStatistics;
        break;
      case 1:
        fn = getAgentResponseTimeStatistics;
        break;
      case 2:
        fn = getAgentLikeDislikeStatistics;
        break;
      case 3:
        fn = getAgentSessionCountStatistics;
        break;
      case 4:
        fn = getAgentAvailableTimeStatistics;
        break;
      case 5:
        fn = getAgentTotalChatTimeStatistics;
        break;
      case 6:
        fn = getAgentTicketResolveCountStatistics;
        break;
      case 7:
        fn = getAgentTicketResolveStatistics;
        break;
      default:
        break;
    }
    if (!fn) {
      AlertHelper.show("There is no data to export.", "error");
      return;
    }

    // get the buffer from fn(), and then save it as a file
    const buffer = await dispatch(fn(projectId, agentIds, filter.beginDate, filter.endDate, true));
    FileHelper.saveAs(buffer, "statistics", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
  };

  return (
    <PalContainer
      description={"Here you can see the statistics of all agents or a specific agent."}
      name="Statistics"
      parentName="Agent Statistics"
    >
      <Grid container>
        <Grid item xs={12}>
          <FilterCard agentFilter />
        </Grid>
      </Grid>
      <PalPage collapse>
        <Grid container rowSpacing={2}>
          <Grid item md xs={10}>
            <Tabs
              sx={(t) => ({
                "& .MuiTabs-flexContainer": {
                  flexWrap: "wrap",
                },
                ".MuiTab-root.Mui-selected": {
                  borderBottom: `2px solid ${t.palette.primary.main}`,
                  paddingBottom: "10px",
                },
              })}
              TabIndicatorProps={{ sx: { display: "none" } }}
              value={tab}
              onChange={handleChangeTab}
            >
              <StyledTab label="Agent Ratings" />
              <StyledTab label="Agent Response Time" />
              <StyledTab label="Agent Like/Dislike" />
              <StyledTab label="Agent Session Count" />
              <StyledTab label="Agent Available Time" />
              <StyledTab label="Agent Total Chat Time" />
              <StyledTab label="Agent Ticket Resolve Count" />
              <StyledTab label="Agent Ticket Resolve" />
            </Tabs>
          </Grid>
          <StyledGrid item md={"auto"} xs={2}>
            <ExcelButton onClick={handleExcelExport} />
          </StyledGrid>

          <Grid item xs={12}>
            {tab === 0 && <AgentSessionRateStatistics />}
            {tab === 1 && <AgentSessionResponseTimeStatistics />}
            {tab === 2 && <AgentSessionLikeDislikeStatistics />}
            {tab === 3 && <AgentSessionCountStatistics />}
            {tab === 4 && <AgentAvailableTimeStatistics />}
            {tab === 5 && <AgentTotalChatTimeStatistics />}
            {tab === 6 && <AgentTicketResolveCountStatistics />}
            {tab === 7 && <AgentTicketResolveStatistics />}
          </Grid>
        </Grid>
      </PalPage>
    </PalContainer>
  );
};

export default AgentStatistics;
