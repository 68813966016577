import { lazy, Suspense, useCallback, useEffect, useMemo, useState } from "react";

import chroma from "chroma-js";
import { cloneDeep, set } from "lodash";
import { SketchPicker } from "react-color";
import { useTranslation } from "react-i18next";
import {
  MdAdd,
  MdChat,
  MdDelete,
  MdHelp,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdPreview,
  MdRestartAlt,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { ButtonGroup, Col, Label, Row } from "reactstrap";

import styled from "@emotion/styled";
import { Help } from "@mui/icons-material";
import { Box, Switch } from "@mui/material";

import { getBotSettings } from "~apiRequests/bot";
import PalamarLogo from "~assets/images/logo-filled-notext.svg";
import { ReactComponent as BotSvg } from "~assets/images/webchat/chat-bot.svg";
import LegoLottie from "~assets/lottie/lego.json";
import { useStorage } from "~common/hooks/useStorage";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import { PRDividerLabel } from "~components/Generic/PRDivider";
import PRInput from "~components/Generic/PRInput";
import PRPage from "~components/Generic/PRPage";
import PRPopover from "~components/Generic/PRPopover";
import PRProfile, { PRProfileLottie } from "~components/Generic/PRProfile";
import PRSelect from "~components/Generic/PRSelect";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import {
  popupAvatarVisibilityOptions,
  popupChatHeightDefault,
  popupFooterMode,
  popupFooterModeOptions,
  popupIconMarginDefault,
  popupIconSizeDefault,
  popupIconType,
  popupIconTypeOptions,
  popupLottieOffsetXDefault,
  popupLottieOffsetYDefault,
  popupMinimizeExistingChatOptions,
  popupPosition,
  popupPositionOptions,
  popupSpeechRecognition,
  popupSpeechRecognitionOptions,
  popupTriggerType,
  popupTriggerTypeOptions,
} from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import DialogHelper from "~helpers/DialogHelper";
import FileHelper from "~helpers/FileHelper";
import Utils from "~helpers/Utils";
import { selectSidebar } from "~store/layout/selectors";
import { patchPopupSettings, setPopupSettingsState } from "~store/settings/popupSettings/actions";
import { selectPopupSettingsState } from "~store/settings/popupSettings/selectors";
import { setMinimizedStatus, setQueryOptions } from "~store/socket/chatbot/actions";
import { selectCurrentBot, selectCurrentProject, selectUserInfo } from "~store/user/selectors";

const WebChat = lazy(() => import("~components/WebChat"));
import "./style.scss";
import { PalSwitch } from "@palamar/fe-library";

export const formItemTypeOptions = [
  { value: "Alpha Numeric", label: "Alpha Numeric" },
  { value: "Email", label: "Email" },
  { value: "Only Numbers", label: "Only Numbers" },
  { value: "Phone Number", label: "Phone Number" },
];

const buttonGroupOptionsDefaultOrIcon = [
  {
    label: "Default",
    value: "DF",
  },
  {
    label: "Icon",
    value: "IC",
  },
];
const languages = {
  TR: "TURKISH",
  EN: "ENGLISH",
  DE: "GERMAN",
  GR: "GREEK",
  AR: "ARABIC",
  UK: "UKRAINE",
  RU: "RUSSIAN",
  PE: "PERSIAN",
  FR: "FRENCH",
};

const StyledPRPagePermission = styled(PRPage)`
  .card {
    background-color: #fff0f0;
    border-radius: 14px;
  }
`;
function PopupSettings() {
  const popupSettings = useSelector(selectPopupSettingsState);
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const sidebar = useSelector(selectSidebar);
  const userInfo = useSelector(selectUserInfo);

  // const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [files, setFiles] = useState({});
  const [projectToken, setProjectToken] = useState(null);
  const [showPopupPreview, setShowPopupPreview] = useStorage("pal_popup_preview", true);

  // const handleTogglePreview = () => {
  //   setIsPreviewVisible(!isPreviewVisible);
  // };

  const handleFileChange = (isLottie) => async (file) => {
    if (!isLottie) {
      const img = await FileHelper.getImage(file);
      const width = img.width;
      const height = img.height;
      if (width !== height || width < 144) {
        AlertHelper.showError("Header icon size must be greater than 144x144 and be square");
        return;
      }
    } else {
      const lottieObj = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          let json;
          try {
            json = JSON.parse(e.target.result);
          } catch {}
          resolve(json);
        };
        reader.readAsText(file);
      });
      if (!lottieObj?.v) {
        AlertHelper.showError("Lottie file is not valid");
        return;
      }
    }

    setFiles({ ...files, popup_button_icon_path: [file] });
    if (popupSettings?.popup_style_info?.popup_icon_type === popupIconType.lottie) {
      dispatch(setPopupSettingsState("popup_button_icon_path", file));
    } else {
      const blobUri = URL.createObjectURL(file);
      dispatch(setPopupSettingsState("popup_button_icon_path", blobUri));
    }
  };

  const handleHeaderFileChange = async (file) => {
    const img = await FileHelper.getImage(file);
    const width = img.width;
    const height = img.height;
    if (width !== height || width < 144) {
      AlertHelper.showError("Header icon size must be greater than 144x144 and be square");
      return;
    }

    setFiles({ ...files, popup_header_icon_path: [file] });
    const blobUri = URL.createObjectURL(file);
    dispatch(setPopupSettingsState("popup_header_icon_path", blobUri));
  };
  const handleBotJoinedFileChange = async (file) => {
    const img = await FileHelper.getImage(file);
    const width = img.width;
    const height = img.height;
    if (width !== height || width < 144) {
      AlertHelper.showError("Bot joined icon size must be greater than 144x144 and be square");
      return;
    }

    setFiles({ ...files, popup_bot_icon_path: [file] });
    const blobUri = URL.createObjectURL(file);
    dispatch(setPopupSettingsState("popup_bot_icon_path", blobUri));
  };
  const validate = (showAlert = true) => {
    if (popupSettings.chatbot_languages.length === 0) {
      showAlert && AlertHelper.show(t("project.settings.emptyLanguage"), "error");
      return false;
    }

    if (popupSettings.popup_title.length === 0) {
      showAlert && AlertHelper.show(t("project.settings.emptyTitle"), "error");
      return false;
    }

    if (popupSettings.welcome_form.form_items.length === 0) {
      showAlert && AlertHelper.show(t("project.settings.emptyWelcomeForm"), "error");
      return false;
    }

    for (let index in popupSettings.welcome_form.form_items) {
      if (
        popupSettings.welcome_form.form_items[index].form_key.length === 0 ||
        popupSettings.welcome_form.form_items[index].form_label.length === 0
      ) {
        showAlert && AlertHelper.show(t("project.settings.invalidWelcomeForm"), "error");
        return false;
      }
    }

    if (
      popupSettings?.popup_style_info?.popup_icon_type !== popupIconType.default &&
      popupSettings.popup_button_icon_path === null
    ) {
      showAlert && AlertHelper.show(t("project.settings.emptyButtonIcon"), "error");
      return false;
    }

    if (
      popupSettings?.popup_style_info?.popup_header_icon_type === popupIconType.icon &&
      popupSettings.popup_header_icon_path === null
    ) {
      showAlert && AlertHelper.show(t("project.settings.emptyHeaderIcon"), "error");
      return false;
    }

    if (
      popupSettings?.popup_style_info?.popup_bot_icon_type === popupIconType.icon &&
      popupSettings.popup_bot_icon_path === null
    ) {
      showAlert && AlertHelper.show(t("project.settings.emptyBotIcon"), "error");
      return false;
    }
    return true;
  };
  const handleClickSave = async () => {
    if (!validate()) return;
    const payload = cloneDeep(popupSettings);

    if (popupSettings?.popup_style_info?.popup_margin) {
      delete payload.popup_style_info.popup_margin;
    }
    await dispatch(
      patchPopupSettings(currentProject.id, popupSettings.id, payload, FileHelper.getFormDataByFileObj(files))
    );

    console.log(popupSettings);
  };

  const handleChange = useCallback(
    (key) => (e) => {
      const newState = cloneDeep(popupSettings);
      newState.popup_style_info ??= {};

      let value = e.target?.value;
      if (key.startsWith("chatbot_info.")) {
        const subKey = key.replace("chatbot_info.", "");
        if (subKey === "footer_mode") {
          value = e;
        } else if (subKey === "avatar_visibility") {
          value = e;
        } else if (subKey === "speech_recognition_mode") {
          value = e;
        } else if (subKey === "speech_recognition_auto_send") {
          value = e.target.checked;
        } else if (subKey === "minimize_existing_Chat") {
          value = e;
        } else if (subKey === "disable_lazy_load") {
          value = e.target.checked;
        }
      } else if (key.startsWith("system_chatbot_info.")) {
        const subKey = key.replace("system_chatbot_info.", "");
        if (subKey === "enable_footer_customization") {
          value = e.target.checked;
          if (!value) {
            newState.chatbot_info ??= {};
            newState.chatbot_info.footer_mode = popupFooterMode.visible;
          }
        }
      } else if (key === "popup_style_info.popup_trigger_type_payload") {
        value = Math.min(Math.max(value, 0), 10_000);
      } else if (key === "popup_primary_color") {
        value = e.hex;
        const light = chroma(value).brighten(1.5).hex();
        const dark = chroma(value).darken(0.7).hex();
        const titleColor = Utils.determineTextColor(value);
        const contrastColor = Utils.determineTextColor(light);

        newState.popup_style_info.primary_light = light;
        newState.popup_style_info.primary_dark = dark;
        newState.popup_style_info.contrast_color = contrastColor;
        newState.popup_style_info.title_color = titleColor;
      } else if (key === "popup_style_info.title_color") {
        value = e.hex;
      } else if (key === "popup_style_info.contrast_color") {
        value = e.hex;
      } else if (key === "popup_style_info.primary_light") {
        value = e.hex;
      } else if (key === "welcome_form.optional") {
        value = !e.target.checked;
      } else if (
        //select types
        [
          "popup_style_info.popup_position",
          "popup_style_info.popup_header_icon_type",
          "popup_style_info.popup_bot_icon_type",
          "popup_style_info.popup_icon_type",
          "popup_style_info.popup_trigger_type",
        ].includes(key)
      ) {
        if (key === "popup_style_info.popup_icon_type") {
          if (e === popupIconType.icon) {
            newState.popup_button_icon_path = PalamarLogo;
            Utils.urlToFile(PalamarLogo, "defaultHeaderIcon.png").then((file) => {
              setFiles((prevData) => ({ ...prevData, popup_button_icon_path: [file] }));
            });
          } else if (e === popupIconType.lottie) {
            // LegoLottie to plain/text
            const file = new File([JSON.stringify(LegoLottie)], "lottie.json", { type: "text/plain" });
            newState.popup_button_icon_path = file;
            setFiles((prevData) => ({ ...prevData, popup_button_icon_path: [file] }));
          }
        } else if (key === "popup_style_info.popup_header_icon_type") {
          if (e === popupIconType.icon) {
            newState.popup_header_icon_path = PalamarLogo;
            Utils.urlToFile(PalamarLogo, "defaultHeaderIcon.png").then((file) => {
              setFiles((prevData) => ({ ...prevData, popup_header_icon_path: [file] }));
            });
          }
        } else if (key === "popup_style_info.popup_bot_icon_type") {
          if (e === popupIconType.icon) {
            newState.popup_bot_icon_path = PalamarLogo;
            Utils.urlToFile(PalamarLogo, "defaultHeaderIcon.png").then((file) => {
              setFiles((prevData) => ({ ...prevData, popup_bot_icon_path: [file] }));
            });
          }
        }
        value = e;
      } else if (
        [
          "popup_style_info.popup_margin_y",
          "popup_style_info.popup_margin_x",
          "popup_style_info.chat_height",
          "popup_style_info.popup_icon_size",
          "popup_style_info.lottie_offset_x",
          "popup_style_info.lottie_offset_y",
          "popup_style_info.lottie_width",
          "popup_style_info.lottie_height",
          "popup_style_info.lottie_loop_delay",
          "popup_style_info.lottie_loop_speed",
          "popup_style_info.popup_ballon_timeout",
        ].includes(key) &&
        e.target.value !== ""
      ) {
        value = Number(e.target.value);
        if (key === "popup_style_info.popup_margin_x" || key === "popup_style_info.popup_margin_y") {
          value = Math.min(Math.max(value, -64), 512);
        } else if (key === "popup_style_info.chat_height") {
          value = Math.min(Math.max(value, 0), 1440);
        } else if (key === "popup_style_info.popup_icon_size") {
          value = Math.min(Math.max(value, 0), 512);
        } else if (key === "popup_style_info.lottie_offset_x") {
          value = Math.min(Math.max(value, -100), 100);
        } else if (key === "popup_style_info.lottie_offset_y") {
          value = Math.min(Math.max(value, -100), 100);
        } else if (key === "popup_style_info.lottie_width") {
          value = Math.min(Math.max(value, 0), 512);
        } else if (key === "popup_style_info.lottie_height") {
          value = Math.min(Math.max(value, 0), 512);
        } else if (key === "popup_style_info.lottie_loop_delay") {
          value = Math.min(Math.max(value, 0), 100000);
        } else if (key === "popup_style_info.lottie_loop_speed") {
          value = Math.min(Math.max(value, 0.1), 10);
        } else if (key === "popup_style_info.popup_ballon_timeout") {
          value = Math.min(Math.max(value, 0), 100000);
        }
      }
      // dispatch(setPopupSettingsState(key, value));
      set(newState, key, value);
      dispatch(setPopupSettingsState(null, newState));
    },
    [dispatch, popupSettings]
  );

  const handleChangeButtonGroup = useCallback(
    (key) => (value) => {
      dispatch(setPopupSettingsState(key, value));
    },
    [dispatch]
  );
  const handleAddWelcomeForm = () => {
    const newWelcomeForm = [
      ...popupSettings.welcome_form.form_items,
      {
        form_label: "",
        form_key: "",
        is_identifier: false,
        is_optional: false,
        form_item_type: formItemTypeOptions[0].value,
        project: currentProject.id,
      },
    ];
    dispatch(setPopupSettingsState("welcome_form.form_items", newWelcomeForm));
  };

  const handleResetPopupStyle = () => {
    const primaryColor = "#28AAE1";
    const light = chroma(primaryColor).brighten(1.5).hex();
    const dark = chroma(primaryColor).darken(0.7).hex();
    const titleColor = Utils.determineTextColor(primaryColor);
    const contrastColor = Utils.determineTextColor(light);

    const newStyle = {
      ...popupSettings.popup_style_info,
      primary_light: light,
      primary_dark: dark,
      contrast_color: contrastColor,
      title_color: titleColor,
      popup_icon_size: popupIconSizeDefault,
      /** @deprecated Use popup_margin_x and popup_margin_y instead and remove popup_margin on save */
      popup_margin: popupIconMarginDefault,
      popup_margin_x: popupIconMarginDefault,
      popup_margin_y: popupIconMarginDefault,
      chat_height: popupChatHeightDefault,
      popup_position: popupPosition.bottomRight,
      lottie_width: popupIconSizeDefault,
      lottie_height: popupIconSizeDefault,
      lottie_offset_x: popupLottieOffsetXDefault,
      lottie_offset_y: popupLottieOffsetYDefault,
      lottie_loop_delay: 0,
      lottie_loop_speed: 1,
      popup_ballon_timeout: 0,
      popup_trigger_type: popupTriggerType.instant,
      popup_trigger_type_payload: 0,
    };
    const state = {
      ...popupSettings,
      popup_primary_color: primaryColor,
      popup_style_info: newStyle,
    };

    dispatch(setPopupSettingsState(null, state));
  };

  const handleAddLanguage = (langCodeList) => {
    const newChatbotLanguages = [...langCodeList];
    dispatch(setPopupSettingsState("chatbot_languages", newChatbotLanguages));
  };

  useEffect(() => {
    getBotSettings(currentProject.id, currentBot.id).then((response) => {
      setProjectToken(response?.project_token?.token);
    });
  }, [currentProject.id, currentBot.id]);

  useEffect(() => {
    if (projectToken) {
      dispatch(
        setQueryOptions({
          token: projectToken,
          previewMode: true,
        })
      );
      setTimeout(() => {
        dispatch(setMinimizedStatus(true));
      }, 100);
    }
  }, [dispatch, projectToken]);

  const columns = useMemo(() => {
    const handleChangeWelcomeForm = (key, item) => (e) => {
      const newWelcomeForm = popupSettings.welcome_form.form_items.map((formItem) => {
        if (formItem === item) {
          let value = e.target ? e.target?.value : e;
          if (key === "is_identifier" || key === "is_optional") {
            value = e.target.checked;
          }
          return { ...formItem, [key]: value };
        }
        return formItem;
      });
      dispatch(setPopupSettingsState("welcome_form.form_items", newWelcomeForm));
    };
    const handleChangeMove = (index, direction) => () => {
      const targetIndex = direction === "up" ? index - 1 : index + 1;

      const newWelcomeForm = [...popupSettings.welcome_form.form_items];

      if (targetIndex < 0 || targetIndex >= newWelcomeForm.length) return;
      const sortedData = Utils.arrayMove(newWelcomeForm, index, targetIndex);
      dispatch(setPopupSettingsState("welcome_form.form_items", sortedData));
    };

    const handleDelete = (row) => async () => {
      const isEmptyRow = !row.form_label?.length && !row.form_key?.length;
      if (!isEmptyRow && !(await DialogHelper.showQuestionDelete())) return;

      const newWelcomeForm = popupSettings.welcome_form.form_items.filter((formItem) => formItem !== row);
      dispatch(setPopupSettingsState("welcome_form.form_items", newWelcomeForm));
    };
    return [
      {
        label: "Label",
        key: "form_label",
        render: (row) => <PRInput value={row.form_label} onChange={handleChangeWelcomeForm("form_label", row)} />,
      },
      {
        label: "Key",
        key: "form_key",
        render: (row) => {
          return <PRInput name="form_key" value={row.form_key} onChange={handleChangeWelcomeForm("form_key", row)} />;
        },
      },
      {
        label: "Field Type",
        key: "field_type",
        style: { minWidth: "160px" },
        render: (row) => {
          return (
            <PRSelect
              isPrimitiveValue
              menuPortal
              defaultValue={formItemTypeOptions[0]}
              isClearable={false}
              options={formItemTypeOptions}
              value={row.form_item_type}
              onChange={handleChangeWelcomeForm("form_item_type", row)}
            />
          );
        },
      },
      {
        label: "Identifier",
        key: "is_identifier",
        render: (row) => (
          <PRInput
            onChange={handleChangeWelcomeForm("is_identifier", row)}
            type="checkbox"
            // disabled={row.readonly || row.identifier}
            checked={row.is_identifier}
          />
        ),
      },
      {
        label: "Is Optional",
        key: "is_optional",
        render: (row) => {
          return (
            <PRInput checked={row.is_optional} type="checkbox" onChange={handleChangeWelcomeForm("is_optional", row)} />
          );
        },
      },
      {
        fixed: "right",
        label: "Actions",
        key: "actions",
        actions: true,
        render: (row, index) => (
          <div className="d-flex justify-content-center">
            <ButtonGroup>
              <PRButton
                outline
                color="primary"
                disabled={row.readonly}
                icon={MdKeyboardArrowUp}
                size="sm"
                tooltipText="Move Up"
                onClick={handleChangeMove(index, "up")}
              />

              <PRButton
                outline
                color="primary"
                disabled={row.readonly}
                icon={MdKeyboardArrowDown}
                size="sm"
                tooltipText="Move Down"
                onClick={handleChangeMove(index, "down")}
              />
            </ButtonGroup>
            <PRButton
              outline
              className="ms-1"
              color="danger"
              disabled={row.readonly}
              icon={MdDelete}
              size="sm"
              tooltipText="Delete"
              onClick={handleDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [dispatch, popupSettings.welcome_form.form_items]);

  const parentName = [
    {
      label: `Popup`,
    },
  ];

  const handleOnBlurValidate = (key) => () => {
    const styleInfo = popupSettings?.popup_style_info;
    if (key === "popup_style_info.popup_margin_x" || key === "popup_style_info.popup_margin_y") {
      const keyXOrY = key === "popup_style_info.popup_margin_x" ? "popup_margin_x" : "popup_margin_y";
      handleChange(key)({ target: { value: Math.min(Math.max(styleInfo?.[keyXOrY], -64), 512) } });
    } else if (key === "popup_style_info.chat_height") {
      handleChange(key)({ target: { value: Math.min(Math.max(styleInfo?.chat_height, 500), 1440) } });
    } else if (key === "popup_style_info.popup_icon_size") {
      handleChange(key)({ target: { value: Math.min(Math.max(styleInfo?.popup_icon_size, 32), 256) } });
    }
  };

  const handleClickShowHidePopupPreview = () => {
    setShowPopupPreview(!showPopupPreview);
  };
  return (
    <PRContainer
      description={"Here you can change the appearance of your popup."}
      name="Settings"
      parentName={parentName}
    >
      <PRPage
        actions={[
          {
            icon: MdPreview,
            color: "primary",
            onClick: handleClickShowHidePopupPreview,
            label: "Show/Hide Preview",
          },
        ]}
        className="pr-popup-chat-settings"
        title={"Popup Settings"}
      >
        <Row className="mt-0 g-3">
          <Col lg="6" md="12">
            <Label>Title</Label>
            <PRInput value={popupSettings?.popup_title} onChange={handleChange("popup_title")} />
          </Col>
          <Col lg="6" md="12">
            <Label> Welcome Title</Label>
            <PRInput
              value={popupSettings?.popup_style_info?.welcome_title || t("chatbot.welcomePage.title")}
              onChange={handleChange("popup_style_info.welcome_title")}
            />
          </Col>
          <Col lg="6" md="12">
            <Label> Welcome Message</Label>
            <PRInput value={popupSettings?.popup_welcome_text} onChange={handleChange("popup_welcome_text")} />
          </Col>
          <Col lg="6" md="12">
            <Label>Languages</Label>
            <PRSelect
              isMulti
              isPrimitiveValue
              options={Object.entries(languages).map(([key, value]) => ({ value: key, label: value }))}
              value={popupSettings?.chatbot_languages}
              onChange={handleAddLanguage}
            />
          </Col>
          <Col lg="6" md="12">
            <Label>Balloon message</Label>
            <PRInput value={popupSettings?.welcome_msg} onChange={handleChange("welcome_msg")} />
          </Col>
          <Col lg="6" md="12">
            <Label>
              Balloon Timeout(Sec){" "}
              <PRTooltip title="The time in seconds that the balloon message will be displayed. If the value is 0, the balloon message will be displayed until the user closes it.">
                <Help
                  sx={{
                    fontSize: "1rem",
                  }}
                />
              </PRTooltip>
            </Label>
            <PRInput
              type="number"
              value={popupSettings?.popup_style_info?.popup_ballon_timeout}
              onBlur={handleOnBlurValidate("popup_style_info.popup_ballon_timeout")}
              onChange={handleChange("popup_style_info.popup_ballon_timeout")}
            />
          </Col>
          <Col className="pt-1" lg="4" md="12">
            <Label>Popup Button</Label>
            <PRTooltip title="Tip: You can use a .webp image for better performance and reduce bot loading time.">
              <span>
                <MdHelp className="ms-1 fs-6" />
              </span>
            </PRTooltip>
            <div>
              <PRSelect
                isPrimitiveValue
                className="mb-2"
                isClearable={false}
                options={popupIconTypeOptions}
                value={popupSettings?.popup_style_info?.popup_icon_type}
                onChange={handleChange("popup_style_info.popup_icon_type")}
              />

              {popupSettings?.popup_style_info?.popup_icon_type === popupIconType.default && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: popupSettings?.popup_primary_color,
                    color: "white",
                    width: "5rem",
                    height: "5rem",
                    borderRadius: "50%",
                  }}
                >
                  <MdChat style={{ fontSize: "2.3rem" }} />
                </div>
              )}

              {popupSettings?.popup_style_info?.popup_icon_type === popupIconType.icon && (
                <PRProfile img={popupSettings?.popup_button_icon_path} onFileChange={handleFileChange(false)} />
              )}
              {popupSettings?.popup_style_info?.popup_icon_type === popupIconType.lottie && (
                <PRProfileLottie img={popupSettings?.popup_button_icon_path} onFileChange={handleFileChange(true)} />
              )}
            </div>
          </Col>
          <Col className="pt-1" lg="4" md="12">
            <Label>Header Icon</Label>
            <div>
              <PRSelect
                isPrimitiveValue
                className="mb-2"
                isClearable={false}
                options={popupIconTypeOptions.filter((item) => item.value !== popupIconType.lottie)}
                value={popupSettings?.popup_style_info?.popup_header_icon_type}
                onChange={handleChange("popup_style_info.popup_header_icon_type")}
              />
              {popupSettings?.popup_style_info?.popup_header_icon_type === popupIconType.default && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: popupSettings?.popup_primary_color,
                    color: "white",
                    width: "5rem",
                    height: "5rem",
                    borderRadius: "50%",
                  }}
                >
                  <MdChat style={{ fontSize: "2.3rem" }} />
                </div>
              )}

              {popupSettings?.popup_style_info.popup_header_icon_type === popupIconType.icon && (
                <PRProfile img={popupSettings?.popup_header_icon_path} onFileChange={handleHeaderFileChange} />
              )}
            </div>
          </Col>
          <Col className="pt-1" lg="4" md="12">
            <Label>Bot Joined Icon</Label>
            <div>
              <PRSelect
                isPrimitiveValue
                className="mb-2"
                isClearable={false}
                options={popupIconTypeOptions.filter((item) => item.value !== popupIconType.lottie)}
                value={popupSettings?.popup_style_info?.popup_bot_icon_type}
                onChange={handleChange("popup_style_info.popup_bot_icon_type")}
              />
              {popupSettings?.popup_style_info?.popup_bot_icon_type === popupIconType.default && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#ffffff",
                    color: "white",
                    width: "5rem",
                    height: "5rem",
                    borderRadius: "50%",
                  }}
                >
                  <BotSvg className="icon-circle__bot" />
                </div>
              )}

              {popupSettings?.popup_style_info.popup_bot_icon_type === popupIconType.icon && (
                <PRProfile circle img={popupSettings?.popup_bot_icon_path} onFileChange={handleBotJoinedFileChange} />
              )}
            </div>
          </Col>
          <Col lg="4" md="12">
            <Label>Show Up Mode</Label>

            <PRSelect
              isPrimitiveValue
              isClearable={false}
              options={popupTriggerTypeOptions}
              value={popupSettings?.popup_style_info?.popup_trigger_type}
              onChange={handleChange("popup_style_info.popup_trigger_type")}
            />
            {popupSettings?.popup_style_info?.popup_trigger_type === popupTriggerType.instant && (
              <div className="d-flex align-items-center"></div>
            )}
            {popupSettings?.popup_style_info?.popup_trigger_type === popupTriggerType.delayed && (
              <div className="d-flex align-items-center mt-2">
                <PRInput
                  className="w-100 pe-2"
                  type="number"
                  value={popupSettings?.popup_style_info?.popup_trigger_type_payload}
                  onChange={handleChange("popup_style_info.popup_trigger_type_payload")}
                />
                <div>Second</div>
              </div>
            )}

            {popupSettings?.popup_style_info?.popup_trigger_type === popupTriggerType.scroll && (
              <div className="d-flex align-items-center mt-2">
                <PRInput
                  className="w-100 pe-2"
                  type="number"
                  value={popupSettings?.popup_style_info?.popup_trigger_type_payload}
                  onChange={handleChange("popup_style_info.popup_trigger_type_payload")}
                />
                <div>px</div>
              </div>
            )}
          </Col>
          <Col lg="4" md="12">
            <Label>
              Footer Mode
              {!popupSettings?.system_chatbot_info?.enable_footer_customization && (
                <PRTooltip title="Please contact the administrator to enable footer customization.">
                  <span>
                    <MdHelp className="ms-1 fs-6" />
                  </span>
                </PRTooltip>
              )}
            </Label>
            <PRSelect
              isPrimitiveValue
              isClearable={false}
              isDisabled={!popupSettings?.system_chatbot_info?.enable_footer_customization}
              options={popupFooterModeOptions}
              value={popupSettings?.chatbot_info?.footer_mode}
              onChange={handleChange("chatbot_info.footer_mode")}
            />
          </Col>
          <Col lg="4" md="12">
            <Label>Avatar Visibility</Label>
            <PRSelect
              isPrimitiveValue
              isClearable={false}
              options={popupAvatarVisibilityOptions}
              value={popupSettings?.chatbot_info?.avatar_visibility}
              onChange={handleChange("chatbot_info.avatar_visibility")}
            />
          </Col>

          <Col lg="4" md="12">
            <Label>Speech Recognition Mode</Label>
            <PRSelect
              isPrimitiveValue
              isClearable={false}
              options={popupSpeechRecognitionOptions}
              value={popupSettings?.chatbot_info?.speech_recognition_mode}
              onChange={handleChange("chatbot_info.speech_recognition_mode")}
            />
            {popupSettings?.chatbot_info?.speech_recognition_mode === popupSpeechRecognition.enabled && (
              <div>
                <Switch
                  checked={popupSettings?.chatbot_info?.speech_recognition_auto_send}
                  onChange={handleChange("chatbot_info.speech_recognition_auto_send")}
                />
                <Label>Auto Send After Recognition</Label>
                <PRTooltip title="Automatically send the message after the speech recognition is completed for the user.">
                  <span>
                    <MdHelp className="ms-1 fs-6" />
                  </span>
                </PRTooltip>
              </div>
            )}
          </Col>
          <Col lg="4" md="12">
            <Label>
              Minimize existing chat
              <PRTooltip title="By default, the chat will automatically open in a new tab as maximized if the session is already active. Enable this option to allow the chat window to minimize, even when the session is ongoing.">
                <span>
                  <MdHelp className="ms-1 fs-6" />
                </span>
              </PRTooltip>
            </Label>
            <PRSelect
              isPrimitiveValue
              isClearable={false}
              options={popupMinimizeExistingChatOptions}
              value={popupSettings?.chatbot_info?.minimize_existing_Chat}
              onChange={handleChange("chatbot_info.minimize_existing_Chat")}
            />
          </Col>
          <Col lg="4" md="12">
            <Label>
              Disable lazy loading
              <PRTooltip title="Bot chat will be loaded lazily. If you have any issues with the lazy loading, you can disable it.">
                <span>
                  <MdHelp className="ms-1 fs-6" />
                </span>
              </PRTooltip>
            </Label>
            <Box>
              <PalSwitch
                checked={popupSettings?.chatbot_info?.disable_lazy_load}
                onChange={handleChange("chatbot_info.disable_lazy_load")}
              />
            </Box>
          </Col>
        </Row>
      </PRPage>
      {/* <PRDivider className="my-3" color={"secondary-400"} /> */}

      <PRPage
        actions={[
          {
            icon: MdRestartAlt,
            color: "primary",
            onClick: handleResetPopupStyle,
            tooltipText: "Reset to default",
          },
        ]}
        className="pr-popup-chat-settings"
        title={"Popup Style"}
      >
        <Row className="mt-0 g-3">
          <Col lg="3" md="12">
            <Label>Primary Color</Label>
            <PRPopover
              content={
                <>
                  <SketchPicker
                    color={popupSettings?.popup_primary_color}
                    onChange={handleChange("popup_primary_color")}
                  />
                </>
              }
            >
              <div className="form-control form-control-color mw-100">
                <div
                  className="form-control"
                  style={{
                    backgroundColor: popupSettings?.popup_primary_color,
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
              {/* <MdColorize /> */}
            </PRPopover>
          </Col>
          <Col lg="3" md="12">
            <Label>Secondary Color</Label>
            <PRPopover
              content={
                <>
                  <SketchPicker
                    color={popupSettings?.popup_style_info?.primary_light}
                    onChange={handleChange("popup_style_info.primary_light")}
                  />
                </>
              }
            >
              <div className="form-control form-control-color mw-100">
                <div
                  className="form-control"
                  style={{
                    backgroundColor: popupSettings?.popup_style_info?.primary_light || "#ffffff",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            </PRPopover>
          </Col>
          <Col lg="3" md="12">
            <Label>Title Color</Label>
            <PRPopover
              content={
                <SketchPicker
                  color={popupSettings?.popup_style_info?.title_color}
                  onChange={handleChange("popup_style_info.title_color")}
                />
              }
            >
              <div className="form-control form-control-color mw-100">
                <div
                  className="form-control"
                  style={{
                    backgroundColor: popupSettings?.popup_style_info?.title_color || "#ffffff",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            </PRPopover>
          </Col>
          <Col lg="3" md="12">
            <Label>Contrast Color</Label>
            <PRPopover
              content={
                <SketchPicker
                  color={popupSettings?.popup_style_info?.contrast_color}
                  onChange={handleChange("popup_style_info.contrast_color")}
                />
              }
            >
              <div className="form-control form-control-color mw-100">
                <div
                  className="form-control"
                  style={{
                    backgroundColor: popupSettings?.popup_style_info?.contrast_color || "#ffffff",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            </PRPopover>
          </Col>
          <Col lg="3" md="12">
            <Label>Popup Position</Label>
            <PRSelect
              isPrimitiveValue
              isClearable={false}
              options={popupPositionOptions}
              value={popupSettings?.popup_style_info?.popup_position}
              onChange={handleChange("popup_style_info.popup_position")}
            />
          </Col>
          {/* <Col lg="3" md="12">
            <Label>Popup Margin(px)</Label>
            <PRInput
              type="number"
              value={popupSettings?.popup_style_info?.popup_margin}
              onBlur={handleOnBlurValidate("popup_style_info.popup_margin")}
              onChange={handleChange("popup_style_info.popup_margin")}
            />
          </Col> */}
          <Col lg="3" md="12">
            <Label>Popup Margin X(px)</Label>
            <PRInput
              type="number"
              value={popupSettings?.popup_style_info?.popup_margin_x ?? popupSettings?.popup_style_info?.popup_margin}
              onBlur={handleOnBlurValidate("popup_style_info.popup_margin_x")}
              onChange={handleChange("popup_style_info.popup_margin_x")}
            />
          </Col>
          <Col lg="3" md="12">
            <Label>Popup Margin Y(px)</Label>
            <PRInput
              type="number"
              value={popupSettings?.popup_style_info?.popup_margin_y ?? popupSettings?.popup_style_info?.popup_margin}
              onBlur={handleOnBlurValidate("popup_style_info.popup_margin_y")}
              onChange={handleChange("popup_style_info.popup_margin_y")}
            />
          </Col>
          <Col lg="3" md="12">
            <Label>Popup Size(px)</Label>
            <PRInput
              type="number"
              value={popupSettings?.popup_style_info?.popup_icon_size}
              onBlur={handleOnBlurValidate("popup_style_info.popup_icon_size")}
              onChange={handleChange("popup_style_info.popup_icon_size")}
            />
          </Col>
          <Col lg="3" md="12">
            <Label>Chat Height(px)</Label>
            <PRInput
              type="number"
              value={popupSettings?.popup_style_info?.chat_height}
              onBlur={handleOnBlurValidate("popup_style_info.chat_height")}
              onChange={handleChange("popup_style_info.chat_height")}
            />
          </Col>
        </Row>
        {popupSettings?.popup_style_info?.popup_icon_type === popupIconType.lottie && (
          <>
            <PRDividerLabel className="mt-3" color={"secondary"}>
              Lottie Options
            </PRDividerLabel>
            <Row className="mt-0 g-3">
              <Col lg="2" md="12">
                <Label>Width</Label>
                <PRInput
                  type="number"
                  value={popupSettings?.popup_style_info?.lottie_width}
                  onBlur={handleOnBlurValidate("popup_style_info.lottie_width")}
                  onChange={handleChange("popup_style_info.lottie_width")}
                />
              </Col>
              <Col lg="2" md="12">
                <Label>Height</Label>
                <PRInput
                  type="number"
                  value={popupSettings?.popup_style_info?.lottie_height}
                  onBlur={handleOnBlurValidate("popup_style_info.lottie_height")}
                  onChange={handleChange("popup_style_info.lottie_height")}
                />
              </Col>
              <Col lg="2" md="12">
                <Label>Offset X</Label>
                <PRInput
                  type="number"
                  value={popupSettings?.popup_style_info?.lottie_offset_x}
                  onBlur={handleOnBlurValidate("popup_style_info.lottie_offset_x")}
                  onChange={handleChange("popup_style_info.lottie_offset_x")}
                />
              </Col>
              <Col lg="2" md="12">
                <Label>Offset Y</Label>
                <PRInput
                  type="number"
                  value={popupSettings?.popup_style_info?.lottie_offset_y}
                  onBlur={handleOnBlurValidate("popup_style_info.lottie_offset_y")}
                  onChange={handleChange("popup_style_info.lottie_offset_y")}
                />
              </Col>
              <Col lg="2" md="12">
                <Label>Loop Delay(ms)</Label>
                <PRInput
                  type="number"
                  value={popupSettings?.popup_style_info?.lottie_loop_delay}
                  onBlur={handleOnBlurValidate("popup_style_info.lottie_loop_delay")}
                  onChange={handleChange("popup_style_info.lottie_loop_delay")}
                />
              </Col>
              <Col lg="2" md="12">
                <Label>Speed (1x)</Label>
                <PRInput
                  step={0.1}
                  type="number"
                  value={popupSettings?.popup_style_info?.lottie_loop_speed?.toFixed(1)}
                  onBlur={handleOnBlurValidate("popup_style_info.lottie_loop_speed")}
                  onChange={handleChange("popup_style_info.lottie_loop_speed")}
                />
              </Col>
            </Row>
          </>
        )}
      </PRPage>

      <PRPage
        actions={[
          {
            icon: MdAdd,
            color: "success-400",
            onClick: handleAddWelcomeForm,
            tooltipText: "Add",
          },
        ]}
        className="pr-popup-chat-settings"
        title={"Popup Welcome Form"}
      >
        <div>
          <PRTable inline noPagination columns={columns} data={popupSettings?.welcome_form?.form_items} />
        </div>

        <Row className="mt-1">
          <Col xs>
            <Switch checked={!popupSettings?.welcome_form?.optional} onChange={handleChange("welcome_form.optional")} />
            <Label className="me-2" size="md">
              Mandatory User Identity Form
              <PRTooltip title="Enable this option to require the user to fill out the identity form upon the initial chat.">
                <span>
                  <MdHelp className="ms-1 fs-6" />
                </span>
              </PRTooltip>
            </Label>
          </Col>
          <Col xs="auto">
            <PRButton minSize="md" onClick={handleClickSave}>
              Save
            </PRButton>
          </Col>
        </Row>
      </PRPage>

      {!!userInfo.is_superuser && (
        <StyledPRPagePermission className="pr-popup-chat-settings " title={"Popup Permissions"}>
          <Row className="mt-0 g-3">
            <Col className="d-flex align-items-center" xs="12">
              <Switch
                checked={popupSettings?.system_chatbot_info?.enable_footer_customization}
                onChange={handleChange("system_chatbot_info.enable_footer_customization")}
              />
              Enable Footer Customization
            </Col>
          </Row>
        </StyledPRPagePermission>
      )}

      {showPopupPreview && (
        <div
          // Pretends to be a iframe
          id="preview-chatbot-container"
          style={{
            position: "fixed",
            // paddingTop: 60,
            // bottom: 60,
            bottom: 0,
            zIndex: 1300,
            // transform: "translateY(-60px)",
            overflow: "hidden",
            marginLeft: sidebar.minimized ? 80 : 240, //sidebar-width
          }}
        >
          <Suspense>
            <WebChat previewMode />
          </Suspense>
        </div>
      )}
    </PRContainer>
  );
}

export default PopupSettings;
