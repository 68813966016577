import { useRef, useState } from "react";

import { withCardon } from "cardon";
import { MdAdd, MdInfo, MdWarning } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "reactstrap";

import useLoading from "~common/hooks/useLoading";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRDate from "~components/Generic/PRDate";
import PRModal from "~components/Generic/PRModal";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import { apiUrlChatbot, tableFilterStorageKey } from "~constants";
import { implementationStatusMap, intentRecommendTypeMap } from "~constants/intentRecommend";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import { updateRecommendations } from "~store/dialogComponents/intentRecommend/actions";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

const IntentRecommend = () => {
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const tableRef = useRef(null);
  const dispatch = useDispatch();

  const onInfoClick = (sessionId, summary) => () => {
    // console.log("sessionId", sessionId);
    ClusterInfoModal.show({ projectId: currentProject.id, clusterId: sessionId });
  };

  const onAddRecommendationClick = (recommendation) => {
    if (recommendation?.recommendation_type === "SAMPLE") {
      return {
        linkProps: {
          newTab: true,
        },
        link: `/chatbot/intent/form/${recommendation?.intent}/?samples=${encodeURI(
          recommendation?.data.sample_intents.join("\n")
        )}`,
        disabled: !recommendation?.data.sample_intents,
      };
    }
    if (recommendation?.recommendation_type === "ADD_ANSWER") {
      return {
        linkProps: {
          newTab: true,
        },
        link: `/chatbot/intent/form/?samples=${encodeURI(recommendation?.data.sample_intents)}`,
        disabled: !recommendation?.data.sample_intents,
      };
    }
    if (recommendation?.recommendation_type === "INTEGRATION") {
      return {
        onClick: () => {
          IntegrationModal.show({
            summary: recommendation?.data.integration_summary,
            sampleIntents: recommendation?.data.sample_intents,
          });
        },
      };
    }
    if (recommendation?.recommendation_type === "STATIC") {
      return {
        linkProps: {
          newTab: true,
        },
        link: `/chatbot/intent/form/?response=${encodeURI(recommendation?.data.response)}&samples=${encodeURI(
          recommendation?.data.sample_intents
        )}`,
        disabled: !recommendation?.data.response,
      };
    }
    if (recommendation?.recommendation_type === "AGENT_FAILS") {
      return {
        onClick: () => {
          AgentFailModal.show({ reason: recommendation?.data.reason });
        },
      };
    }
    return {};
  };

  const recommendationIconRender = (recommendation) => {
    switch (recommendation?.recommendation_type) {
      case "SAMPLE":
        return MdAdd;
      case "STATIC":
        return MdAdd;
      case "INTEGRATION":
        return MdAdd;
      case "ADD_ANSWER":
        return MdAdd;
      case "AGENT_FAILS":
        return MdWarning;
      default:
        return MdAdd;
    }
  };

  const columns = [
    {
      key: "id",
      label: "ID",
    },
    {
      key: "name",
      label: "Name",
    },
    // {
    //   key: "cluster_type",
    //   label: "Cluster Type",
    //   render: (item) => clusterTypeMap[item.cluster_type] || item.cluster_type,
    // },
    // {
    //   //   noWrap: true,
    //   key: "summary",
    //   label: "Summary",
    //   style: {
    //     maxWidth: "600px",
    //     minWidth: "400px",
    //   },
    //   render: (item) => {
    //     if (item.summary?.length > 500) {
    //       const summaryMin = item.summary.substring(0, 500) + "...";
    //       return (
    //         <PRTooltip placement="bottom" title={item.summary}>
    //           <span>{summaryMin}</span>
    //         </PRTooltip>
    //       );
    //     }
    //     return item.summary;
    //   },
    // },
    // {
    //   key: "cluster_size",
    //   label: "Cluster Size",
    // },
    {
      key: "created",
      label: "Created",
      render: (item) => (
        <div>
          <PRTooltip title={DateHelper.getDateTimeLocal(item.created).format("LLT")}>
            <span>{DateHelper.getDateTimeLocal(item.created).fromNow()}</span>
          </PRTooltip>
        </div>
      ),
    },
    {
      key: "recommendation_type",
      label: "Recommendation Type",
      render: (item) => intentRecommendTypeMap[item.recommendation_type] || item.recommendation_type,
    },
    {
      key: "implementation_status",
      label: "Implementation Status",
      render: (item) => implementationStatusMap[item.implementation_status] || item.implementation_status,
    },
    {
      key: "actions",
      label: "Actions",
      actions: true,
      fixed: "right",
      render: (row) => (
        <div className="d-flex gap-1 justify-content-center">
          <PRButton
            outline
            color="primary"
            icon={MdInfo}
            size="sm"
            tooltipText="Info"
            onClick={onInfoClick(row?.id, row?.summary)}
          />
          {row.recommendation_type && (
            <PRButton
              outline
              color="secondary"
              icon={recommendationIconRender(row)}
              size="sm"
              tooltipText="Add Recommendation"
              {...onAddRecommendationClick(row)}
            />
          )}
        </div>
      ),
    },
  ];

  const actions = [
    // {
    //   label: "Update Clusters",
    //   onClick: async () => {
    //     await UpdateClustersModal.show({ projectId: currentProject.id, chatbotId: currentBot.id });
    //     tableRef.current?.refresh();
    //   },
    // },
    {
      label: "Update Recommendations",
      onClick: async () => {
        await UpdateRecommendationsModal.show({
          projectId: currentProject.id,
          chatbotId: currentBot.id,
          chatbotName: currentBot.name,
        });
        tableRef.current?.refresh();
      },
    },
  ];

  return (
    <PRContainer
      actions={actions}
      description="Here are some intents that you might want to consider adding to your bot"
      name="Chatbot"
      parentName="Intent Recommendations"
      tableRef={tableRef}
    >
      <PRTable
        columns={columns}
        storageKey={tableFilterStorageKey.intentRecommend}
        url={apiUrlChatbot.getClusterModelViewSet.format(currentProject.id)}
      />
    </PRContainer>
  );
};

const ClusterInfoModal = withCardon(
  function ClusterInfo({ get, projectId, clusterId, summary }) {
    // const renderedMd = RenderHelper.renderMd(summary, { html: true });
    // const sanitizedHtml = SanitizeHelper.html(renderedMd);

    // const htmlDom = <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} style={{ width: "100%" }} />;
    return (
      <PRModal size="xl" submitText="" title="Cluster Info" onClose={get(false)}>
        {/* {htmlDom} */}
        <PRTable
          columns={[
            {
              key: "id",
              label: "ID",
            },
            // {
            //   key: "is_handled",
            //   label: "Is Handled",
            // },
            {
              key: "quotes",
              label: "Quotes",
            },
            {
              key: "created",
              label: "Created",
              render: (item) => (
                <div>
                  <PRTooltip title={DateHelper.getDateTimeLocal(item.created).format("LLT")}>
                    <span>{DateHelper.getDateTimeLocal(item.created).fromNow()}</span>
                  </PRTooltip>
                </div>
              ),
            },
          ]}
          url={apiUrlChatbot.getClusteredSessions.format(projectId, clusterId)}
        />
      </PRModal>
    );
  },
  {
    destroyOnHide: true,
  }
);

// const UpdateClustersModal = withCardon(
//   function UpdateClusters({ get, projectId, chatbotId }) {
//     const dispatch = useDispatch();
//     const [date, setDate] = useState(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000));
//     const [loading, q] = useLoading();
//     const onClick = async () => {
//       // console.log("date", date);
//       await q(dispatch(updateClusters(projectId, chatbotId, date)));
//       // AlertHelper.showSuccess("Clusters are updated successfully");
//       get(true)();
//     };
//     return (
//       <PRModal loading={loading} title="Update Clusters" onClick={onClick} onClose={get(false)}>
//         <Label>Start From:</Label>
//         <PRDate name="start_date" value={date} onChange={setDate} />
//       </PRModal>
//     );
//   },
//   {
//     destroyOnHide: true,
//   }
// );

const UpdateRecommendationsModal = withCardon(
  function UpdateRecommendations({ get, projectId, chatbotId, chatbotName }) {
    const dispatch = useDispatch();
    const [fromDate, setFromDate] = useState(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000));
    const [toDate, setToDate] = useState(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000));
    const [loading, q] = useLoading();
    const onClick = async () => {
      if (
        !(await DialogHelper.showQuestionYesNo(
          "",
          <>
            Are you sure you want to update recommendations for <b>{chatbotName}</b> ? This action is priced and cannot
            be undone.
          </>
        ))
      ) {
        return;
      }
      await q(dispatch(updateRecommendations(projectId, chatbotId, fromDate, toDate)));
      get(true)();
    };
    return (
      <PRModal loading={loading} title="Update Recommendations" onClick={onClick} onClose={get(false)}>
        <Label>From Date:</Label>
        <PRDate name="From Date" value={fromDate} onChange={setFromDate} />
        <Label>To Date:</Label>
        <PRDate name="To Date" value={toDate} onChange={setToDate} />
      </PRModal>
    );
  },
  {
    destroyOnHide: true,
  }
);

const IntegrationModal = withCardon(
  function Integration({ get, summary, sampleIntents }) {
    const link = `/chatbot/intent/form/?samples=${encodeURI(sampleIntents)}`;
    return (
      <PRModal
        submitLink={link}
        submitLinkProps={{ newTab: true }}
        title="Integration"
        onClick={get(false)}
        onClose={get(false)}
      >
        <Label>{summary}</Label>
      </PRModal>
    );
  },
  {
    destroyOnHide: true,
  }
);

const AgentFailModal = withCardon(
  function AgentFail({ get, reason }) {
    return (
      <PRModal submitText="" title="Agent Fail" onClose={get(false)}>
        <Label>{reason}</Label>
      </PRModal>
    );
  },
  {
    destroyOnHide: true,
  }
);

export default IntentRecommend;
