import { useState } from "react";

import { withCardon } from "cardon";
import { useFormik } from "formik";
import { QRCode } from "react-qrcode-logo";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import styled from "@emotion/styled";

import PRDivider from "~components/Generic/PRDivider";
import PRInput from "~components/Generic/PRInput";
import PRLink from "~components/Generic/PRLink";
import PRModal from "~components/Generic/PRModal";
import PRSelect from "~components/Generic/PRSelect";
import { userAuthenticationMethod, userAuthenticationMethodOptions } from "~constants";
import Utils from "~helpers/Utils";
import { getCurrentUser, update2FAStatus, updateCurrentUser, updateCurrentUserPassword } from "~store/actions";
import { selectUserInfo } from "~store/user/selectors";

const StyledQrWrapper = styled.div`
  canvas {
    width: 100% !important;
    height: auto !important;
  }
`;

const ProfileModal = withCardon(
  function ProfileModal({ get }) {
    const userInfo = useSelector(selectUserInfo);
    const dispatch = useDispatch();
    const [showDetail, setShowDetail] = useState(false);
    const [authenticationUrl, setAuthenticationUrl] = useState(null);
    const [secretKey, setSecretKey] = useState(null);
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        firstname: userInfo.firstname,
        lastname: userInfo.lastname,
        email: userInfo.email,
        preferred_2fa_method: userInfo.preferred_2fa_method || userAuthenticationMethod.email,
        two_factor_enabled: userInfo.two_factor_enabled,
        // username: "",
        currentPassword: "",
        password: "",
        confirmPassword: "",
        // secret_question: "",
        // secret_answer: "",
      },
      validationSchema: Yup.object({
        firstname: Yup.string().required("Please Enter Your First Name"),
        lastname: Yup.string().required("Please Enter Your Last Name"),
        email: Yup.string()
          .required("Please Enter Your Email")
          .test("email", "Please Enter Valid Email", (value) => {
            return Utils.validateEmail(value);
          }),
        // username: Yup.string().required("Please Enter Your Username"),
        currentPassword: Yup.string().when({
          is: (val) => !!val?.length,
          then: Yup.string().required("Please Enter Your Current Password"),
        }),
        password: Yup.string().when({
          is: (val) => !!val?.length,
          then: Yup.string()
            .required("Please Enter Your Password")
            .min(8, "Password must be at least 8 characters")
            .max(64, "Password must be at most 64 characters")
            .test(
              "passwordComplexity",
              "Password must contain at least one uppercase, one lowercase, one number, and one special character",
              (value) => Utils.validatePassword(value)
            ),
        }),
        // secret_question: Yup.string().required("Please Enter Your Secret Question"),
        // secret_answer: Yup.string().required("Please Enter Your Secret Answer"),
        confirmPassword: Yup.string().when({
          is: (val) => !!val?.length,
          then: Yup.string()
            .required("Please Enter Your Confirm Password")
            .oneOf([Yup.ref("password"), null], "Passwords must match"),
        }),
      }),
      onSubmit: async (values) => {
        const payload = {
          data: values,
        };
        if (values.password == values.confirmPassword && values.currentPassword) {
          await dispatch(
            updateCurrentUserPassword({
              new_password: values.password,
              current_password: values.currentPassword,
            })
          );
        }
        let disableModalClose = false;
        if (
          values.two_factor_enabled !== userInfo.two_factor_enabled ||
          values.preferred_2fa_method !== userInfo.preferred_2fa_method
          //|| values.preferred_2fa_method === userAuthenticationMethod.authenticator
        ) {
          const preferredMethod = values.two_factor_enabled ? values.preferred_2fa_method : "none";
          const twoFAResponse = await dispatch(update2FAStatus({ preferred_method: preferredMethod }));
          if (values.preferred_2fa_method === userAuthenticationMethod.authenticator) {
            const secretKey = twoFAResponse.secret_key;
            setSecretKey(secretKey);
            const urlTemplate = `otpauth://totp/Palmate:${userInfo.email}?secret=${secretKey}&issuer=Palmate`;
            setAuthenticationUrl(urlTemplate);
            disableModalClose = true;
          }
          delete payload.data.two_factor_enabled;
          delete payload.data.preferred_2fa_method;
        }
        delete payload.data.currentPassword;
        delete payload.data.password;
        delete payload.data.confirmPassword;
        await dispatch(updateCurrentUser(payload));
        await dispatch(getCurrentUser());
        if (!disableModalClose) {
          get(false)();
        }
      },
    });
    const handleToggleDetail = () => {
      setShowDetail((prev) => !prev);
    };

    const handleChangeAuthenticationMethod = (value) => {
      formik.setFieldValue("preferred_2fa_method", value);
    };

    const handleCheck2fa = (e) => {
      const checked = e.target.checked;
      if (checked && formik.values.preferred_2fa_method === "none") {
        formik.setFieldValue("preferred_2fa_method", userAuthenticationMethod.email);
      }
      formik.setFieldValue("two_factor_enabled", checked);
    };
    if (authenticationUrl) {
      return (
        <PRModal submitText={""} title="Edit Profile" onClick={formik.handleSubmit} onClose={get(false)}>
          <Row>
            <Col lg={12}>
              <Alert color="info">
                <strong>Scan the QR code below with your authenticator app to enable 2FA.</strong>
              </Alert>
            </Col>
            <Col lg={12}>
              <StyledQrWrapper>
                <QRCode logoPaddingStyle="square" logoWidth={50} quietZone={0} size={512} value={authenticationUrl} />
              </StyledQrWrapper>
            </Col>
            <Col className="text-center" lg={12}>
              <strong>OTP Key: </strong> {secretKey}
            </Col>
          </Row>
        </PRModal>
      );
    }
    return (
      <PRModal submitText={"Update"} title="Edit Profile" onClick={formik.handleSubmit} onClose={get(false)}>
        <Row>
          <Col lg={6}>
            <Label>First Name</Label>
            <PRInput
              autoComplete="off"
              invalid={formik.touched.firstname && formik.errors.firstname}
              name="firstname"
              placeholder="Enter First Name"
              value={formik.values.firstname}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
          <Col className=" " lg={6}>
            <Label>Last Name</Label>
            <PRInput
              autoComplete="off"
              invalid={formik.touched.lastname && formik.errors.lastname}
              name="lastname"
              placeholder="Enter Last Name"
              value={formik.values.lastname}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
          <Col className="mt-3" xs={12}>
            <Label>Email</Label>
            <PRInput
              autoComplete="off"
              invalid={formik.touched.email && formik.errors.email}
              name="email"
              placeholder="Enter Email"
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
          {userInfo.two_factor_enabled !== undefined && (
            <>
              <Col className="mt-3" xs={12}>
                <Label>2FA Authentication</Label>
                <PRInput
                  autoComplete="off"
                  checked={formik.values.two_factor_enabled}
                  name="two_factor_enabled"
                  type="checkbox"
                  value={formik.values.two_factor_enabled}
                  onBlur={formik.handleBlur}
                  onChange={handleCheck2fa}
                />
              </Col>
              <Col className="mt-3" xs={12}>
                <Label>Authentication Method </Label>
                <PRSelect
                  isPrimitiveValue
                  invalid={formik.touched.preferred_2fa_method && formik.errors.preferred_2fa_method}
                  isClearable={false}
                  isDisabled={!formik.values.two_factor_enabled}
                  name="preferred_2fa_method"
                  options={userAuthenticationMethodOptions}
                  placeholder="Select 2FA Authentication"
                  value={formik.values.preferred_2fa_method}
                  onBlur={formik.handleBlur}
                  onChange={handleChangeAuthenticationMethod}
                />
              </Col>
            </>
          )}
          {!showDetail ? (
            <PRLink className="mt-3 font-size-12" onClick={handleToggleDetail}>
              Change Password
            </PRLink>
          ) : (
            <>
              <PRDivider className="mt-4" />
              <Col className="mt-3" xs={12}>
                <Label>Current Password </Label>
                <PRInput
                  autoComplete="off"
                  invalid={formik.touched.currentPassword && formik.errors.currentPassword}
                  name="currentPassword"
                  placeholder="Enter Current Password"
                  type="password"
                  value={formik.values.currentPassword}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col className="mt-3" xs={12}>
                <Label>New Password </Label>
                <PRInput
                  autoComplete="off"
                  invalid={formik.touched.password && formik.errors.password}
                  name="password"
                  placeholder="Enter Password"
                  type="password"
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col className="mt-3" xs={12}>
                <Label>Confirm New Password </Label>
                <PRInput
                  autoComplete="off"
                  invalid={formik.touched.confirmPassword && formik.errors.confirmPassword}
                  name="confirmPassword"
                  placeholder="Enter Confirm Password"
                  type="password"
                  value={formik.values.confirmPassword}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Col>
            </>
          )}
        </Row>
      </PRModal>
    );
  },
  {
    destroyOnHide: true,
  }
);

export default ProfileModal;
