import { useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { MdCircle } from "react-icons/md";
import { useSelector } from "react-redux";
import { Col, Label, Row } from "reactstrap";

import PRContainer from "~components/Generic/PRContainer";
import PRPage from "~components/Generic/PRPage";
import PRTab from "~components/Generic/PRTab";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import { apiUrlAgentLeaderboard, tableFilterStorageKey } from "~constants";
import DateHelper from "~helpers/DateHelper";
import { selectPopupSettingsState } from "~store/settings/popupSettings/selectors";
import { selectCurrentProject } from "~store/user/selectors";

const RenderAgentStatus = (status) => {
  return status === "AV" ? (
    <PRTooltip placement="top" title={"Online"}>
      <span>
        <MdCircle className="text-success fs-5 ms-1" />
      </span>
    </PRTooltip>
  ) : (
    <PRTooltip placement="top" title={"Offline"}>
      <span>
        <MdCircle className="text-secondary fs-5 ms-1" />
      </span>
    </PRTooltip>
  );
};

export default function AgentLeaderboard() {
  const [filterSticked, setFilterSticked] = useState(false);

  const [genericQueryFilter, setGenericQueryFilter] = useState({
    from_date: DateHelper.getDateTimeLocal().startOf("day"),
    filter_day: 1,
  });

  const currentProject = useSelector(selectCurrentProject);
  const popupSettings = useSelector(selectPopupSettingsState);

  const columns = useMemo(() => {
    return [
      {
        label: "Status",
        key: "status",
        render: (row) => {
          return <>{RenderAgentStatus(row?.status)}</>;
        },
      },
      {
        label: "Name",
        key: "name",
      },
      popupSettings.show_thumps_up && {
        label: "Like Count",
        key: "like_count",
      },
      popupSettings.show_thumps_up && {
        label: "Dislike Count",
        key: "dislike_count",
      },
      {
        label: "Available Time",
        key: "available_time",
        render: (row) => {
          return <>{DateHelper.formatMoment(row?.available_time, "seconds", "auto")}</>;
        },
      },
      {
        label: "Chat Time",
        key: "chat_time",
        render: (row) => {
          return <>{DateHelper.formatMoment(row?.chat_time, "seconds", "auto")}</>;
        },
      },
      {
        label: "Session Count",
        key: "session_count",
      },
      {
        label: "Max. Active Chat Count",
        key: "max_active_chat_count",
      },
      // {
      //   label: "Actions",
      //   key: "actions",
      //   actions: true,
      //   render: (row) => (
      //     <div className="d-flex justify-content-center">
      //       <PRButton
      //         outline
      //         color="primary"
      //         icon={MdEdit}
      //         link={`/contactcenter/agents/form/${row.id}`}
      //         size="sm"
      //         tooltipText="Edit"
      //       />
      //       {/* <PRButton
      //         outline
      //         className="ms-1"
      //         color="danger"
      //         icon={MdDelete}
      //         size="sm"
      //         tooltipText="Delete"
      //         onClick={handleDelete(row)}
      //       /> */}
      //     </div>
      //   ),
      // },
    ].filter(Boolean);
  }, [popupSettings]);

  useEffect(() => {
    const positionHandler = () => {
      const filterDom = document.getElementById("filter-col");
      if (!filterDom) return;

      const { top } = filterDom.getBoundingClientRect();
      const limit = 110; // header + padding-top
      // const limit = 70 + 24; // header + padding-top
      if (top <= limit) {
        setFilterSticked(true);
      } else {
        setFilterSticked(false);
      }
    };

    const filterDom = document.getElementById("filter-col");
    if (!filterDom) return;

    const mainDom = document.getElementById("main-scroll");
    mainDom.addEventListener("scroll", positionHandler);

    return () => {
      mainDom.removeEventListener("scroll", positionHandler);
    };
  }, []);

  const handleChangeGenericFilter = (key) => (value) => {
    let newFilter = {};

    const newState = {
      ...genericQueryFilter,
      [key]: value,
      ...newFilter,
    };
    setGenericQueryFilter(newState);
  };

  return (
    <PRContainer
      noAction
      className={"pr-agent-stats"}
      description={"Here you can see the statistics of general agent performance."}
      name="Contact Center"
      parentName={"Agent Leaderboard"}
    >
      <Row>
        <Col
          className={classNames("pr-stats-filter-col", {
            "pr-stats-filter-col-sticked": filterSticked,
          })}
          id="filter-col"
          sm={12}
        >
          <PRPage headerColor="primary" title={!filterSticked && "Filter"}>
            <Row className="mt-2 g-2">
              <Col md={8} sm={12}>
                <Label>Period</Label>
                <PRTab
                  border
                  fullWidth
                  pill
                  tab={genericQueryFilter?.filter_day}
                  tabList={[
                    // { id: 1, label: "Hourly" },
                    { id: 1, label: "Today" },
                    { id: 7, label: "Last Week" },
                    { id: 30, label: "Last Month" },
                    { id: 365, label: "Last Year" },
                  ]}
                  onChange={handleChangeGenericFilter("filter_day")}
                />
              </Col>
            </Row>
          </PRPage>
        </Col>
        <Col xs={12}>
          <PRPage
            // actions={actions}
            // title={
            //   <>
            //     Agents&nbsp;
            //     <RippleBadge
            //       anchorOrigin={{ vertical: "50%", horizontal: "right" }}
            //       color={"success"}
            //       overlap="circular"
            //       sx={{}}
            //       variant="dot"
            //     >
            //       &nbsp;
            //     </RippleBadge>
            //   </>
            // }
            title={"Agents"}
          >
            <PRTable
              inline
              columns={columns}
              defaultSortInfo={[{ key: "session_count", order: "desc" }]}
              memoizeAsQuery={false}
              storageKey={tableFilterStorageKey.agentLeaderboardList}
              url={`${apiUrlAgentLeaderboard.list.format(currentProject?.id)}?day=${genericQueryFilter?.filter_day}`}
            />
          </PRPage>
        </Col>
      </Row>
    </PRContainer>
  );
}
