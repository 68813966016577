import { createSelector } from "reselect";

/** @type {() => typeof import("./reducer").initState} */
const rootSelector = (state) => state.organization;

export const selectMemberFields = createSelector(rootSelector, (state) => state.organization.memberFields);
export const selectMemberFieldFormat = createSelector(rootSelector, (state) => state.organization.memberFieldFormat);
export const selectFilters = createSelector(rootSelector, (state) => state.organization.filters);
export const selectFilter = createSelector(rootSelector, (state) => state.organization.filter);
export const selectResources = createSelector(rootSelector, (state) => state.organization.resources);
export const selectResource = createSelector(rootSelector, (state) => state.organization.resource);
export const selectMembers = createSelector(rootSelector, (state) => state.organization.members);
export const selectMember = createSelector(rootSelector, (state) => state.organization.member);
export const selectSurveyList = createSelector(rootSelector, (state) => state.organization.surveyList);
export const selectSurvey = createSelector(rootSelector, (state) => state.organization.survey);
export const selectSurveyResult = createSelector(rootSelector, (state) => state.organization.surveyResult);
export const selectSlots = createSelector(rootSelector, (state) => state.organization.slots);
export const selectSlot = createSelector(rootSelector, (state) => state.organization.slot);
export const selectReservations = createSelector(rootSelector, (state) => state.organization.reservations);

export const selectActionList = createSelector(rootSelector, (state) => state.organization.actionList);
export const selectAction = createSelector(rootSelector, (state) => state.organization.action);

export const selectAnnouncement = createSelector(rootSelector, (state) => state.organization.announcement);
export const selectAnnouncementVersion = createSelector(
  rootSelector,
  (state) => state.organization.announcementVersion
);

export const selectSecretKey = createSelector(rootSelector, (state) => state.secretKey);
export const selectSecretKeyList = createSelector(rootSelector, (state) => state.secretKeyList);
