import { useEffect, useMemo, useRef } from "react";

import { withCardon } from "cardon";
import { useFormik } from "formik";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import { Attachment } from "@mui/icons-material";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRInput from "~components/Generic/PRInput";
import PRModal from "~components/Generic/PRModal";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import PalTooltip from "~components/mui/PalTooltip";
import {
  apiUrlTicket,
  tableFilterStorageKey,
  ticketStatusOptions,
  ticketWaitingAction,
  ticketWaitingActionMap,
} from "~constants";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import Utils from "~helpers/Utils";
import { createOrUpdateTicket, deleteTicket, getTicketCategoryList } from "~store/helpdesk/actions";
import { getPermissionUserList } from "~store/settings/permission/actions";
import { selectPermissionUserList } from "~store/settings/permission/selectors";
import { selectCurrentProject } from "~store/user/selectors";

import "./style.scss";
const CreateTicketModal = withCardon(
  function CreateTicket({ get }) {
    const dispatch = useDispatch();
    const currentProject = useSelector(selectCurrentProject);
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        contact: "",
        desc: "",
      },
      validationSchema: Yup.object({
        contact: Yup.string()
          .required("Contact is required")
          .test("invalid-email", "Invalid email", (value) => {
            return Utils.validateEmail(value);
          }),

        desc: Yup.string().required("Description is required"),
      }),

      onSubmit: async (values) => {
        const response = await dispatch(
          createOrUpdateTicket(currentProject.id, {
            contact: values.contact,
            text: values.desc,
          })
        );
        if (response.uuid) {
          HistoryHelper.push(`/helpdesk/ticket/form/${response.uuid}`, { scope: "dashboard" });
        }
        get(values)();
      },
    });

    return (
      <PRModal submitText={"Create"} title={"Create Ticket"} onClick={formik.handleSubmit} onClose={get(false)}>
        <Row className="g-2">
          <Col xs={12}>
            <Label>Contact Info:</Label>
            <PRInput
              invalid={formik.touched.contact && formik.errors.contact}
              name="contact"
              value={formik.values.contact}
              onChange={formik.handleChange}
            />
          </Col>
          <Col xs={12}>
            <Label>Description:</Label>
            <PRInput
              invalid={formik.touched.desc && formik.errors.desc}
              name="desc"
              rows={3}
              type="textarea"
              value={formik.values.desc}
              onChange={formik.handleChange}
            />
          </Col>
        </Row>
      </PRModal>
    );
  },
  {
    destroyOnHide: true,
  }
);

export default function TicketList() {
  const dispatch = useDispatch();

  const tableRef = useRef(null);
  const currentProject = useSelector(selectCurrentProject);
  const permissionUserList = useSelector(selectPermissionUserList);

  useEffect(() => {
    dispatch(getPermissionUserList(currentProject.id));
  }, [dispatch, currentProject.id]);

  const columns = useMemo(() => {
    const handleDelete = (row) => async () => {
      if (!(await DialogHelper.showQuestionDelete())) return;
      await dispatch(deleteTicket(row.uuid, currentProject.id));
      tableRef.current?.refresh();
    };
    const blockPassThroughHover = (e) => {
      e.stopPropagation();
    };
    return [
      {
        label: "Content",
        key: "text",
        className: "text-nowrap overflow-hidden text-truncate",
        render: (row) => (
          <div className="d-flex flex-column">
            <PRTooltip title={row.text}>
              <span
                className="text-truncate"
                style={{
                  maxWidth: "200px",
                }}
              >
                {row.files_count > 0 ? (
                  <>
                    <Attachment color="secondary" sx={{ fontSize: 16 }} />
                    &nbsp;
                  </>
                ) : (
                  ""
                )}
                {row.text}
              </span>
            </PRTooltip>
          </div>
        ),
      },
      {
        label: "Category",
        key: "category_name",
      },
      {
        label: "Contact",
        key: "contact",
      },
      {
        label: "Waiting Action",
        key: "waiting_action",
        noWrap: true,
        render: (row) => {
          // return <span>{ticketWaitingActionMap[row.waiting_action] || row.waiting_action}</span>;

          if (row.waiting_action === ticketWaitingAction.AGENT_ACTION_WAITING) {
            return (
              <Badge className="font-size-12" color="orange">
                {ticketWaitingActionMap[row.waiting_action] || row.waiting_action}
              </Badge>
            );
          } else if (row.waiting_action === ticketWaitingAction.CUSTOMER_ACTION_WAITING) {
            return (
              <Badge className="font-size-12" color="primary">
                {ticketWaitingActionMap[row.waiting_action] || row.waiting_action}
              </Badge>
            );
          } else {
            return (
              <Badge className="font-size-12" color="secondary">
                {ticketWaitingActionMap[row.waiting_action] || row.waiting_action}
              </Badge>
            );
          }
        },
      },
      {
        label: "Status",
        key: "status",
        render: (row) => {
          let Comp = ticketStatusOptions.find((item) => item.value === row.status)?.label || (() => row.status);

          return <Comp />;
        },
      },
      {
        label: "Assigned Agent",
        key: "assigned_agent",
        render: (row) => {
          const permissionUser = permissionUserList?.find((item) => item.id === row?.assigned_user);
          if (!permissionUser) return <span>-</span>;
          const firstLastName = permissionUser?.firstname + " " + permissionUser?.lastname;
          const fullInfo = `${firstLastName} (${permissionUser?.email})`;
          return (
            <span>
              <PRTooltip title={fullInfo}>
                <span className={"fw-semibold"}>{firstLastName}</span>
              </PRTooltip>
            </span>
          );
        },
      },
      {
        label: "Assigned Time",
        key: "assigned_time",
        render: (row) => (
          <span>
            <PalTooltip title={row.assigned_time ? DateHelper.getDateTimeLocal(row.assigned_time).fromNow() : "-"}>
              {row.assigned_time ? DateHelper.getDateTimeLocal(row.assigned_time).format("LLT") : "-"}
            </PalTooltip>
          </span>
        ),
        className: "text-nowrap",
      },
      {
        label: "Created",
        key: "created",
        render: (row) => (
          <span>
            <PalTooltip title={DateHelper.getDateTimeLocal(row.created).fromNow()}>
              {DateHelper.getDateTimeLocal(row.created).format("LLT")}
            </PalTooltip>
          </span>
        ),
        className: "text-nowrap",
      },
      {
        label: "Updated",
        key: "updated",
        render: (row) => (
          <span>
            <PalTooltip title={row.updated ? DateHelper.getDateTimeLocal(row.updated).fromNow() : "-"}>
              {row.updated ? DateHelper.getDateTimeLocal(row.updated).format("LLT") : "-"}
            </PalTooltip>
          </span>
        ),
        className: "text-nowrap",
      },
      {
        label: "Last Customer Action Date",
        key: "last_customer_action_date",
        render: (row) => (
          <span>
            <PalTooltip
              title={
                row.last_customer_action_date
                  ? DateHelper.getDateTimeLocal(row.last_customer_action_date).fromNow()
                  : "-"
              }
            >
              {row.last_customer_action_date
                ? DateHelper.getDateTimeLocal(row.last_customer_action_date).format("LLT")
                : "-"}
            </PalTooltip>
          </span>
        ),
        className: "text-nowrap",
      },
      {
        label: "Actions",
        key: "actions",
        actions: true,
        fixed: "right",
        render: (row) => (
          <div className="d-flex justify-content-center">
            <PRButton
              outline
              color="primary"
              icon={MdEdit}
              link={`/helpdesk/ticket/form/${row.uuid}`}
              size="sm"
              tooltipText="Edit"
            />
            <PRButton
              outline
              className="ms-1"
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText="Delete"
              onClick={handleDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [dispatch, permissionUserList, currentProject.id]);

  // TODO: Add create ticket modal after BE update
  // const actions = useMemo(() => {
  //   return [
  //     {
  //       label: "Create New",
  //       icon: MdAdd,
  //       color: "success",
  //       onClick: async () => {
  //         CreateTicketModal.show();
  //       },
  //     },
  //   ];
  // }, []);

  const filters = useMemo(
    () => [
      {
        key: "status",
        options: ticketStatusOptions,
      },

      {
        key: "category",
        onFetch: async () => {
          const res = await dispatch(getTicketCategoryList(currentProject.id, { loading: false }));
          return res.results.map((item) => ({ label: item.name, value: item.id }));
        },
      },
    ],
    [dispatch, currentProject.id]
  );

  return (
    <PRContainer
      className="pr-"
      description={"Here you can manage your tickets and assign them to agents"}
      name="Help Desk"
      parentName="Ticket"
    >
      <PRTable
        ref={tableRef}
        columns={columns}
        filters={filters}
        storageKey={tableFilterStorageKey.ticketList}
        url={apiUrlTicket.getList.format(currentProject.id)}
      />
    </PRContainer>
  );
}
