import chroma from "chroma-js";
import classNames from "classnames";
import reactStringReplace from "react-string-replace";

import styled from "@emotion/styled";
import { Tooltip } from "@mui/material";

import HtmlContent from "~components/HtmlContent";
import IFrameHelper from "~helpers/IFrameHelper";
import RenderHelper from "~helpers/RenderHelper";
import SanitizeHelper from "~helpers/SanitizeHelper";
import Utils from "~helpers/Utils";

import { domChatContainerId, domChatScrollContainerId } from "./constants";
function easeInOutQuad(t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
}
export function urlify(text = "", color) {
  let urlRegex = /(https?:\/\/[^\s]+)/g;
  let foundUrl = text?.match?.(urlRegex);
  if (foundUrl) {
    if (foundUrl.length > 0) {
      for (let url of foundUrl) {
        reactStringReplace(text, url, (match, i) => (
          <Tooltip title={url}>
            <a
              href={url}
              rel="noreferrer"
              style={{
                ...(color
                  ? {
                      color: color,
                    }
                  : {}),
              }}
              target="_blank"
            >
              <span
                style={{
                  textDecoration: "underline",
                }}
              >
                {Utils.ellipsisUrl(match)}
              </span>
            </a>
          </Tooltip>
        ));
      }

      return (
        <>
          {reactStringReplace(text, /(https?:\/\/[^\s]+)/g, (match, i) => (
            <Tooltip key={match + i} title={match}>
              <a
                href={match}
                rel="noreferrer"
                style={{
                  ...(color
                    ? {
                        color: color,
                      }
                    : {}),
                }}
                target="_blank"
              >
                <span
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  {Utils.ellipsisUrl(match)}
                </span>
              </a>
            </Tooltip>
          ))}
        </>
      );
    }
  }
  return text;
}

const StyledMarkdownDiv = styled.div`
  & > div {
    //width - padding - marging - emnote_icon
    width: ${(props) => (props.isEmbedded ? "calc(450px - 40px - 40px - 24px)" : "60vw")};
  }
  a {
    color: ${({ isRightBalloon, theme }) => {
      if (isRightBalloon) return "#ebebeb";

      let linkColor = theme.palette.primary.main;
      const defaultLeftBackgroundColor = "#f8f8f8";
      const primaryColorChroma = theme.palette.primary.main;
      const contrastDifference = chroma.contrast(primaryColorChroma, defaultLeftBackgroundColor);
      if (4.5 > contrastDifference) {
        linkColor = chroma(primaryColorChroma)
          .darken(4.5 - contrastDifference)
          .hex();
      }

      return linkColor;
    }} !important;
  }
`;

function animateScroll(element, start, end, duration) {
  let startTime = null;
  return new Promise((resolve) => {
    function step(timestamp) {
      if (!startTime) startTime = timestamp;
      const elapsed = timestamp - startTime;
      const newPos = easeInOutQuad(elapsed, start, end - start, duration);
      element.scrollTop = newPos;
      window.dispatchEvent(new CustomEvent("chat-container-scrolling"));
      if (elapsed < duration) {
        requestAnimationFrame(step);
      } else {
        element.scrollTop = end;
        window.dispatchEvent(new CustomEvent("chat-container-scrolling"));
        resolve();
      }
    }
    requestAnimationFrame(step);
  });
}
/**
 * Scrolls the chat to the bottom.
 *
 * @param {"instant" | "smooth" | "auto"} mode - Scroll behavior.
 * @param {boolean} scrollToTop - Whether to scroll to the top instead of the bottom.
 */
export const scrollChatToBottom = async (mode = "auto", scrollToTop) => {
  const dom = document.getElementById(domChatContainerId);
  const chatContainerDom = dom?.parentElement;
  const scroll = document.getElementById(domChatScrollContainerId);
  const innerDom = document.getElementById(domChatContainerId);

  if (!scroll || !innerDom || !chatContainerDom) return;
  // const chatScrollHeight = chatContainerDom.scrollHeight;
  // const distanceFromBottom = chatContainerDom.scrollHeight - chatContainerDom.scrollTop - chatContainerDom.clientHeight;
  const distanceFromTop = chatContainerDom?.scrollTop;
  function calculateTotalHeight(element) {
    let totalHeight = 0;
    Array.from(element.children).forEach((child) => {
      totalHeight += child.offsetHeight;
    });
    return totalHeight;
  }

  const chatViewHeight = calculateTotalHeight(innerDom);
  const scrollTopTolerance = 5;

  const scrollTopLimit = innerDom.offsetHeight - scroll.clientHeight + 20 * 2; // 20px padding top and bottom
  // if scroll position is near top, scroll to bottom directly otherwise scroll to bottom smoothly
  const shouldSmoothScroll = !scrollToTop ? distanceFromTop * 3 > chatViewHeight : true;
  if ((mode === "auto" && shouldSmoothScroll) || mode === "smooth") {
    // scroll.scrollIntoView({ behavior: "smooth" });
    if (!scrollToTop) {
      if (scrollTopLimit < scroll.scrollTop + scrollTopTolerance) {
        scroll.scrollTop = scrollTopLimit + scrollTopTolerance;
      } else {
        await animateScroll(scroll, scroll.scrollTop + scrollTopTolerance, scrollTopLimit, 250);
      }
    } else {
      if (scroll.scrollTop < 0) {
        scroll.scrollTop = 0;
      } else {
        await animateScroll(scroll, scroll.scrollTop + scrollTopTolerance, 0, 250);
      }
    }
  } else if (mode !== "smooth") {
    if (scrollToTop) {
      scroll.scrollTop = 0;
    } else {
      scroll.scrollTop = scrollTopLimit + scrollTopTolerance;
    }
  }
};

export const getChatDom = () => {
  const dom = document.getElementById(domChatScrollContainerId);
  return dom;
};

const renderTextContent = (text, format = "plain", isRightBalloon) => {
  if (format === "html") {
    let sanitizedHtml = SanitizeHelper.html(text);
    sanitizedHtml = RenderHelper.addBlankTargetToLinks(sanitizedHtml);
    return (
      <HtmlContent isRightBalloon={isRightBalloon}>
        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
      </HtmlContent>
    );
  } else if (format === "markdown") {
    const renderedMd = RenderHelper.renderMd(text, { html: true });
    const sanitizedHtml = SanitizeHelper.html(renderedMd);

    const htmlDom = <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} style={{ width: "100%" }} />;
    return (
      <StyledMarkdownDiv
        className={classNames("inner-markdown markdown-body", {
          dark: isRightBalloon,
        })}
        isEmbedded={IFrameHelper.isInIFrameOrTryChatbotOrPreview()}
        isRightBalloon={isRightBalloon}
      >
        {htmlDom}
      </StyledMarkdownDiv>
    );
  } else {
    return text;
  }
};

const StyledRenderDiv = styled.div`
  ${(props) => {
    if (props.isRightBalloon) {
      // let primary = props.theme.palette.primary.main;
      // let light = props.theme.palette.primary.light;
      const contrastDifference = chroma.contrast(props.theme.palette.primary.main, "#fff");
      const leftBallonColorContrastDifference = chroma.contrast("#f8f8f8", "#fff");

      if (leftBallonColorContrastDifference > contrastDifference) {
        // If color brightness is too much, use left balloon color instead of theme color
        // primary = "#f8f8f8";
        // light = chroma(primary).brighten(1.5).hex();
      }

      return ` 
        .markdown-body.dark{
          --color-fg-default: ${props.theme.palette.primary.contrastText};
          a {
            color: ${props.theme.palette.primary.contrastText} !important;
          }
        }
        &, * {
          color: ${props.theme.palette.primary.contrastText}; 
          a { 
            color: ${props.theme.palette.primary.contrastText};
          }
        }
    `;
    } else {
      return `
        color: rgba(0, 0, 0, 0.7) !important;
      `;
    }
  }}
`;

/**
 * @param {string} text
 * @param {"html" | "markdown" | "plain"} format
 * @param {boolean} isRightBalloon
 */
export const renderText = (text, format = "plain", isRightBalloon) => {
  return (
    <StyledRenderDiv isRightBalloon={isRightBalloon}>{renderTextContent(text, format, isRightBalloon)}</StyledRenderDiv>
  );
};
