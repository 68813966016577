import { useEffect, useMemo } from "react";

import { PalContainer, PalPage, PalSelect, PalTextField, PalTypography } from "@palamar/fe-library";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import styled from "@emotion/styled";
import { Grid } from "@mui/material";

import useLoading from "~common/hooks/useLoading";
import useProjectChange from "~common/hooks/useProjectChange";
import PRButton from "~components/Generic/PRButton";
import HistoryHelper from "~helpers/HistoryHelper";
import { getResources } from "~store/organization/actions";
import { selectResources } from "~store/organization/selectors";
import {
  createOrUpdateReservationGenerator,
  getReservationGenerator,
  setReservationGenerator,
} from "~store/reservationGenerator/actions";
import { selectReservationGenerator } from "~store/reservationGenerator/selectors";
import { selectCurrentProject } from "~store/user/selectors";

const StyledTypography = styled(PalTypography)`
  padding-bottom: ${({ theme }) => theme.spacing(1)};
  padding-left: ${({ theme }) => theme.spacing(1)};
  display: block;
`;

const ReservationGeneratorEdit = () => {
  const { id } = useParams();

  const [resourceLoading, resourceQ] = useLoading();
  const dispatch = useDispatch();
  const resources = useSelector(selectResources);

  const currentProject = useSelector(selectCurrentProject);
  const reservationGenerator = useSelector(selectReservationGenerator);

  const handleClickCancel = () => {
    HistoryHelper.goBack("/organization/reservation-generator/", { scope: "dashboard" });
  };

  const resourceOptions = useMemo(() => {
    const mappedData = resources.map((item) => ({
      value: item.id,
      label: `${item.name} (${item.resource_type} - ${item.id})`,
    }));
    mappedData.reverse();
    return mappedData;
  }, [resources]);

  useEffect(() => {
    if (!id) return;
    dispatch(getReservationGenerator(currentProject.id, id));
    // dispatch(getReservation);

    return () => {
      dispatch(setReservationGenerator(null));
    };
    // Page should be redirected to list if selected project is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    resourceQ(dispatch(getResources(currentProject.id)));
  }, [currentProject.id, dispatch, resourceQ]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: reservationGenerator?.id,
      name: reservationGenerator?.name || "",
      resource: reservationGenerator?.resource || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      await dispatch(
        createOrUpdateReservationGenerator(currentProject.id, {
          ...values,
        })
      );
      handleClickCancel();
    },
  });

  useProjectChange(() => {
    HistoryHelper.push("/organization/reservation-generator/", { scope: "dashboard" });
  }, []);

  const parentName = useMemo(
    () => [
      {
        label: "Reservation Generator",
        url: "/organization/reservation-generator/",
      },
      {
        label: id ? "Edit Reservation Generator" : "Create Reservation Generator",
      },
    ],
    [id]
  );

  const handleResourceChange = (e, value) => {
    formik.setFieldValue("resource", value);
  };

  useEffect(() => {
    console.log("resource:", formik.values.resource);
  }, [formik.values.resource]);

  return (
    <PalContainer
      description={"Here you can create a new reservation generator to able to generate reservations."}
      name="Reservations"
      parentName={parentName}
    >
      <PalPage>
        <Grid container spacing={1}>
          <Grid item display={"flex"} flexDirection={"column"} lg="6" xs="12">
            {/* <Grid container>
              <Grid item display={"flex"} flexDirection={"column"} xs="12"> */}
            <StyledTypography variant="subtitle1">Name</StyledTypography>
            <PalTextField
              invalid={formik.touched.name && formik.errors.name}
              name="name"
              placeholder="Enter Name"
              sx={{ flexGrow: 1 }}
              value={formik.values.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {/* </Grid>
            </Grid> */}
          </Grid>
          <Grid item xs>
            <StyledTypography variant="subtitle1">Calendar</StyledTypography>
            <PalSelect
              // invalid={formik.touched.name && formik.errors.name}
              isPrimitiveValue
              isLoading={resourceLoading}
              name="resource"
              options={resourceOptions}
              placeholder="Select Resource"
              value={formik.values.resource}
              onBlur={formik.handleBlur}
              onChange={handleResourceChange}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent={"end"} marginTop={1}>
          <Grid item xs="auto">
            <PRButton outline className="me-2" onClick={handleClickCancel}>
              Cancel
            </PRButton>
            <PRButton onClick={formik.handleSubmit}>{id ? "Update" : "Create"}</PRButton>
          </Grid>
        </Grid>
      </PalPage>
    </PalContainer>
  );
};

export default ReservationGeneratorEdit;
