import { useCallback, useEffect, useMemo, useState } from "react";

import { useFormik } from "formik";
import { MdInfo } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import { Switch } from "@mui/material";

import useKeyPressed from "~common/hooks/useKeyPressed";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRInput, { PRTextArea, PRTextAreaFormat } from "~components/Generic/PRInput";
import PRNumber from "~components/Generic/PRNumber";
import PRPage from "~components/Generic/PRPage";
import PRSelect from "~components/Generic/PRSelect";
import PRTab from "~components/Generic/PRTab";
import PalTooltip from "~components/mui/PalTooltip";
import {
  livechatCallcenterTypeOptions,
  llmTypeOptions,
  projectLanguageOptions,
  projectTimeoutTimes,
  projectTimeoutTimesOptions,
  projectTranslateKeyOptions,
  emailTypes,
  emailTypesOptions,
  livechatCallcenterType,
} from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import LoadingHelper from "~helpers/LoadingHelper";
import {
  createOrUpdateProject,
  getProject,
  setProject,
  updateProjectSettings,
} from "~store/settings/projectSettings/actions";
import { selectProject } from "~store/settings/projectSettings/selectors";
import {
  deleteLlmSettings,
  getCallcenterSettings,
  getLlmSettings,
  setCallcenterSettings,
  setLlmSettings,
  updateCallcenterSettings,
  updateLlmSettings,
} from "~store/socket/livechat/actions";
import { selectCallcenterSettings, selectLlmSettings } from "~store/socket/livechat/selectors";

const defaultTimeoutTimes = {
  waiting_timeout: 20,
  inactivity_timeout: 5,
  bot_sessions_timeout: 10,
  agent_sessions_timeout: 15,
  feedback_timeout: 5,
};

function TimeoutTimesItem({ tab, value = {}, onChange }) {
  const handleChange = (key) => (data) => {
    const inputValue = data?.floatValue;
    onChange?.({ ...value, [key]: inputValue });
  };

  return (
    <Row className="g-2 mt-0">
      <Col md={3} xs={12}>
        <Label>Waiting Timeout</Label>
        <PRNumber
          key={tab}
          min={1}
          placeholder="Enter timeout value"
          suffix=" minutes"
          value={value.waiting_timeout ?? null}
          onChange={handleChange("waiting_timeout")}
        />
      </Col>
      <Col md={3} xs={12}>
        <Label>Inactivity Timeout</Label>
        <PRNumber
          key={tab}
          min={1}
          placeholder="Enter timeout value"
          suffix=" minutes"
          value={value.inactivity_timeout ?? null}
          onChange={handleChange("inactivity_timeout")}
        />
      </Col>
      <Col md={3} xs={12}>
        <Label>Bot Sessions Timeout</Label>
        <PRNumber
          key={tab}
          min={1}
          placeholder="Enter timeout value"
          suffix=" minutes"
          value={value.bot_sessions_timeout ?? null}
          onChange={handleChange("bot_sessions_timeout")}
        />
      </Col>
      <Col md={3} xs={12}>
        <Label>Agent Sessions Timeout</Label>
        <PRNumber
          key={tab}
          min={1}
          placeholder="Enter timeout value"
          suffix=" minutes"
          value={value.agent_sessions_timeout ?? null}
          onChange={handleChange("agent_sessions_timeout")}
        />
      </Col>
      <Col md={3} xs={12}>
        <Label>Feedback Timeout</Label>
        <PRNumber
          key={tab}
          min={1}
          placeholder="Enter timeout value"
          suffix=" minutes"
          value={value.feedback_timeout ?? null}
          onChange={handleChange("feedback_timeout")}
        />
      </Col>
    </Row>
  );
}

export default function ProjectSettingsEdit() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const project = useSelector(selectProject);
  const [timeoutTab, setTimeoutTab] = useState(projectTimeoutTimes.web);
  const isShiftPressed = useKeyPressed("Shift");
  const [firstAccessTokenFocus, setFirstAccessTokenFocus] = useState(false);

  const [offlineMessageTab, setOfflineMessageTab] = useState(projectTimeoutTimes.web);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [selectedTranslateKey, setSelectedTranslateKey] = useState(projectTranslateKeyOptions[0].value);
  const [enableLLMOptions, setEnableLLMOptions] = useState(false);

  const callcenterSettings = useSelector(selectCallcenterSettings);
  const llmSettings = useSelector(selectLlmSettings);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: project?.name,
      chatbotLanguages: project?.settings?.chatbot_languages ?? [],
      timeoutTimes: project?.settings?.timeout_times || {},
      maintenance: project?.settings?.maintenance ?? false,
      show_thumps_up: project?.settings?.show_thumps_up ?? true,
      show_queue_order: project?.settings?.show_queue_order ?? true,
      chat_label_enabled: project?.settings?.chat_label_enabled ?? false,
      ticket_enabled: project?.settings?.ticket_enabled ?? true,
      direct_to_ticket_enabled: project?.settings?.direct_to_ticket_enabled ?? false,

      system_chatbot_info: project?.settings?.system_chatbot_info,
      chatbot_info: project?.settings?.chatbot_info,

      mailer_username: project?.settings?.mailer_username || "",
      mailer_password: project?.settings?.mailer_password || "",
      mailer_encryption_type: project?.settings?.mailer_encryption_type || "",
      mailer_domain: project?.settings?.mailer_domain || "",
      mailer_port: project?.settings?.mailer_port || "",
      info_email_type: project?.settings?.info_email_type || emailTypes.SES,
      email_status: project?.settings?.email_status || "",

      info_email: project?.settings?.info_email ?? "noreply@palmate.ai",
      info_email_name: project?.settings?.info_email_name ?? "",
      reply_to_email: project?.settings?.reply_to_email ?? "",
      receiver_email: project?.settings?.receiver_email ?? "noreply@palmate.ai",
      static_messages: [
        ...(Array.isArray(project?.settings?.static_messages) ? project?.settings?.static_messages || [] : []),
      ],
      callcenterSettings: {
        ...callcenterSettings,
        callcenter_type: callcenterSettings?.callcenter_type || livechatCallcenterType.Pool,
      },
      // llmSettings: llmSettings,
      llmSettings: {
        id: llmSettings?.id,
        llm_type: llmSettings?.llm_type,
        submodel: llmSettings?.submodel,
        access_token: llmSettings?.access_token,
      },
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required").min(3, "Must be at least 3 characters"),
      chatbotLanguages: Yup.array().required("Required").min(1, "Must be at least 1 language"),
      llmSettings: Yup.object().shape({
        llm_type: Yup.string().when("llmSettings", {
          is: (value) => enableLLMOptions,
          then: Yup.string().required("Required"),
          otherwise: Yup.string().notRequired(),
        }),
        submodel: Yup.string().when("llm_type", {
          is: (value) => !!value && enableLLMOptions,
          then: Yup.string().required("Required"),
          otherwise: Yup.string().notRequired(),
        }),
      }),
      // email_status: Yup.string()
      //   .required("Required")
      //   .test("is-key-of-emailStatus", "email_status must be a key of emailStatus", (value) => {
      //     return Object.keys(emailStatus).includes(value);
      //   }),
      info_email: Yup.string().optional().email("Invalid email"),
      reply_to_email: Yup.string().optional().email("Invalid email"),
      mailer_username: Yup.string()
        .nullable()
        .when("info_email_type", {
          is: (value) => value === emailTypes.SMTP,
          then: Yup.string().required("Required"),
          otherwise: Yup.string().notRequired(),
        }),
      mailer_password: Yup.string()
        .nullable()
        .when("info_email_type", {
          is: (value) => value === emailTypes.SMTP,
          then: Yup.string().required("Required"),
          otherwise: Yup.string().notRequired(),
        }),
      mailer_domain: Yup.string()
        .nullable()
        .when("info_email_type", {
          is: (value) => value === emailTypes.SMTP,
          then: Yup.string().required("Required"),
          otherwise: Yup.string().notRequired(),
        }),
      mailer_port: Yup.string()
        .nullable()
        .when("info_email_type", {
          is: (value) => value === emailTypes.SMTP,
          then: Yup.string()
            .required("Required")
            .test("is-port", "Must be a valid port", (value) => {
              if (!value) return true;
              if (!/^\d+$/.test(value)) return false;
              return Number(value) > 0 && Number(value) < 65536;
            }),
          otherwise: Yup.string().notRequired(),
        }),

      mailer_encryption_type: Yup.string().when("info_email_type", {
        is: (value) => value === emailTypes.SMTP,
        then: Yup.string().required("Required").max(4, "Must be at most 4 characters"),
        otherwise: Yup.string().notRequired(),
      }),
    }),

    onSubmit: async ({ name, chatbotLanguages, timeoutTimes, ...restSettings }, formikHelpers) => {
      LoadingHelper.open();

      if (restSettings.info_email_type === emailTypes.SES) {
        restSettings.mailer_encryption_type = "";
        restSettings.mailer_port = "";
        restSettings.mailer_domain = "";
        restSettings.mailer_password = "";
        restSettings.mailer_username = "";
      }

      if (!enableLLMOptions) {
        if (restSettings.llmSettings?.id) {
          await dispatch(deleteLlmSettings(project?.id, restSettings.llmSettings?.id));
        }
        restSettings.llmSettings = null;
      }

      try {
        let newProject;
        newProject = await dispatch(
          createOrUpdateProject(
            {
              name: name,
              id: id,
            },
            {
              successMsg: false,
              loading: false,
              onSuccess: () => {},
            }
          )
        );

        if (newProject?.id) {
          const payload = {
            timeout_times: timeoutTimes,
            chatbot_languages: chatbotLanguages,
            ...restSettings,
          };

          await dispatch(
            updateProjectSettings(newProject?.id, payload, {
              successMsg: false,
              loading: false,
            })
          );
        }
        await dispatch(
          updateCallcenterSettings(newProject?.id, formik.values.callcenterSettings, {
            loading: false,
          })
        );
        if (restSettings.llmSettings?.access_token === llmSettings.access_token) {
          delete restSettings.llmSettings.access_token;
        }

        if (restSettings.llmSettings) {
          await dispatch(
            updateLlmSettings(newProject?.id, restSettings.llmSettings, {
              loading: false,
            })
          );
        }

        await dispatch(getProject(newProject?.id));
        AlertHelper.showSuccess();

        if (!isShiftPressed) {
          HistoryHelper.goBack("/settings/project", { scope: "dashboard" });
        }
      } catch (error) {}
      LoadingHelper.close();
    },
  });

  useEffect(() => {
    if (llmSettings?.submodel || llmSettings?.access_token) {
      setEnableLLMOptions(true);
    }
  }, [llmSettings]);

  const availableLanguageListOptions = useMemo(() => {
    return projectLanguageOptions.filter((a) => formik.values.chatbotLanguages.includes(a.value));
  }, [formik.values.chatbotLanguages]);

  useEffect(() => {
    if (!selectedLanguage && availableLanguageListOptions?.length) {
      setSelectedLanguage(availableLanguageListOptions?.[0]?.value);
    }
  }, [availableLanguageListOptions, selectedLanguage]);

  useEffect(() => {
    if (id) {
      dispatch(getProject(id));
      dispatch(getCallcenterSettings(id));
      dispatch(getLlmSettings(id));
    }
    return () => {
      dispatch(setProject({}));
      dispatch(setLlmSettings({}));
      dispatch(setCallcenterSettings({}));
    };
  }, [dispatch, id]);

  const onAccessTokenFocus = () => {
    if (!firstAccessTokenFocus) {
      formik.setFieldValue("llmSettings.access_token", "");
      setFirstAccessTokenFocus(true);
    }
  };

  const handeEmailStatusChange = (value) => {
    formik.setFieldValue("email_status", value);
  };

  const handleEmailTypeChange = (value) => {
    formik.setFieldValue("info_email_type", value);
  };

  const handleChangeCallcenterType = (value) => {
    formik.setFieldValue("callcenterSettings.callcenter_type", value);
  };

  const handleChangeLlmType = (value) => {
    formik.setFieldValue("llmSettings.llm_type", value);
  };

  const handleClickCancel = () => {
    HistoryHelper.goBack("/settings/project", { scope: "dashboard" });
  };

  const handleChangeSelect = (value) => {
    formik.setFieldValue("chatbotLanguages", value);
  };
  const handleOptionReadonly = useCallback(
    (option) => {
      return !!project?.settings?.chatbot_languages.find((item) => item === option.value);
    },
    [project?.settings?.chatbot_languages]
  );

  const handleChangeTimeoutTimes = (value) => {
    formik.setFieldValue("timeoutTimes", {
      ...formik.values.timeoutTimes,
      [timeoutTab]: value,
    });
  };

  const handleChangeSwitch = (name) => (e) => {
    formik.setFieldValue(name, e.target.checked);
  };

  const parentName = [
    {
      label: "Project",
      url: "/settings/project/",
    },
    {
      label: `Project ${id ? "Edit" : "Create"}`,
    },
  ];

  const excludeFormatList = useMemo(() => {
    if ([projectTimeoutTimes.web].includes(offlineMessageTab)) {
      return [];
    }
    return [PRTextAreaFormat.html, PRTextAreaFormat.markdown];
  }, [offlineMessageTab]);

  const activeOfflineMessageData = useMemo(() => {
    const dataFiltered = formik.values.static_messages.filter((item) => {
      return (
        item.platform === offlineMessageTab && item.language === selectedLanguage && item.key === selectedTranslateKey
      );
    });

    return dataFiltered?.[0] || {};
  }, [formik.values.static_messages, offlineMessageTab, selectedLanguage, selectedTranslateKey]);

  const activeDefaultOfflineMessageData = useMemo(() => {
    const dataFiltered = formik.values.static_messages.filter((item) => {
      return item.platform === "default" && item.language === selectedLanguage && item.key === selectedTranslateKey;
    });

    return dataFiltered?.[0] || {};
  }, [formik.values.static_messages, selectedLanguage, selectedTranslateKey]);

  const handleChangeOfflineMessageValue = (e) => {
    const value = e.target.value;
    let isChanged = false;
    const mappedArray = formik.values.static_messages.map((item) => {
      let newItem = { ...item };
      if (
        item.platform === offlineMessageTab &&
        item.language === selectedLanguage &&
        item.key === selectedTranslateKey
      ) {
        newItem.value = value;
        isChanged = true;
      }

      newItem.format ??= PRTextAreaFormat.plain;
      return newItem;
    });

    if (!isChanged) {
      mappedArray.push({
        platform: offlineMessageTab,
        language: selectedLanguage,
        key: selectedTranslateKey,
        value: value,
        format: activeDefaultOfflineMessageData.format || PRTextAreaFormat.plain,
      });
    }
    formik.setFieldValue("static_messages", mappedArray);
  };

  const handleChangeOfflineMessageFormat = (format) => {
    let isChanged = false;
    const mappedArray = formik.values.static_messages.map((item) => {
      let newItem = { ...item };
      if (
        item.platform === offlineMessageTab &&
        item.language === selectedLanguage &&
        item.key === selectedTranslateKey
      ) {
        newItem.format = format;
        isChanged = true;
      }

      newItem.format ??= PRTextAreaFormat.plain;
      return newItem;
    });

    if (!isChanged) {
      mappedArray.push({
        platform: offlineMessageTab,
        language: selectedLanguage,
        key: selectedTranslateKey,
        value: activeDefaultOfflineMessageData.value || "",
        format: format,
      });
    }
    formik.setFieldValue("static_messages", mappedArray);
  };

  const handleChangeLanguageSystemMessage = (value) => {
    setSelectedLanguage(value);
  };
  return (
    <PRContainer name="Settings" parentName={parentName}>
      <PRPage title={"General"}>
        <Row className="g-2 w-lg-50">
          <Col xs={12}>
            <Label>Name:</Label>
            <PRInput
              invalid={formik.touched.name && formik.errors.name}
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Col>
          <Col xs={12}>
            <Label>Bot Languages:</Label>
            <PRSelect
              isMulti
              isPrimitiveValue
              disabled={id}
              invalid={formik.touched.chatbotLanguages && formik.errors.chatbotLanguages}
              isClearable={false}
              isOptionReadonly={handleOptionReadonly}
              options={projectLanguageOptions}
              value={formik.values.chatbotLanguages}
              onChange={handleChangeSelect}
            />
          </Col>

          <Col md="12">
            <Switch
              checked={formik.values.show_thumps_up}
              name="show_thumps_up"
              onChange={handleChangeSwitch("show_thumps_up")}
            />
            Show Thumps Up/Down
          </Col>
          <Col md="12">
            <Switch
              checked={formik.values.maintenance}
              name="maintenance"
              onChange={handleChangeSwitch("maintenance")}
            />
            <span className="text-danger">Enable Maintenance Mode</span>
          </Col>
        </Row>
      </PRPage>
      <PRPage title="Email">
        <Row className="g-2 w-lg-50">
          <Col xs={12}>
            <Label>Receiver Email:</Label>
            <PRInput
              invalid={formik.touched.receiver_email && formik.errors.receiver_email}
              name="receiver_email"
              value={formik.values.receiver_email}
              onChange={formik.handleChange}
            />
          </Col>
          <Col xs={12}>
            <Label>Sender Name:</Label>
            <PRInput
              invalid={formik.touched.info_email_name && formik.errors.info_email_name}
              name="info_email_name"
              value={formik.values.info_email_name}
              onChange={formik.handleChange}
            />
          </Col>
          <Col xs={12}>
            <Label>Sender Email:</Label>
            <PRInput
              invalid={formik.touched.info_email && formik.errors.info_email}
              name="info_email"
              value={formik.values.info_email}
              onChange={formik.handleChange}
            />
          </Col>
          {/* <Col xs={12}>
            <Switch checked={enableMailOptions} onChange={() => setEnableMailOptions(!enableMailOptions)} />
            <Label>Enable Mail Options</Label>
          </Col> */}
          <Col xs={12}>
            <Label>Sender Email Type:</Label>
            <PRSelect
              isPrimitiveValue
              invalid={formik.touched.info_email_type && formik.errors.info_email_type}
              isClearable={false}
              options={emailTypesOptions}
              value={formik.values.info_email_type}
              onChange={handleEmailTypeChange}
            />
          </Col>
          <Col xs={12}>
            <Label>Reply To Email:</Label>
            <PRInput
              invalid={formik.touched.reply_to_email && formik.errors.reply_to_email}
              name="reply_to_email"
              value={formik.values.reply_to_email}
              onChange={formik.handleChange}
            />
          </Col>
          <>
            {formik.values.info_email_type === emailTypes.SMTP && (
              <>
                <Col xs={12}>
                  <Label>SMTP Username:</Label>
                  <PRInput
                    invalid={formik.touched.mailer_username && formik.errors.mailer_username}
                    name="mailer_username"
                    value={formik.values.mailer_username}
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col xs={12}>
                  <Label>SMTP Password:</Label>
                  <PRInput
                    invalid={formik.touched.mailer_password && formik.errors.mailer_password}
                    name="mailer_password"
                    value={formik.values.mailer_password}
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col xs={12}>
                  <Label>SMTP Domain/IP:</Label>
                  <PRInput
                    invalid={formik.touched.mailer_domain && formik.errors.mailer_domain}
                    name="mailer_domain"
                    value={formik.values.mailer_domain}
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col xs={12}>
                  <Label>SMTP Port:</Label>
                  <PRInput
                    invalid={formik.touched.mailer_port && formik.errors.mailer_port}
                    name="mailer_port"
                    value={formik.values.mailer_port}
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col xs={12}>
                  <Label className="d-flex align-items-center">
                    SMTP Encryption Type:
                    <PalTooltip title={"Can be one of the following: ssl, tls"}>
                      <div>
                        <MdInfo />
                      </div>
                    </PalTooltip>
                  </Label>
                  <PRInput
                    invalid={formik.touched.mailer_encryption_type && formik.errors.mailer_encryption_type}
                    name="mailer_encryption_type"
                    value={formik.values.mailer_encryption_type}
                    onChange={formik.handleChange}
                  />
                </Col>
              </>
            )}
          </>
        </Row>
      </PRPage>
      <PRPage title="LLM">
        <Row className="g-2 w-lg-50">
          <Col xs={12}>
            <Switch checked={enableLLMOptions} onChange={() => setEnableLLMOptions(!enableLLMOptions)} />
            <Label>Enable LLM Options</Label>
          </Col>

          {enableLLMOptions && (
            <>
              <Col xs={12}>
                <Label>LLM Type:</Label>
                <PRSelect
                  isPrimitiveValue
                  className="w-xl"
                  invalid={formik.touched.llmSettings?.llm_type && formik.errors.llmSettings?.llm_type}
                  isClearable={false}
                  options={llmTypeOptions}
                  value={formik.values.llmSettings?.llm_type}
                  onChange={handleChangeLlmType}
                />
              </Col>
              <Col xs={12}>
                <Label>Submodel:</Label>
                <PRInput
                  invalid={formik.touched.llmSettings?.submodel && formik.errors.llmSettings?.submodel}
                  name="llmSettings.submodel"
                  value={formik.values.llmSettings?.submodel}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col xs={12}>
                <Label>Access Token:</Label>
                <PRInput
                  invalid={formik.touched.llmSettings?.access_token && formik.errors.llmSettings?.access_token}
                  name="llmSettings.access_token"
                  value={formik.values.llmSettings?.access_token}
                  onChange={formik.handleChange}
                  onClick={onAccessTokenFocus}
                />
              </Col>
            </>
          )}
        </Row>
      </PRPage>
      <PRPage title="Livechat & Ticket">
        <Row className="g-2 w-lg-50">
          {/* <Col xs={12}>
            <Label>Email Status:</Label>
            <b>{emailStatusMap[formik.values.email_status] || formik.values.email_status}</b>
          </Col> */}

          <Col xs={12}>
            <Label>Livechat mode:</Label>
            <PRSelect
              isPrimitiveValue
              className="w-xl"
              isClearable={false}
              options={livechatCallcenterTypeOptions}
              value={formik.values.callcenterSettings?.callcenter_type}
              onChange={handleChangeCallcenterType}
            />
          </Col>

          <Col md="12">
            <Switch
              checked={formik.values.callcenterSettings?.agent_can_leave_session}
              name="callcenterSettings.agent_can_leave_session"
              onChange={handleChangeSwitch("callcenterSettings.agent_can_leave_session")}
            />
            Enable Agents to Redirect to Bot
          </Col>
          <Col md="12">
            <Switch
              checked={formik.values.show_queue_order}
              name="show_queue_order"
              onChange={handleChangeSwitch("show_queue_order")}
            />
            Show Queue Order
          </Col>

          <Col md="12">
            <Switch
              checked={formik.values.chat_label_enabled}
              name="chat_label_enabled"
              onChange={handleChangeSwitch("chat_label_enabled")}
            />
            Enable Livechat Labels
          </Col>
          <Col md="12">
            <Switch
              checked={formik.values.ticket_enabled}
              name="ticket_enabled"
              onChange={handleChangeSwitch("ticket_enabled")}
            />
            Enable Help Desk & Ticket
          </Col>
          <Col md="12">
            <Switch
              checked={formik.values.direct_to_ticket_enabled}
              disabled={!formik.values.ticket_enabled}
              name="direct_to_ticket_enabled"
              onChange={handleChangeSwitch("direct_to_ticket_enabled")}
            />
            Show Direct to Ticket Button
          </Col>
          {/* <Col md="12">
            <Label size="md">Show Bot Sessions</Label>
            <PRInput
              checked={formik.values.callcenterSettings?.show_bot_sessions}
              name="callcenterSettings.show_bot_sessions"
              type="checkbox"
              onChange={formik.handleChange}
            />
          </Col> */}
        </Row>
      </PRPage>

      <PRPage title="Timeout Settings">
        <Row className="g-2">
          <PRTab tab={timeoutTab} tabList={projectTimeoutTimesOptions} valueSelector="value" onChange={setTimeoutTab} />
          <TimeoutTimesItem
            tab={timeoutTab}
            value={{
              ...defaultTimeoutTimes,
              ...formik.values.timeoutTimes?.[timeoutTab],
            }}
            onChange={handleChangeTimeoutTimes}
          />
        </Row>
      </PRPage>
      <PRPage title="System Messages">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="gap-2 d-flex align-items-center">
            <PRSelect
              isPrimitiveValue
              className="w-xl"
              isClearable={false}
              options={availableLanguageListOptions}
              value={selectedLanguage}
              onChange={handleChangeLanguageSystemMessage}
            />
            <PRSelect
              isPrimitiveValue
              className="w-xl"
              isClearable={false}
              options={projectTranslateKeyOptions}
              value={selectedTranslateKey}
              onChange={setSelectedTranslateKey}
            />
          </div>
        </div>
        <PRTab
          className="mb-1"
          tab={offlineMessageTab}
          tabList={projectTimeoutTimesOptions}
          valueSelector="value"
          onChange={setOfflineMessageTab}
        />
        <PRTextArea
          key={offlineMessageTab}
          editorMode
          editorProps={{
            defaultHeight: 180,
          }}
          excludeFormatList={excludeFormatList}
          format={activeOfflineMessageData.format || activeDefaultOfflineMessageData.format}
          value={activeOfflineMessageData.value ?? activeDefaultOfflineMessageData.value}
          onChange={handleChangeOfflineMessageValue}
          onFormatChange={handleChangeOfflineMessageFormat}
        />

        <Row className="justify-content-end mt-2">
          <Col md="auto">
            <PRButton outline className="" onClick={handleClickCancel}>
              Cancel
            </PRButton>
            <PRButton className="ms-2" onClick={formik.handleSubmit}>
              Save
            </PRButton>
          </Col>
        </Row>
      </PRPage>
    </PRContainer>
  );
}
