import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { withCardon } from "cardon";
import classNames from "classnames";
import { MdAdd, MdBlock, MdDelete, MdEdit, MdHelp, MdPerson, MdRemoveCircleOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Col, Label, Row } from "reactstrap";

import { Box, Switch } from "@mui/material";

import useLoading from "~common/hooks/useLoading";
import { RenderBreadcrumbActions } from "~components/Breadcrumb";
import PRButton from "~components/Generic/PRButton";
import PRCalendar from "~components/Generic/PRCalendar";
import PRContainer from "~components/Generic/PRContainer";
import PRModal from "~components/Generic/PRModal";
import PRPage from "~components/Generic/PRPage";
import PRSelect from "~components/Generic/PRSelect";
import PRTab from "~components/Generic/PRTab";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import {
  apiUrlOrganization,
  organizationResourceAttendanceStatus,
  organizationResourceAttendanceStatusMap,
  organizationVisibilityType,
  userRole,
} from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import FileHelper from "~helpers/FileHelper";
import LoadingHelper from "~helpers/LoadingHelper";
import { CustomerListCellRenderer } from "~pages/Organization/Customer/CustomerList";
import AddBulkReservationSlotsModal from "~pages/Organization/Resources/AddBulkReservationSlotsModal";
import AddEditOrganizationResourceModal from "~pages/Organization/Resources/AddEditOrganizationResourceModal";
import AddEditReservationSlotModal, {
  AttendanceButton,
  AttendanceStatusDot,
  EditReservationSlotDateModal,
} from "~pages/Organization/Resources/AddEditReservationSlotModal";
import {
  addBlacklistResourceUser,
  deleteResource,
  getMemberFieldFormatList,
  getReservationActions,
  getReservations,
  getResources,
  getSlots,
  removeBlacklistResourceUser,
  setResource,
} from "~store/organization/actions";
import {
  selectMemberFieldFormat,
  selectReservations,
  selectResources,
  selectSlots,
} from "~store/organization/selectors";
import { selectCurrentProject, selectUserInfo } from "~store/user/selectors";

import "./style.scss";

function resourceToSlotMapper(resourceList) {
  const mappedReservedView = (resourceList || []).map((reservation) => {
    let lowestStartDate;
    let highestEndDate;
    let lowestAvailableCount;
    for (const s of reservation.slot) {
      const startDate = DateHelper.getDateTimeLocal(s.start_date);
      const endDate = DateHelper.getDateTimeLocal(s.end_date);
      if (!lowestStartDate || startDate.isBefore(lowestStartDate)) {
        lowestStartDate = startDate;
      }
      if (!highestEndDate || endDate.isAfter(highestEndDate)) {
        highestEndDate = endDate;
      }
      if (!lowestAvailableCount || s.available_slots < lowestAvailableCount) {
        lowestAvailableCount = s.available_slots;
      }
    }
    return {
      ...reservation.slot[0],
      available_slots: lowestAvailableCount,
      start_date: lowestStartDate,
      end_date: highestEndDate,
      reservation,
    };
  });

  return mappedReservedView;
}

function slotToEventMapper(slot, { reservedView, resource, onRefreshCalendar }) {
  const isAvailable = slot.available_slots > 0;
  const total = slot.total || slot.available_slots;
  const reserved = total - slot.available_slots;
  const ratio = `${reserved}/${total}`;
  const eventIdentifier = isAvailable ? ratio + " Available" : ratio + " Unavailable";

  const startDate = DateHelper.getDateTimeLocal(slot.start_date);
  const endDate = DateHelper.getDateTimeLocal(slot.end_date);

  const allDay = startDate.clone().add(1, "day").isSame(slot.end_date, "milliseconds");
  const customer = slot.reservation?.customer;
  let eventTitle = eventIdentifier;
  let eventTitleTooltip = eventIdentifier;

  // const insideUserIdentifier = "✓";
  // if (identifierValue) {
  //   eventTitle += ` ${insideUserIdentifier}`;
  // }
  if (reservedView) {
    if (slot.reservation?.reserver) {
      let fullName = slot.reservation?.reserver?.safe_information?.full_name;
      if (slot.reservation?.reserver?.safe_information?.ad && slot.reservation?.reserver?.safe_information?.soyad) {
        fullName = `${slot.reservation?.reserver?.safe_information?.ad} ${slot.reservation?.reserver?.safe_information?.soyad}`;
      }
      const email = slot.reservation?.reserver?.safe_information?.email;

      const customerData = `${fullName} (${email})`;
      eventTitle = customerData;
    } else if (slot.reservation?.customer) {
      let fullName = slot.reservation?.customer?.full_name;
      if (slot.reservation?.customer?.ad && slot.reservation?.customer?.soyad) {
        fullName = `${slot.reservation?.customer?.ad} ${slot.reservation?.customer?.soyad}`;
      }
      const email = slot.reservation?.customer?.email;
      const customerData = `*${fullName} (${email})`;
      eventTitle = customerData;
    }

    eventTitleTooltip = eventTitle;

    eventTitle = (
      <Box alignItems="center" display="flex">
        <Box mr={0.25}>
          {/* <AttendanceStatusDot mini isAttended={slot.reservation?.is_attended} /> */}
          <AttendanceButton
            renderButton={(props) => {
              return <AttendanceStatusDot mini isAttended={slot.reservation?.is_attended} {...props} />;
            }}
            reservation={slot.reservation}
            resource={resource}
            onUpdated={onRefreshCalendar}
          />
        </Box>
        {eventTitle}
      </Box>
    );
  }
  const isBlacklisted = resource?.blacklist?.some((cus) => cus?.id === slot.reservation?.reserver?.id);
  return {
    id: slot.id,
    reservation: slot.reservation,
    title: eventTitle,
    tooltip: eventTitleTooltip,
    available: isAvailable,
    availableSlots: slot.available_slots,
    totalSlots: total,
    customer: customer,
    blacklist: isBlacklisted,
    start: startDate.toDate(),
    end: endDate.toDate(),
    allDay,
    start_date: startDate,
    end_date: endDate,
  };
}

function BlacklistViewContent({ get, blacklist, resource }) {
  const tableRef = useRef();
  const dispatch = useDispatch();

  const [blacklistList, setBlacklistList] = useState(blacklist || []);
  const memberFieldFormat = useSelector(selectMemberFieldFormat);
  const memberDataFields = memberFieldFormat?.member_data_fields;
  const currentProject = useSelector(selectCurrentProject);
  const [tab, setTab] = useState("members");
  const [isActionPerformed, setIsActionPerformed] = useState(false);
  const [filterActions, setFilterActions] = useState(null);

  const columns = useMemo(() => {
    if (!memberDataFields) return [];

    const handleBlacklist = (row, isExistingInBlacklist) => async () => {
      if (!(await DialogHelper.showQuestionYesNo())) return;
      if (!isExistingInBlacklist) {
        await dispatch(addBlacklistResourceUser(currentProject.id, resource.id, row.id));
        setBlacklistList((prev) => [...prev, row]);
      } else {
        await dispatch(removeBlacklistResourceUser(currentProject.id, resource.id, row.id));
        setBlacklistList((prev) => prev.filter((cus) => cus.id !== row.id));
      }
      setIsActionPerformed(true);
      tableRef.current.refresh();
    };

    const fields = [];
    fields.unshift({
      label: "#",
      key: "id",
    });
    if (memberDataFields.some((item) => item.visibility_type === organizationVisibilityType.PUBLIC)) {
      const memberOnlyFields = memberDataFields.map((item) => {
        const label = (
          <span
            className={classNames({
              "fw-semibold": item.identifier,
            })}
          >
            {item.display_name || item.name}
          </span>
        );

        return {
          label: label,
          key: `safe_information.${item.name}`,
          render: (obj, index, value) => (
            <CustomerListCellRenderer cellKey={item.name} memberDataFields={memberDataFields} value={value} />
          ),
        };
      });
      fields.push(...memberOnlyFields);
    }

    fields.push({
      label: "Actions",
      key: "actions",
      actions: true,
      fixed: "right",
      render: (row) => {
        const isExistingInBlacklist = blacklistList.some((cus) => cus.id === row.id);
        return (
          <div className="d-flex justify-content-center">
            <PRButton
              outline
              className="ms-1"
              color={!isExistingInBlacklist ? "danger" : "warning-600"}
              icon={!isExistingInBlacklist ? MdBlock : MdRemoveCircleOutline}
              size="sm"
              tooltipText={!isExistingInBlacklist ? "Add to Blacklist" : "Remove from Blacklist"}
              onClick={handleBlacklist(row, isExistingInBlacklist)}
            />
          </div>
        );
      },
    });

    return fields;
  }, [memberDataFields, dispatch, currentProject.id, resource.id, blacklistList]);

  const handleFilterSetActions = useCallback((actions) => {
    setFilterActions(actions);
  }, []);

  return (
    <PRModal
      size="xl"
      submitText={""}
      title={"Blacklist"}
      onClose={get(isActionPerformed)}
      // onDelete={reservation?.id ? handleClickDeleteReservation : undefined}
    >
      <PRTab
        className="mb-2 mt-4"
        tab={tab}
        tabList={[
          {
            id: "members",
            label: "Members",
          },
          {
            id: "blacklist",
            label: "Blacklisted Members",
          },
        ]}
        onChange={setTab}
      />
      {tab === "members" && (
        <>
          <div className="my-2 d-flex align-items-center justify-content-between">
            <div></div>
            {filterActions?.length && <RenderBreadcrumbActions actions={filterActions} />}
          </div>
          <PRTable
            ref={tableRef}
            inline
            columns={columns}
            customSetActions={handleFilterSetActions}
            url={apiUrlOrganization.getReservationMembers.format(currentProject.id)}
          />
        </>
      )}
      {tab === "blacklist" && <PRTable ref={tableRef} inline columns={columns} data={blacklistList} />}
    </PRModal>
  );
}

const BlacklistView = withCardon(BlacklistViewContent, { destroyOnHide: true });

export default function CreateResource() {
  const dispatch = useDispatch();

  const currentProject = useSelector(selectCurrentProject);
  const resources = useSelector(selectResources);
  const slots = useSelector(selectSlots);

  const tableDetailRef = useRef(null);

  const [selectedResourceId, setSelectedResourceId] = useState("");
  const [loadingResources, enqueue] = useLoading(true);
  const [calendarView, setCalendarView] = useState("month");
  const [dateRange, setDateRange] = useState();
  const [showAll, setShowAll] = useState(false);
  const [calendarRef, setCalendarRef] = useState();
  const [tab, setTab] = useState("calendar");
  const [reservedView, setReservedView] = useState(false);
  const [selectedTableSlot, setSelectedTableSlot] = useState();
  const [slotActions, setSlotActions] = useState(null);
  const [accordionState, setAccordionState] = useState({
    1: true,
    2: false,
  });
  const reservations = useSelector(selectReservations);
  const userInfo = useSelector(selectUserInfo);

  const hasProjectFullRight = [userRole.admin, userRole.organizationManager].some((role) =>
    currentProject?.permissions?.includes(role)
  );
  const hasFullAccess = userInfo.is_superuser || hasProjectFullRight;

  // Fetch initial once for blacklist and AddEditReservationSlot.
  useEffect(() => {
    dispatch(getMemberFieldFormatList(currentProject.id));
  }, [dispatch, currentProject?.id]);

  const handleToggleSlotView = (e) => {
    setReservedView(e.target.checked);
  };
  const handleClickAccordion = (id) => () => {
    setAccordionState({
      ...(id === 1 && {
        1: !accordionState[1],
        2: false,
      }),
      ...(id === 2 && {
        1: false,
        2: !accordionState[2],
      }),
    });
  };

  useEffect(() => {
    if (selectedResourceId) {
      dispatch(getReservationActions(currentProject.id, selectedResourceId))
        .then((response) => {
          const isArray = Array.isArray(response);
          // if (isArray) {
          //   response = response.filter((action) => !["add_blacklist", "remove_blacklist"].includes(action.button_type));
          // }
          setSlotActions(isArray ? response : []);
        })
        .catch(() => {
          setSlotActions([]);
        });
    }
  }, [dispatch, selectedResourceId, currentProject.id]);

  // const selectedResourceSlots = useMemo(() => {
  //   if (reservedView) {
  //     const mappedReservedView = resourceToSlotMapper(reservations);
  //     return mappedReservedView;
  //   }
  //   if (!slots) {
  //     return [];
  //   }
  //   return slots || [];
  // }, [slots, reservedView, reservations]);

  // const selectedResource = useSelector(selectResource);
  const selectedResource = useMemo(() => {
    if (!selectedResourceId) return null;
    return resources.find((resource) => resource.id === selectedResourceId);
  }, [resources, selectedResourceId]);

  const handleCalendarRef = useCallback((ref) => {
    setCalendarRef(ref);
  }, []);

  const getSlotsByDateRange = useCallback(
    (dateObj, fetchOptions) => {
      let rangeByRef;
      if (calendarRef && !dateObj) {
        rangeByRef = calendarRef.getView().range(calendarRef.props.date, { localizer: calendarRef.props.localizer });
      }
      const { start, end } = dateObj || rangeByRef || {};
      // console.log("start", start, "end", end);
      return dispatch(
        getSlots(
          selectedResourceId,
          currentProject.id,
          { start_date__gte: start, end_date__lte: end, limit: 99999 },
          fetchOptions
        )
      );
    },
    [calendarRef, dispatch, selectedResourceId, currentProject]
  );

  const getReservationsByDateRange = useCallback(
    (dateObj, fetchOptions) => {
      let rangeByRef;
      if (calendarRef && !dateObj) {
        rangeByRef = calendarRef.getView().range(calendarRef.props.date, { localizer: calendarRef.props.localizer });
      }
      const { start, end } = dateObj || rangeByRef || {};
      // console.log("start", start, "end", end);
      return dispatch(
        getReservations(
          selectedResourceId,
          currentProject.id,
          {
            start_date__gte: start.toISOString(),
            end_date__lte: end.toISOString(),
            limit: 99999,
          },
          fetchOptions
        )
      );
    },
    [calendarRef, dispatch, selectedResourceId, currentProject]
  );

  const calendarEvents = useMemo(() => {
    let activeSlots = slots || [];
    if (reservedView) {
      const resourceSlots = resourceToSlotMapper(reservations);
      activeSlots = resourceSlots || [];
    }

    if (!activeSlots) return [];
    const mappedCalendarEvents = activeSlots.map((slot) =>
      slotToEventMapper(slot, {
        reservedView,
        resource: selectedResource,
        onRefreshCalendar: () => {
          if (reservedView) {
            getReservationsByDateRange(dateRange);
          } else {
            getSlotsByDateRange(dateRange);
          }
        },
      })
    );

    return mappedCalendarEvents;
  }, [reservedView, selectedResource, getSlotsByDateRange, getReservationsByDateRange, dateRange, slots, reservations]);

  const fetchEvents = useCallback(
    async (modalDateRange) => {
      let reservationsResult = [];
      let slotsResult = [];
      if (reservedView) {
        reservationsResult =
          (
            await getReservationsByDateRange(modalDateRange, {
              onSuccess: () => {},
            })
          )?.results || [];
      } else {
        slotsResult =
          (
            await getSlotsByDateRange(modalDateRange, {
              onSuccess: () => {},
            })
          )?.results || [];
      }

      let activeSlots = slotsResult || [];
      if (reservedView) {
        const resourceSlots = resourceToSlotMapper(reservationsResult);

        activeSlots = resourceSlots.map((event) => event?.reservation?.slot || []).flat() || [];
        //filter by modalDateRange's start and end
        activeSlots = activeSlots.filter((slot) => {
          const startDate = DateHelper.getDateTime(slot.start_date);
          return (
            startDate.isBetween(modalDateRange.start, modalDateRange.end) || startDate.isSame(modalDateRange.start)
          );
        });

        //remove duplicate by id
        activeSlots = activeSlots.filter((slot, index, self) => self.findIndex((s) => s.id === slot.id) === index);
      }

      if (!activeSlots) return [];
      const mappedCalendarEvents = activeSlots.map((slot) =>
        slotToEventMapper(slot, {
          reservedView,
          resource: selectedResource,
          onRefreshCalendar: () => {
            if (reservedView) {
              getReservationsByDateRange(dateRange);
            } else {
              getSlotsByDateRange(dateRange);
            }
          },
        })
      );

      return mappedCalendarEvents;
    },
    [getReservationsByDateRange, getSlotsByDateRange, reservedView, dateRange, selectedResource]
  );
  // useEffect(() => {
  //   enqueue(dispatch(getResource(selectedResourceId, currentProject.id)));
  // }, [dispatch, currentProject.id, enqueue, selectedResourceId]);

  useEffect(() => {
    enqueue(dispatch(getResources(currentProject.id))).then(({ results = [] }) => {
      setSelectedResourceId(results?.[0]?.id);
      if (!results?.[0]?.id) {
        dispatch(setResource({}));
      }
    });
    // dispatch(getMembers(currentProject.id)); //for 'Add/Edit modal'
  }, [dispatch, currentProject.id, enqueue]);

  const resourceOptions = useMemo(() => {
    const mappedData = resources.map((item) => ({
      value: item.id,
      label: `${item.name} (${item.resource_type} - ${item.id})`,
    }));
    mappedData.reverse();
    return mappedData;
  }, [resources]);

  const handleClickAddResource = async () => {
    const result = await AddEditOrganizationResourceModal.show();
    if (result) {
      await dispatch(getResources(currentProject.id));
      setSelectedResourceId(result.id);
    }
  };
  const handleClickEditResource = async () => {
    const result = await AddEditOrganizationResourceModal.show({ resourceId: selectedResourceId });
    if (result) {
      dispatch(getResources(currentProject.id));
      tableDetailRef.current.refresh();
    }
  };

  const handleClickOpenBlacklist = async () => {
    const result = await BlacklistView.show({ blacklist: selectedResource?.blacklist, resource: selectedResource });
    if (result) {
      dispatch(getResources(currentProject.id));
      // tableDetailRef.current.refresh();
    }
  };
  const handleClickAddResourceSlots = async () => {
    const result = await AddBulkReservationSlotsModal.show({
      resourceId: selectedResourceId,
    });
    if (result) {
      dispatch(getResources(currentProject.id));
      if (reservedView) {
        getReservationsByDateRange(dateRange);
      } else {
        getSlotsByDateRange(dateRange);
      }
    }
  };
  const handleClickDeleteResource = async () => {
    if (!(await DialogHelper.showQuestionDelete())) {
      return;
    }
    await dispatch(deleteResource(selectedResourceId, currentProject.id));
    dispatch(getResources(currentProject.id));
    if (resources.length > 0) {
      setSelectedResourceId(resources[0].id);
    }
  };

  const handleClickAddSingleSlot = useCallback(
    (date) => async () => {
      const result = await AddEditReservationSlotModal.show({
        resource: selectedResource,
        date,
        fetchEvents: fetchEvents,
        reservedMode: reservedView,
        slotActions,
      });
      if (result) {
        dispatch(getResources(currentProject.id));
        if (reservedView) {
          getReservationsByDateRange(dateRange);
        } else {
          getSlotsByDateRange(dateRange);
        }
      }
    },
    [
      selectedResource,
      currentProject.id,
      getSlotsByDateRange,
      getReservationsByDateRange,
      dateRange,
      dispatch,
      reservedView,
      fetchEvents,
      slotActions,
    ]
  );

  const handleClickEditSingleSlot = useCallback(
    (date) => async () => {
      const beginOfTheDay = DateHelper.getDateTimeLocal(date).startOf("day");
      const endOfTheDay = beginOfTheDay.clone().add(1, "day");
      const relatedEvents = calendarEvents.filter((slot) => {
        const startDate = DateHelper.getDateTime(slot.start);
        return startDate.isBetween(beginOfTheDay, endOfTheDay) || startDate.isSame(beginOfTheDay);
      });

      let expandedRelatedEvents = relatedEvents.map((event) => event?.reservation?.slot || []).flat();
      //remove duplicate by id
      expandedRelatedEvents = expandedRelatedEvents.filter(
        (slot, index, self) => self.findIndex((s) => s.id === slot.id) === index
      );
      const mappedRelatedEvents = expandedRelatedEvents.map((slot) =>
        slotToEventMapper(slot, { reservedView, resource: selectedResource })
      );

      const result = await AddEditReservationSlotModal.show({
        resource: selectedResource,
        events: reservedView ? mappedRelatedEvents : relatedEvents,
        fetchEvents: fetchEvents,
        date,
        reservedMode: reservedView,
        slotActions,
      });
      if (result) {
        dispatch(getResources(currentProject.id));
        if (reservedView) {
          getReservationsByDateRange(dateRange);
        } else {
          getSlotsByDateRange(dateRange);
        }
      }
    },
    [
      selectedResource,
      currentProject.id,
      calendarEvents,
      getSlotsByDateRange,
      dateRange,
      dispatch,
      reservedView,
      getReservationsByDateRange,
      fetchEvents,
      slotActions,
    ]
  );

  // const presentCalendarEvents = useMemo(() => {
  //   return calendarEvents.filter((event) => {
  //     const end = DateHelper.getDateTimeLocal(event.end);
  //     const now = DateHelper.getDateTimeLocal();
  //     return end.isAfter(now);
  //   });
  // }, [calendarEvents]);

  const eventWrapperComponent = useCallback((props) => {
    const selected = props.selected;
    return (
      <div
        //add danger background bootstrap class
        className={classNames({
          "calendar-day-available": !props.event.customer && props.event.available,
          "calendar-day-scheduled-customer": props.event.customer && props.event.available,
          "calendar-day-selected": selected,
          "calendar-day-blacklist": props.event.blacklist,
        })}
      >
        {props.children}
      </div>
    );
  }, []);
  const dateCellWrapperComponent = useCallback(
    function (props) {
      const childrenClassName = props.children.props.className;
      const cellDate = props.value;

      const relevantEvents = calendarEvents.filter((event) => {
        const eventDate = event.start;
        return DateHelper.getDateTimeLocal(eventDate).isSame(DateHelper.getDateTimeLocal(cellDate), "day");
      });

      const isToday = DateHelper.getDateTimeLocal(cellDate).isSame(DateHelper.getDateTimeLocal(new Date()), "day");
      const unavailableSlots = relevantEvents.filter((event) => !event.available);
      return (
        <div
          className={classNames(childrenClassName, {
            border: isToday,
            "border-secondary": isToday,
            "calendar-cell-wrapper": true,
          })}
        >
          <Row className="h-100 align-items-end justify-content-between">
            <Col xs="auto"></Col>
            <Col xs="auto">
              <div className="d-flex align-items-center">
                {calendarView === "month" && (
                  <>
                    {!!unavailableSlots?.length && (
                      <PRTooltip title={"Total scheduled meeting"}>
                        <div className="d-flex align-items-center font-size-13 fw-bold ms-1 mb-1 me-2 text-primary">
                          {unavailableSlots.length}
                          <MdPerson className="ms-1 " />
                        </div>
                      </PRTooltip>
                    )}
                    {!relevantEvents?.length && !reservedView && (
                      <PRButton
                        outline
                        className="me-1 mb-1"
                        color="success-600"
                        disabled={!selectedResourceId}
                        icon={MdAdd}
                        size="sm"
                        onClick={handleClickAddSingleSlot(cellDate)}
                      />
                    )}
                    {!!relevantEvents?.length && (
                      <PRButton
                        outline
                        className="me-1 mb-1"
                        color="primary"
                        disabled={!selectedResourceId}
                        icon={MdEdit}
                        size="sm"
                        onClick={handleClickEditSingleSlot(cellDate)}
                      />
                    )}
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      );
    },
    [calendarEvents, calendarView, handleClickAddSingleSlot, handleClickEditSingleSlot, selectedResourceId]
  );

  const handleDoubleClickCalendar = useCallback(
    async (e) => {
      const result = await AddEditReservationSlotModal.show({
        resource: selectedResource,
        events: [e],
        fetchEvents: fetchEvents,
        date: e.start,
        doubleClick: true,
        reservedMode: reservedView,
        slotActions,
      });
      if (result && selectedResource?.id) {
        dispatch(getResources(currentProject.id));
        if (reservedView) {
          getReservationsByDateRange(dateRange);
        } else {
          getSlotsByDateRange(dateRange);
        }
      }
    },
    [
      selectedResource,
      currentProject.id,
      getSlotsByDateRange,
      dateRange,
      dispatch,
      getReservationsByDateRange,
      reservedView,
      fetchEvents,
      slotActions,
    ]
  );

  const calendarComponents = useMemo(() => {
    return {
      eventWrapper: eventWrapperComponent,
      dateCellWrapper: dateCellWrapperComponent,
    };
  }, [eventWrapperComponent, dateCellWrapperComponent]);

  const handleRangeChanged = useCallback((range, view, showAll) => {
    setShowAll(showAll);
    if (showAll) {
      const ago = DateHelper.getDateTimeLocal().subtract(5, "year");
      const later = DateHelper.getDateTimeLocal().add(5, "year");
      setDateRange({ start: ago, end: later });
      return;
    }
    let startDate, endDate;
    if (range.start && range.end) {
      startDate = DateHelper.getDateTimeLocal(range.start).startOf("day");
      endDate = DateHelper.getDateTimeLocal(range.end).startOf("day").add(1, "day");
      // endDate = DateHelper.getDateTimeLocal(range.end).endOf("month");
    } else {
      const days = range || [];
      startDate = DateHelper.getDateTimeLocal(days[0]).startOf("day");
      endDate = DateHelper.getDateTimeLocal(days[days.length - 1])
        .startOf("day")
        .add(1, "day");
      // endDate = DateHelper.getDateTimeLocal(days[days.length - 1]).endOf("day");
    }
    setDateRange({ start: startDate, end: endDate });
  }, []);

  useEffect(() => {
    if (!selectedResourceId || (!dateRange && !calendarRef) || !currentProject.id) return;
    if (reservedView) {
      getReservationsByDateRange(dateRange);
    } else {
      getSlotsByDateRange(dateRange);
    }
  }, [
    getSlotsByDateRange,
    dispatch,
    selectedResourceId,
    currentProject.id,
    dateRange,
    calendarRef,
    reservedView,
    getReservationsByDateRange,
  ]);

  // const totalSlots = calendarEvents.length;
  // const totalSlotsPresent = presentCalendarEvents.length;
  // const totalSlotsAvailable = calendarEvents.filter((slot) => slot.available).length;
  // const totalSlotAvailablePresent = presentCalendarEvents.filter((slot) => slot.available).length;
  // const totalSlotsReserved = totalSlots - totalSlotsAvailable;
  // const totalSlotsReservedPresent = totalSlotsPresent - totalSlotAvailablePresent;

  const handleClickDownload = useCallback(
    (events, type) => {
      const timestamp = Date.now();
      const { start, end } = dateRange;
      DialogHelper.showQuestionYesNo(
        "Download as " + type.toUpperCase(),
        "Are you sure you want to download the all reservations? This process may take a while."
      ).then(async (result) => {
        if (result) {
          LoadingHelper.open();
          try {
            // const response = await dispatch(
            //   getSlots(selectedResourceId, currentProject.id, {
            //     start_date__gte: start,
            //     end_date__lte: end,
            //     limit: 99999,
            //   })
            // );
            const filterStart = start.clone();
            const filterEnd = end.clone();
            filterStart.utcOffset(0, true);
            filterEnd.utcOffset(0, true);

            const responseReservation = await dispatch(
              getReservations(selectedResourceId, currentProject.id, {
                start_date__gte: filterStart.toISOString(),
                end_date__lte: filterEnd.toISOString(),
                limit: 99999,
              })
            );

            const reservationResults = responseReservation.results || [];
            // const slotResults = response.results || [];
            // const detailedSlotResults = await Promise.all([
            //   ...slotResults.map((slot) => dispatch(getSlot(slot.id, selectedResourceId, currentProject.id, true))),
            // ]);
            // const tasks = slotResults.map(
            //   (slot) => () => dispatch(getSlot(slot.id, selectedResourceId, currentProject.id, true))
            // );

            // Limit to x concurrent tasks
            // const detailedSlotResults = await Utils.promiseRun(tasks, 1);

            // const flatReservations = detailedSlotResults.reduce((acc, slot) => {
            //   const { title, start_date, end_date, reservation = [] } = slot || {};

            //   const mappedReservation = reservation.map((res) => {
            //     return {
            //       ...res,
            //       title,
            //       start: start_date,
            //       end: end_date,
            //     };
            //   });
            //   return [...acc, ...mappedReservation];
            // }, []);

            const mappedDataRaw = [];
            const allSlots = reservationResults.map((event) => event.slot).flat();
            const allUniqueSlots = allSlots.filter(
              (slot, index, self) => self.findIndex((s) => s.id === slot.id) === index
            );
            const sortedAllSlotsByStartDate = allUniqueSlots.sort((a, b) => {
              const startOfDayA = DateHelper.getDateTimeLocal(a.start_date).startOf("day");
              const minutesSinceStartA = DateHelper.getDateTimeLocal(a.start_date).diff(startOfDayA, "minutes");

              const startOfDayB = DateHelper.getDateTimeLocal(b.start_date).startOf("day");
              const minutesSinceStartB = DateHelper.getDateTimeLocal(b.start_date).diff(startOfDayB, "minutes");

              return minutesSinceStartA - minutesSinceStartB;
            });

            const mappedAllSlots = sortedAllSlotsByStartDate.map((slot) => {
              const startTime = DateHelper.getDateTimeLocal(slot.start_date).format("LT");
              const endTime = DateHelper.getDateTimeLocal(slot.end_date).format("LT");
              let timeTextKey = `${startTime} - ${endTime}`;
              if (slot.allDay) timeTextKey = "All Day";

              return {
                [`${timeTextKey}`]: "",
              };
            });

            const aggregatedAllSlotsFormat = mappedAllSlots.reduce((acc, slot) => {
              return {
                ...acc,
                ...slot,
              };
            }, {});

            for (const event of reservationResults) {
              const customerFields = event.reserver?.safe_information || event.customer || {};
              const slots = [...event?.slot];
              const sortedSlotsByStartDate = slots.sort((a, b) => {
                return DateHelper.getDateTimeLocal(a.start_date).diff(DateHelper.getDateTimeLocal(b.start_date));
              });
              const startDateMoment = DateHelper.getDateTimeLocal(sortedSlotsByStartDate[0].start_date);
              const startDate = startDateMoment.toDate();
              const startTime = DateHelper.getDateTimeLocal(sortedSlotsByStartDate[0].start_date).format("LT");
              const endTime = DateHelper.getDateTimeLocal(
                sortedSlotsByStartDate[sortedSlotsByStartDate.length - 1].end_date
              ).format("LT");
              // const endDate = DateHelper.getDateTimeLocal(sortedSlotsByStartDate[sortedSlotsByStartDate.length - 1].end_date).toDate();

              const mappedSlots = sortedSlotsByStartDate.map((slot) => {
                const startTime = DateHelper.getDateTimeLocal(slot.start_date).format("LT");
                const endTime = DateHelper.getDateTimeLocal(slot.end_date).format("LT");
                let timeTextKey = `${startTime} - ${endTime}`;
                if (slot.allDay) timeTextKey = "All Day";

                return {
                  [`${timeTextKey}`]: "X",
                };
              });

              const aggregatedSlots = mappedSlots.reduce((acc, slot) => {
                return {
                  ...acc,
                  ...slot,
                };
              }, {});
              const slotPickFormat = {
                ...aggregatedAllSlotsFormat,
              };
              for (const key in aggregatedSlots) {
                slotPickFormat[key] = aggregatedSlots[key];
              }

              mappedDataRaw.push({
                _date: sortedSlotsByStartDate?.[0]?.start_date,
                Calendar: selectedResource?.name,
                Date: startDateMoment.format("DD.MM.YYYY"),
                TimeRange: `${startTime} - ${endTime}`,

                AttendedStatus:
                  organizationResourceAttendanceStatus.no_info === event?.is_attended
                    ? "-"
                    : organizationResourceAttendanceStatusMap[event?.is_attended] || event?.is_attended,
                ...customerFields,
                "İşlem Adı": event?.secret_note?.islem_adi,
                Fiyat: event?.secret_note?.fiyat,
                note: event?.note,
                ...slotPickFormat,
              });
            }
            // const mappedData = flatReservations.map((event) => {
            //   const { title, start, end, customer, reserver } = event;
            //   const startDate = DateHelper.getDateTimeLocal(start).toDate();
            //   const startTime = DateHelper.getDateTimeLocal(start).format("LT");
            //   const endTime = DateHelper.getDateTimeLocal(end).format("LT");
            //   let timeText = `${startTime} - ${endTime}`;
            //   if (event.allDay) timeText = "All Day";
            //   return {
            //     Date: startDate,
            //     Time: timeText,
            //     // Event: title,
            //     ...(reserver?.safe_information || customer || {}),
            //   };
            // });

            const mappedData = mappedDataRaw
              .sort((a, b) => {
                return DateHelper.getDateTimeLocal(a._date).diff(DateHelper.getDateTimeLocal(b._date));
              })
              .map((item) => {
                delete item._date;
                return item;
              });

            if (!mappedData?.length) {
              AlertHelper.show("Data not available in selected date", "warning");
              return;
            }
            if (type === "csv") {
              FileHelper.exportAsCSV(mappedData, `agenda_${timestamp}`);
            } else if (type === "xlsx") {
              FileHelper.exportAsExcel(mappedData, `agenda_${timestamp}`);
            }
          } finally {
            LoadingHelper.close();
          }
        }
      });
      return false;
    },
    [selectedResourceId, dateRange, dispatch, currentProject.id]
  );

  const columns = useMemo(() => {
    return [
      {
        label: "Start Date",
        key: "start_date",
        render: (row) => {
          return DateHelper.getDateTimeLocal(row.start_date).format("LLT");
        },
      },
      {
        label: "End Date",
        key: "end_date",
        render: (row) => {
          return DateHelper.getDateTimeLocal(row.end_date).format("LLT");
        },
      },
      {
        label: "Total",
        key: "total",
      },
      {
        label: "Reserved",
        key: "reserved",
      },
      {
        label: "Available",
        key: "available_slots",
        render: (row) => {
          return (
            <b
              className={classNames({
                "text-success-600": row.available_slots > 0,
                "text-danger": !(row.available_slots > 0),
              })}
            >
              {row.available_slots || 0}
            </b>
          );
        },
      },
    ];
  }, []);

  const columnsDetail = useMemo(() => {
    // const handleEditReservation = (row) => async (e) => {
    //   const result = await AddEditReservationSlotDetailModal.show({
    //     reservation: row,
    //     slotId: selectedTableSlot,
    //     resource: selectedResource,
    //   });

    //   if (result) {
    //     await dispatch(getSlot(selectedTableSlot, selectedResource?.id, currentProject.id, true));
    //     // setIsModified(true);

    //     await dispatch(getResources(currentProject.id));
    //     tableDetailRef.current.refresh();
    //   }
    // };
    const handleEditReservation = (row) => async (e) => {
      const mappedRelatedEvents = row.slot.map((slot) =>
        slotToEventMapper(slot, { reservedView, resource: selectedResource })
      );

      const result = await EditReservationSlotDateModal.show({
        // events,
        // reservation: reservation,
        // resource,
        editMode: true,
        slotActions: slotActions,
        reservation: row,
        resource: selectedResource,
        events: mappedRelatedEvents,
        fetchEvents: fetchEvents,
        date: DateHelper.getDateTimeLocal(row.slot[0].start_date).startOf("day").toDate(),
        reservedMode: reservedView,
      });

      if (result) {
        // await dispatch(getSlot(selectedTableSlot, selectedResource?.id, currentProject.id, true));
        // setIsModified(true);

        // await dispatch(getResources(currentProject.id));
        tableDetailRef.current.refresh();
      }
    };
    // const handleDeleteReservation = (row) => async (e) => {
    //   if (!(await DialogHelper.showQuestionDelete())) return;

    //   dispatch(deleteReservation(row.id, selectedResource?.id, currentProject.id)).then(() => {
    //     // dispatch(getReservations(resourceId, currentProject.id));
    //     dispatch(getSlot(row.slot, selectedResource?.id, currentProject.id, true)).then(() => {
    //       tableDetailRef.current.refresh();
    //     });
    //   });
    // };

    return [
      {
        label: "Date",
        key: "start_date",
        render: (row) => {
          const sortedSlots = [...row.slot]?.sort((a, b) => {
            return DateHelper.getDateTimeLocal(a.start_date).diff(DateHelper.getDateTimeLocal(b.start_date));
          });

          const startDate = DateHelper.getDateTimeLocal(sortedSlots?.[0]?.start_date).format("L");
          return startDate;
        },
      },
      {
        label: "Time",
        key: "time_range",
        render: (row) => {
          const sortedSlots = [...row.slot]?.sort((a, b) => {
            return DateHelper.getDateTimeLocal(a.start_date).diff(DateHelper.getDateTimeLocal(b.start_date));
          });

          const startTime = DateHelper.getDateTimeLocal(sortedSlots?.[0]?.start_date).format("LT");
          const endTime = DateHelper.getDateTimeLocal(sortedSlots?.[sortedSlots.length - 1]?.end_date).format("LT");

          return `${startTime} - ${endTime}`;
        },
      },
      // {
      //   label: "Full Name",
      //   key: "reserver.safe_information.full_name",
      // },
      // {
      //   label: "Email",
      //   key: "reserver.safe_information.email",
      // },
      {
        label: "Note",
        key: "note",
      },
      {
        label: "Actions",
        key: "actions",
        actions: true,
        render: (row) => {
          // const handleClickDelete = async () => {
          //   if (!(await DialogHelper.showQuestionDelete())) return;
          //   dispatch(deleteIntentTag(currentProject.id, currentBot.id, row.id)).then(() => {
          //     tableRef.current.refresh();
          //   });
          // };

          return (
            <div className="d-flex justify-content-center">
              <PRButton
                outline
                color="primary"
                icon={MdEdit}
                size="sm"
                tooltipText="Edit"
                onClick={handleEditReservation(row)}
              />
              {/* <PRButton
                outline
                className="ms-1"
                color="danger"
                icon={MdDelete}
                size="sm"
                tooltipText="Delete"
                onClick={handleDeleteReservation(row)}
              /> */}
            </div>
          );
        },
      },
    ];
  }, [selectedResource, tableDetailRef, slotActions, reservedView, fetchEvents]);

  const handleClickTab = (tabId) => {
    setTab(tabId);
  };

  const handelClickRow = (id) => {
    setSelectedTableSlot(id);

    setAccordionState({
      1: false,
      2: true,
    });
  };

  // const handleReservationAddUpdate = async (e) => {
  //   const result = await AddEditReservationSlotDetailModal.show({
  //     reservation: null,
  //     slotId: selectedTableSlot,
  //     resource: selectedResource,
  //   });

  //   if (result) {
  //     dispatch(getSlot(selectedTableSlot, selectedResource.id, currentProject.id, true));
  //   }
  // };
  return (
    <PRContainer
      className="pr-resource"
      description={"Here you can create and manage your calendars."}
      name="Organization"
      parentName={"Reservations"}
    >
      <PRPage>
        <Row className="mt-2">
          <Col lg="auto">
            <Label className="" size="md">
              Calendar
            </Label>
          </Col>

          <Col lg className="d-flex align-items-center">
            <PRSelect
              fullWidth
              isPrimitiveValue
              isClearable={false}
              isLoading={loadingResources}
              options={resourceOptions}
              placeholder="Select calendar"
              value={selectedResourceId}
              onChange={setSelectedResourceId}
            />

            {hasFullAccess && (
              <PRButton
                outline
                className="ms-2"
                color="danger"
                disabled={!selectedResourceId}
                icon={MdDelete}
                onClick={handleClickDeleteResource}
              />
            )}
            <PRButton
              className=" ms-2"
              // disabled={!selectedResource?.blacklist?.length}
              icon={MdBlock}
              onClick={handleClickOpenBlacklist}
            />
            {hasFullAccess && (
              <>
                <PRButton
                  className=" ms-2"
                  disabled={!selectedResourceId}
                  icon={MdEdit}
                  onClick={handleClickEditResource}
                />
                <PRButton className=" ms-2" color="success" icon={MdAdd} onClick={handleClickAddResource} />
              </>
            )}
          </Col>
        </Row>
        <PRTab
          className="mb-2 mt-4"
          tab={tab}
          tabList={[
            {
              id: "calendar",
              label: "Calendar",
            },
            {
              id: "list",
              label: "Reservations",
            },
          ]}
          onChange={handleClickTab}
        />

        {/* <PRDivider className="my-3" /> */}
        {/* <PRDividerLabel pageSeparator className="my-3">
          Reservation Slots
        </PRDividerLabel> */}

        {tab === "calendar" && (
          <>
            <Box alignItems="center" className="mb-2" display="flex">
              <Switch checked={reservedView} onChange={handleToggleSlotView} />
              <Label className="font-size-16" size="md" tag="h6">
                {reservedView ? "Reserved View" : "Slot View"}
              </Label>
            </Box>
            <Row className="g-2 mb-2 align-items-center justify-content-between">
              <Col className="d-flex align-items-center " xs="auto">
                <Label className="font-size-16" tag="h6">
                  Total slots:
                </Label>
                <Label className="font-size-16 ms-1 fw-semibold" tag="h6">
                  {selectedResource?.reservation_status?.all?.count || 0}
                </Label>
              </Col>
              <Col className="d-flex align-items-center" xs="auto">
                <Label className="font-size-16" tag="h6">
                  Available slots:
                </Label>
                <Label className={"font-size-16 ms-1 fw-semibold d-flex align-items-center lh-1"} tag="h6">
                  <span
                    className={classNames({
                      "text-success-600": (selectedResource?.reservation_status?.all?.available_count || 0) > 0,
                      "text-danger": (selectedResource?.reservation_status?.all?.available_count || 0) === 0,
                    })}
                  >
                    {selectedResource?.reservation_status?.future?.available_count || 0}
                  </span>
                  <span className="ms-1 fs-6 d-flex align-items-center lh-1">
                    ({selectedResource?.reservation_status?.all?.available_count || 0}
                    <PRTooltip title="Total available slots in past and future">
                      <span>
                        <MdHelp className="fs-6 ms-1" />
                      </span>
                    </PRTooltip>
                    )
                  </span>
                </Label>
              </Col>
              <Col className="d-flex align-items-center" xs="auto">
                <Label className="font-size-16" tag="h6">
                  Reserved slots:
                </Label>
                <Label className={"font-size-16 ms-1 fw-semibold d-flex align-items-center"} tag="h6">
                  <span
                    className={classNames({
                      "text-primary": selectedResource?.reservation_status?.future?.reservation_count || 0 > 0,
                    })}
                  >
                    {selectedResource?.reservation_status?.all?.reservation_count || 0}
                  </span>
                  <span className="ms-1 fs-6 d-flex align-items-center lh-1">
                    ({selectedResource?.reservation_status?.future?.reservation_count || 0}
                    <PRTooltip title="Total reserved slots in past and future">
                      <span>
                        <MdHelp className="fs-6 ms-1" />
                      </span>
                    </PRTooltip>
                    )
                  </span>
                </Label>
              </Col>
              <Col xs="auto">
                <PRButton
                  className="fw-medium ms-2"
                  color="success"
                  disabled={!selectedResourceId}
                  icon={MdAdd}
                  onClick={handleClickAddResourceSlots}
                >
                  Add Slots
                </PRButton>
              </Col>
            </Row>

            <PRCalendar
              ref={handleCalendarRef}
              components={calendarComponents}
              events={calendarEvents}
              showAll={showAll}
              tooltipAccessor="tooltip"
              view={calendarView}
              onClickDownload={handleClickDownload}
              onDoubleClickEvent={handleDoubleClickCalendar}
              onRangeChange={handleRangeChanged}
              onView={setCalendarView}
            />
          </>
        )}

        {tab === "list" && (
          <div>
            <PRTable
              ref={tableDetailRef}
              inline
              {...(selectedResourceId && {
                url: apiUrlOrganization.getReservation.format(currentProject.id, selectedResourceId),
              })}
              columns={columnsDetail}
              // responseDataCountKey={"total"}
              // responseDataKey={"reservation"}
            />
            {/* <Label className="fw-semibold mt-2">Reservation Slots</Label> */}

            {/* <PRAccordion flush>
              <PRAccordionItem
                secondary
                collapsed={accordionState[1]}
                title={"Reservations"}
                onClick={handleClickAccordion(1)}
              >
                <Row>
                  <Col xs></Col>
                  <Col xs="auto">
                    <PRButton
                      className="fw-medium ms-2"
                      color="success"
                      disabled={!selectedResourceId}
                      icon={MdAdd}
                      onClick={handleClickAddResourceSlots}
                    >
                      Add Slots
                    </PRButton>
                  </Col>
                </Row>
                <PRTable
                  inline
                  onRowClick={handelClickRow}
                  {...(selectedResourceId && {
                    url: apiUrlOrganization.getResourceAddSlot.format(currentProject.id, selectedResourceId),
                  })}
                  columns={columns}
                  selectedRowItem={selectedTableSlot}
                />
              </PRAccordionItem>
              <PRAccordionItem
                secondary
                collapsed={accordionState[2]}
                title={"Reservation Slots"}
                onClick={handleClickAccordion(2)}
              >
                <Row className="justify-content-end g-1 mt-2">
                  <Col xs={"auto"}>
                    <PRButton className="me-2" color="success" icon={MdAdd} onClick={handleReservationAddUpdate}>
                      Add Reservation
                    </PRButton>
                  </Col>
                </Row>
                <PRTable
                  ref={tableDetailRef}
                  inline
                  {...(selectedResourceId &&
                    selectedTableSlot && {
                      url: apiUrlOrganization.getResourceDetailed.format(
                        currentProject.id,
                        selectedResourceId,
                        selectedTableSlot
                      ),
                    })}
                  columns={columnsDetail}
                  responseDataCountKey={"total"}
                  responseDataKey={"reservation"}
                />
              </PRAccordionItem>
            </PRAccordion> */}
          </div>
        )}
      </PRPage>
    </PRContainer>
  );
}
