import { forwardRef, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { BiLoaderCircle } from "react-icons/bi";
import { MdBrokenImage } from "react-icons/md";

import TransparentBackground from "~assets/images/webchat/transparent-background.png";
import Network from "~helpers/Network";
import Utils from "~helpers/Utils";
import "./style.scss";

const blankSvg = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'/%3E";
const brokenImageSvg =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23cccccc' width='800px' height='800px' viewBox='0 0 24 24'%3E%3Cpath d='M21.71,14.54,19.21,12a1,1,0,0,0-1.42,0L15,14.84,12.21,12a1,1,0,0,0-1.42,0L8.5,14.34,6.21,12a1,1,0,0,0-1.42,0l-2.5,2.5a1,1,0,0,0-.21.33,1,1,0,0,0-.08.38V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V15.25a1,1,0,0,0-.08-.38A1,1,0,0,0,21.71,14.54ZM20,19a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V15.66l1.5-1.5,2.29,2.3a1,1,0,0,0,1.42,0l2.29-2.3L14.29,17a1,1,0,0,0,1.42,0l2.79-2.8,1.5,1.5ZM19,2H5A3,3,0,0,0,2,5v5.26a1.17,1.17,0,0,0,0,.27s0,.07,0,.1a1,1,0,0,0,1.66.31L5.5,9.16l2.29,2.3a1,1,0,0,0,1.42,0l2.29-2.3L14.29,12a1,1,0,0,0,1.42,0l2.79-2.8,1.77,1.78a1,1,0,0,0,1.66-.31.28.28,0,0,0,0-.09.88.88,0,0,0,.06-.28V5A3,3,0,0,0,19,2Zm1,5.84L19.21,7a1,1,0,0,0-1.42,0L15,9.84,12.21,7a1,1,0,0,0-1.42,0L8.5,9.34,6.21,7A1,1,0,0,0,4.79,7L4,7.84V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z'/%3E%3C/svg%3E";

const imgCache = {};

export default forwardRef(function PRImg(
  {
    // lazy,
    blankPlaceholder,
    className,
    src: srcProp,
    textStyle,
    noPlaceholder,
    showBrokenPlaceholder,
    onBroken,
    showTransparentBackground,
    // authenticated,
    onLoad,
    onClick,
    noCache,
    ...props
  },
  ref
) {
  const [isBroken, setIsBroken] = useState(false);
  const [loadedSrc, setLoadedSrc] = useState("");

  const brokenPlaceholderImg = useMemo(() => {
    if (noPlaceholder) return null;
    return brokenImageSvg;
  }, [noPlaceholder]);

  const isBlobUrl = useMemo(() => {
    return srcProp?.startsWith("blob:");
  }, [srcProp]);

  const isBase64 = useMemo(() => {
    return srcProp?.startsWith("data:");
  }, [srcProp]);

  const { src } = useMemo(() => {
    return {
      src: Utils.normalizeUrl(srcProp),
    };
  }, [srcProp]);

  const cacheBlob = useMemo(() => {
    return imgCache[src];
  }, [src]);

  // useEffect(() => {
  //   if (!lazy) return;
  //   const observer = lozad(".pr-img-lozad", {
  //     loaded: function (el) {
  //       el.classList.add("pr-img-lozad-loaded");
  //     },
  //   });
  //   observer.observe();
  // }, [lazy]);

  useEffect(() => {
    setIsBroken(false);
  }, [props.src]);

  const handleImageError = (e) => {
    setIsBroken(true);
  };

  useEffect(() => {
    if (isBroken) {
      onBroken?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBroken]);

  useEffect(() => {
    if (!isBase64 && !isBlobUrl && (!cacheBlob || noCache)) {
      setIsBroken(false);
      setLoadedSrc(null);
      let proxyUrl = src;
      if (process.env.NODE_ENV === "development") {
        //To bypass CORS
        const excludeList = ["https://www.google.com/maps", "https://maps.googleapis.com/maps/api/staticmap"];
        if (!excludeList.some((url) => src?.startsWith(url))) {
          const origin = window.location.origin;
          proxyUrl = `${origin}/proxy/${encodeURIComponent(src)}`;
        }
      }
      Network.request(proxyUrl, {
        responseType: "blob",
        rawResponse: true,
        noAlert: true,
      })
        .then((response) => {
          const objectUrl = URL.createObjectURL(response.data);
          setLoadedSrc(objectUrl);
          imgCache[src] = objectUrl;
        })
        .catch(() => setIsBroken(true));
    }
  }, [isBase64, src, isBlobUrl, cacheBlob, noCache]);

  if (isBroken && showBrokenPlaceholder) {
    return (
      <span className="pr-img-broken-placeholder d-flex align-items-center justify-content-center flex-column py-2">
        <MdBrokenImage
          className="text-dark align-items-center d-flex"
          style={{
            fontSize: "2rem",
            height: "2rem",
          }}
        />
        <div className="font-size-10 my-1 " style={textStyle}>
          Not found
        </div>
      </span>
    );
  }
  const handleOnLoad = () => {
    onLoad?.(blankPlaceholder ? loadedSrc || src || blankSvg : loadedSrc || src);
  };
  if (!isBase64 && !cacheBlob && !loadedSrc && !isBroken && !isBlobUrl) {
    return (
      <span className="d-flex align-items-center justify-content-center flex-column py-2 w-100">
        <BiLoaderCircle className="spin align-items-center d-flex text-muted" />
      </span>
    );
  }

  const imageItem = !isBlobUrl && isBroken ? brokenPlaceholderImg : (!noCache && cacheBlob) || loadedSrc || src;

  const handleOnClick = (e) => {
    if (onClick) {
      const imageStatus = {
        broken: isBroken,
        loaded: !!loadedSrc,
        image: imageItem,
      };
      onClick(e, imageStatus);
    }
  };

  return (
    <img
      {...props}
      ref={ref}
      style={{
        ...(showTransparentBackground && { backgroundImage: `url(${TransparentBackground})` }),
        ...props.style,
      }}
      // {...(!lazy
      //   ? {
      src={imageItem}
      //   }
      // : {
      //     ...(blankPlaceholder
      //       ? {
      //           "data-src": dataSrc || blankSvg,
      //           src: blankSvg,
      //         }
      //       : {
      //           "data-src": dataSrc,
      //         }),
      //   })}
      className={classNames("pr-img", className, {
        // "pr-img-lozad": lazy,
        "broken-image": isBroken,
      })}
      onClick={handleOnClick}
      onError={handleImageError}
      onLoad={handleOnLoad}
    />
  );
});
