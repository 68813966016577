import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import styled from "@emotion/styled";

import { ReactComponent as BrokenFileSvg } from "~assets/images/webchat/broken-file.svg";
import ImagePreviewModal from "~common/modals/ImagePreviewModal";
import PRImg from "~components/Generic/PRImg";
import IFrameHelper from "~helpers/IFrameHelper";
import store from "~store";
import {
  selectDisplayMode,
  selectOnlineStatus,
  selectQueryOptions,
  selectWelcomeOptions,
} from "~store/socket/chatbot/selectors";

import { usePRRefContext } from "../../context";
import SimpleMessage from "../SimpleMessage";

import "./style.scss";

const StyledImageContainer = styled.div`
  width: 100%;
  max-width: 500px;
  height: auto;
  min-height: 50px;
  max-height: 70vh;

  display: flex;
  align-items: center;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 0px #0000000a;
  border: 1px solid #e8e8e8;
  border-radius: 14px 14px 14px 0px;
  opacity: 1;
  overflow: hidden;
  user-select: none;
  cursor: ${(props) => (props.isBroken ? "default" : "pointer")};
  img {
    height: auto;
    //stretch width
    width: 100%;
    //align center image
    object-fit: contain;
  }
  .pr-img-broken-placeholder {
    width: 100%;
  }

  color: ${(props) => (props.position === "right" ? props.theme.palette.primary.contrastText : "inherit")};
`;

const BrokenDiv = styled.div`
  text-align: left;
  .broken-container {
    width: 50px;
    height: 58px;
    padding: 8px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 6px;
  }
  svg {
    width: auto;
    height: 42px;
    transform: scale(1.2);
  }
  .broken-text {
    margin-top: 15px;
    height: 22px;
    font: normal normal normal 18px/20px Museo Sans;
    letter-spacing: 0px;
    color: #000000b3;
    color: ${(props) => (props.position === "right" ? props.theme.palette.primary.contrastText : "inherit")};
  }
`;

export default function ImageMessage({
  plainView,
  format,
  imageUrl,
  position,
  messageTime,
  isSent,
  messageId,
  likeStatus,
  historyId,
  isLast,
  className,
}) {
  const [isBroken, setIsBroken] = useState(false);
  const { t } = useTranslation();
  const distributedRef = usePRRefContext();
  const tempImgWidth = useRef(0);
  const imgRef = useRef(null);

  const displayMode = useSelector(selectDisplayMode);
  const onlineStatus = useSelector(selectOnlineStatus);
  const welcomeOptions = useSelector(selectWelcomeOptions);

  useEffect(() => {
    if (onlineStatus === "closed") {
      ImagePreviewModal.hide();
    }
  }, [onlineStatus]);

  useEffect(() => {
    setIsBroken(false);
  }, [imageUrl]);

  const handleClickImagePreview = async () => {
    distributedRef.current.disableScrollOnMessage = true;
    tempImgWidth.current = imgRef.current.offsetWidth;
    const webchatDom = document.getElementById("webchat");
    if (IFrameHelper.isInIFrame()) {
      await IFrameHelper.setPopupSizeMode("wide", null, displayMode, welcomeOptions?.popup_style_info?.popup_position);
      webchatDom.classList.add("webchat-image-view");

      if (displayMode === "desktop") {
        webchatDom.classList.add("webchat-image-view-desktop");
      }
    }

    // Update iframe's class list by image preview status
    const queryOptions = selectQueryOptions(store.getState());
    const currentClassStr = (await IFrameHelper.classListAsync("toString", [], queryOptions.previewMode)) || "";
    const currentClassList = typeof currentClassStr === "string" ? currentClassStr.split(" ").filter(Boolean) : "";

    const classPrefix = "PalChatbot";
    const actionPreviewClassList = [`${classPrefix}--image-preview`];
    const existingClassMode = actionPreviewClassList.find((c) => currentClassList.includes(c));

    if (!existingClassMode) {
      await IFrameHelper.classListAsync("add", actionPreviewClassList, queryOptions.previewMode);
    }

    // Show image asynchronously until close button is clicked
    await ImagePreviewModal.show({ image: imageUrl });

    await IFrameHelper.classListAsync("remove", actionPreviewClassList, queryOptions.previewMode);

    if (IFrameHelper.isInIFrame()) {
      await IFrameHelper.setPopupSizeMode("chat", null, displayMode, welcomeOptions?.popup_style_info?.popup_position);
      webchatDom.classList.remove("webchat-image-view");

      if (displayMode === "desktop") {
        webchatDom.classList.remove("webchat-image-view-desktop");
      }
    }

    setTimeout(() => {
      distributedRef.current.disableScrollOnMessage = false;
    }, 100);
  };

  const handleBroken = () => {
    setIsBroken(true);
  };
  const Wrapper = plainView ? ({ children }) => children : SimpleMessage;

  return (
    <Wrapper
      format={format}
      historyId={historyId}
      isLast={isLast}
      isSent={isSent}
      likeStatus={likeStatus}
      messageId={messageId}
      messageTime={messageTime}
      position={position}
    >
      {!isBroken && (
        <StyledImageContainer className={className} isBroken={isBroken} position={position}>
          <PRImg
            ref={imgRef}
            showBrokenPlaceholder
            showTransparentBackground
            src={imageUrl}
            onBroken={handleBroken}
            onClick={handleClickImagePreview}
          />
        </StyledImageContainer>
      )}
      {isBroken && (
        <BrokenDiv className={className} position={position}>
          <div className="broken-container">
            <BrokenFileSvg />
          </div>

          <div className="broken-text">{t("chatbot.not-found")}</div>
        </BrokenDiv>
      )}
    </Wrapper>
  );
}
