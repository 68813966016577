import { memo, useCallback, useEffect, useMemo, useState } from "react";

import chroma from "chroma-js";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";

import styled from "@emotion/styled";
import { Box, Popover, Zoom } from "@mui/material";

import useQueryParams from "~common/hooks/useQueryParams";
import PRTooltip from "~components/Generic/PRTooltip";
import PalIconButton from "~components/mui/PalIconButton";
import PalTooltip from "~components/mui/PalTooltip";
import { chatbotLikeDislikeType } from "~constants";
import DateHelper from "~helpers/DateHelper";
import StyledHelper from "~helpers/StyledHelper";
import { wsOut } from "~store/socket/chatbot/actions";

import EmoteWheel from "./EmoteItem";
import { renderText, urlify } from "../../ChatUtils";
import { usePRChatOptionsContext, usePRMessagesContext, usePRRefContext } from "../../context";

import "./style.scss";

// function SendStatusWrapper({ children, isSent, position }) {
//   const [sendStatus, setSendStatus] = useState(isSent);

//   useDebouncedEffect(
//     () => {
//       setSendStatus(isSent);
//     },
//     [isSent],
//     250
//   );

//   let primaryColor;

//   //check if bright or dark
//   const lum = chroma(theme.colorPrimary).luminance();
//   if (lum > 0.25) {
//     primaryColor = chroma(theme.colorPrimary).brighten(1.0).hex();
//   } else {
//     primaryColor = chroma(theme.colorPrimary).brighten(2.0).hex();
//   }
//   return (
//     <div className="position-relative col">
//       {position === "right" && (
//         <span
//           className={classNames("position-absolute bottom-0", {
//             "end-0": position === "right",
//             "start-0": position === "left",
//           })}
//           style={{
//             marginBottom: "0.1rem",
//             zIndex: 10 + 1,
//             color: "var(--bs-gray-600)",
//             ...(position === "right"
//               ? {
//                   marginRight: "0.25rem",
//                   color: primaryColor,
//                 }
//               : { marginLeft: "0.25rem" }),
//           }}
//         >
//           {sendStatus ? <FaCheck className="fs-6 " /> : <BiLoaderAlt className="fs-5 spin" />}
//         </span>
//       )}
//       {children}
//     </div>
//   );
// }

const ReactionDiv = styled("div", { shouldForwardProp: (prop) => prop !== "wide" })`
  overflow: hidden;
  width: 36px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 0px #0000000a;
  border: 1px solid #e8e8e8;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: calc(-36px + 10px);
  left: 25px;
  ${(props) => (props.wide ? "width: 72px; left: 12.5px;" : "")}
  transition: all 0.2s ease-in-out;
`;

const EmoteButton = styled(PalIconButton, StyledHelper.sfw(["noClickable"]))`
  &.MuiIconButton-root.MuiIconButton-sizeMedium {
    width: 36px;
    height: 36px;
    padding: 0;
    color: #000000;
  }

  ${(props) => (props.noClickable ? "pointer-events: none;" : "")}
`;
function MessageLikeDislikeBase({
  clickable,
  internalLikeStatus,
  setInternalLikeStatus,
  messageId,
  position,
  likeStatus,
  onClick,
  reverseChat,
}) {
  const { hideLikeDislike, readonlyLikeDislike } = usePRChatOptionsContext();

  const { t } = useTranslation();

  useEffect(() => {
    const likeStatusNumber = Number(likeStatus);
    if (likeStatusNumber > -1) {
      // setInternalLikeStatus(likeStatus ? Number(likeStatus) : chatbotLikeDislikeType.unknown);
      setInternalLikeStatus(likeStatusNumber);
    }
  }, [likeStatus, setInternalLikeStatus]);

  if (!messageId || position === (reverseChat ? "left" : "right") || hideLikeDislike) return null;

  const isLiked = internalLikeStatus === chatbotLikeDislikeType.like;
  const isDisliked = internalLikeStatus === chatbotLikeDislikeType.dislike;
  const isLikedOrDisliked = isLiked || isDisliked;

  const showLike = (!readonlyLikeDislike && !isLikedOrDisliked && clickable) || isLiked;
  const showDislike = (!readonlyLikeDislike && !isLikedOrDisliked && clickable) || isDisliked;
  const handleClick = (key) => () => {
    const isRemove = isLikedOrDisliked;
    onClick?.(isRemove ? chatbotLikeDislikeType.initial : key);
  };

  if (!isLikedOrDisliked && (!clickable || readonlyLikeDislike)) return null;
  return (
    <ReactionDiv style={{ zIndex: 12 }} wide={!isLikedOrDisliked && clickable}>
      <Zoom direction="right" in={!!showLike}>
        <span>
          <PRTooltip delay={1000} title={t("chatbot.like")}>
            <Box
              sx={{
                ...(isLikedOrDisliked && showLike && { marginLeft: "36px" }),
              }}
            >
              <EmoteButton
                disableRipple
                noClickable={readonlyLikeDislike}
                onClick={handleClick(chatbotLikeDislikeType.like)}
              >
                👍
              </EmoteButton>
            </Box>
          </PRTooltip>
        </span>
      </Zoom>
      <Zoom direction="left" in={!!showDislike}>
        <span>
          <PRTooltip delay={1000} title={t("chatbot.dislike")}>
            <Box
              sx={{
                ...(isLikedOrDisliked && showDislike && { marginLeft: "-36px" }),
              }}
            >
              <EmoteButton
                disableRipple
                noClickable={readonlyLikeDislike}
                onClick={handleClick(chatbotLikeDislikeType.dislike)}
              >
                👎
              </EmoteButton>
            </Box>
          </PRTooltip>
        </span>
      </Zoom>
    </ReactionDiv>
  );
}
const MessageLikeDislike = memo(MessageLikeDislikeBase);
const StyledBalloonDiv = styled("div")`
  display: flex;
  align-items: center;
  min-width: 70px;
  min-height: 20px;
  font-size: 18px;
  line-height: 20px;

  ${(props) => props.extraBottomSpace && "margin-bottom: 20px;"}

  position: relative;
  padding: 15px 20px 25px;
  z-index: 10;
  display: flex;
  justify-content: center;
  border-radius: 14px;
  border-bottom-left-radius: 0px;
  &.balloon-right {
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 0px;
  }
  &.balloon-center {
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }
  @keyframes highlightAnimation {
    0% {
      box-shadow: 0 0 4px 2px rgba(255, 0, 0, 0.2);
    }
    25% {
      box-shadow: 0 0 4px 2px rgba(0, 255, 0, 0.2);
    }
    50% {
      box-shadow: 0 0 4px 2px rgba(0, 0, 255, 0.2);
    }
    75% {
      box-shadow: 0 0 4px 2px rgba(255, 255, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 4px 2px rgba(255, 0, 0, 0.2);
    }
  }

  &.balloon--no-background {
    background: transparent;
  }
  &.balloon--highlight {
    animation: highlightAnimation 10s ease-in-out infinite;
  }
  box-shadow: 2px 2px 0px #0000000a;
  border: 1px solid #e8e8e8;

  .balloon__time {
    position: absolute;
    bottom: 5px;
    right: 10px;
    height: 15px;
    font: normal normal normal 13px/15px Museo Sans;
    letter-spacing: 0px;
    color: #00000066;
  }

  ${(props) => {
    if (props.position === "right") {
      let primary = props.theme.palette.primary.main;
      let light = props.theme.palette.primary.light;
      const contrastDifference = chroma.contrast(props.theme.palette.primary.main, "#fff");
      const leftBallonColorContrastDifference = chroma.contrast("#f8f8f8", "#fff");

      if (leftBallonColorContrastDifference > contrastDifference) {
        // If color brightness is too much, use left balloon color instead of theme color
        primary = "#f8f8f8";
        light = chroma(primary).brighten(1.5).hex();
      }

      return `
        border: 1px solid ${light};
        background-color: ${primary};
        color: ${props.theme.palette.primary.contrastText};
    `;
    } else {
      return `
        color: rgba(0, 0, 0, 0.7);
        background-color: #f8f8f8;      
      `;
    }
  }}
  .markdown-wrapper {
    color: ${(props) => (props.position === "right" ? props.theme.palette.primary.contrastText : "inherit")};
  }
  .text {
    a {
      text-decoration: underline !important;
      color: ${(props) => {
        if (props.position !== "right") {
          let linkColor = props.theme.palette.primary.main;
          const defaultLeftBackgroundColor = "#f8f8f8";
          const primaryColorChroma = props.theme.palette.primary.main;
          const contrastDifference = chroma.contrast(primaryColorChroma, defaultLeftBackgroundColor);
          if (4.5 > contrastDifference) {
            linkColor = chroma(primaryColorChroma)
              .darken(4.5 - contrastDifference)
              .hex();
          }
          return linkColor;
        } else {
          return props.theme.palette.primary.contrastText;
        }
      }};
    }
  }
`;

function useHasMessageOnRightSide() {
  const messages = usePRMessagesContext();
  const [hasAnyMessage, setHasAnyMessage] = useState(false);

  useEffect(() => {
    if (messages?.length) {
      const hasAnyCustomerMsg = messages?.find((msg) => msg?.position === "right");
      if (!!hasAnyCustomerMsg !== hasAnyMessage) {
        setHasAnyMessage(!!hasAnyCustomerMsg);
      }
    }
  }, [messages, hasAnyMessage]);
  return hasAnyMessage;
}

export default function SimpleMessage({
  text,
  children,
  position,
  className,
  onClick,
  messageTime,
  background = true,
  footerComponent,
  style,
  format,
  isSent,
  messageId,
  likeStatus,
  historyId,
  isLast,
  reverseChat,
}) {
  const { messageId: selectedMessageId } = useQueryParams();

  const dispatch = useDispatch();
  const distributedRef = usePRRefContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [internalLikeStatus, setInternalLikeStatus] = useState();
  const [isHovered, setIsHovered] = useState(false);
  const [isEmoteVisible, setIsEmoteVisible] = useState(false);
  const hasMessageOnRightSide = useHasMessageOnRightSide();

  const { disableTextInput, disableDislike, disableLike } = usePRChatOptionsContext();
  const handleEnterLeave = (status) => () => {
    setIsHovered(status);
  };

  const handleClickEmote = (e) => {
    setIsEmoteVisible(!isEmoteVisible);
    setAnchorEl(e.currentTarget);
  };

  // format === "plain" ? urlify(text, position === "right" && Utils.determineTextColor(theme.colorPrimary)) : text;
  const content = format === "plain" ? urlify(text) : text;

  // const rightBalloonBackground = theme.palette.primary.light && chroma(theme.palette.primary.main).brighten(1.5).hex();

  const isReactionDisabled =
    // likeStatus === chatbotLikeDislikeType.like ||
    // likeStatus === chatbotLikeDislikeType.dislike ||
    disableTextInput || likeStatus === chatbotLikeDislikeType.unknown;
  const isExtraMarginForBottomLike = hasMessageOnRightSide && isLast && (messageId || historyId);
  const isEmoteWheelVisible =
    position === "left" &&
    !disableTextInput &&
    !(isLast && hasMessageOnRightSide) &&
    likeStatus !== chatbotLikeDislikeType.like &&
    likeStatus !== chatbotLikeDislikeType.dislike;

  useEffect(() => {
    if (!isHovered) {
      setIsEmoteVisible(false);
    }
  }, [isHovered]);

  const handleClickLikeDislike = useCallback(
    (likeStatus) => () => {
      distributedRef.current.disableScrollOnMessage = true;

      setInternalLikeStatus(likeStatus);
      setIsEmoteVisible(false);
      setAnchorEl(null);
      dispatch(wsOut.action.submitMessageFeedback(historyId || messageId, likeStatus));

      setTimeout(
        () => {
          distributedRef.current.disableScrollOnMessage = false;
        },
        //Animation duration
        250
      );
    },
    [messageId, historyId, dispatch, distributedRef]
  );
  const handleClickFooterLikeDislike = useCallback(
    (likeStatus) => {
      handleClickLikeDislike(likeStatus)();
    },
    [handleClickLikeDislike]
  );

  const handleClose = () => {
    // setIsEmoteVisible(false);
    setAnchorEl(null);
  };
  const hourInfo = useMemo(() => DateHelper.getDateTimeLocal(messageTime).format("LT"), [messageTime]);

  const isLikeDislikeComponentVisible = !disableLike || !disableDislike;
  const isLikedOrDisliked =
    internalLikeStatus === chatbotLikeDislikeType.like || internalLikeStatus === chatbotLikeDislikeType.dislike;
  // const isLikeDislikeAlwaysVisible = isLast && !isReactionDisabled;

  const enableExtraMarginForLastMessage = (isExtraMarginForBottomLike || isLikedOrDisliked) && position === "left";
  return (
    <div className="position-relative" onMouseEnter={handleEnterLeave(true)} onMouseLeave={handleEnterLeave(false)}>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        elevation={0}
        open={!!anchorEl}
        slotProps={{
          paper: {
            style: {
              backgroundColor: "transparent",
              border: "none",
              borderRadius: "24px",
              // overflow: "visible",
              overflow: "hidden",
            },
          },
        }}
        transformOrigin={{ vertical: 102, horizontal: "center" }}
        onClose={handleClose}
      >
        <Box
          sx={{
            display: "flex",
            background: "#F8F8F8 0% 0% no-repeat padding-box",
            boxShadow: "2px 2px 0px #0000000A",
            border: "1px solid #E8E8E8",
            borderRadius: 24,
            // position: "absolute",
            // top: -54,
            // right: 0,
            // height: 47,
            alignItems: "center",
            justifyContent: "center",

            gap: "3px",
            padding: "3px 3px",
          }}
        >
          <PalIconButton
            className="d-flex align-items-center justify-content-center"
            color="secondary"
            sx={{
              background: "#f8f8f8",
              "&:hover": {
                backgroundColor: "#FFFFFF",
                border: "1px solid #E8E8E8",
              },
              borderRadius: "100%",
              boxSize: "border-box",
              fontSize: 26,
              lineHeight: 20,
              "&.MuiIconButton-sizeMedium": {
                width: 40,
                height: 40,
              },
            }}
            onClick={handleClickLikeDislike(chatbotLikeDislikeType.like)}
          >
            👍
          </PalIconButton>
          <PalIconButton
            className="d-flex align-items-center justify-content-center"
            color="secondary"
            sx={{
              background: "#f8f8f8",
              "&:hover": {
                backgroundColor: "#FFFFFF",
                border: "1px solid #E8E8E8",
              },
              borderRadius: "100%",
              boxSize: "border-box",
              fontSize: 26,
              lineHeight: 20,
              "&.MuiIconButton-sizeMedium": {
                width: 40,
                height: 40,
              },
            }}
            onClick={handleClickLikeDislike(chatbotLikeDislikeType.dislike)}
          >
            👎
          </PalIconButton>
        </Box>
      </Popover>
      <Row
        className={classNames("pr-simple-message", "flex-nowrap", "g-0", `pr-simple-message-${format}`)}
        style={{
          display: "flex",
          flexDirection: position === "left" ? "row" : "row-reverse",
          alignItems: "center",
          columnGap: "5px",
          ...style,
        }}
      >
        <Col
          xs
          className={classNames({
            "d-flex": true,
            "flex-column": true,
            "align-items-start": position === "left",
            "align-items-end": position === "right",
          })}
        >
          {/* <SendStatusWrapper isSent={isSent} position={position}> */}
          <StyledBalloonDiv
            className={classNames(
              // `balloon-${format}`,
              {
                "balloon-right": position === "right",
                "balloon-center": position === "center",
                "balloon--no-background": !background,
                "balloon--highlight": messageId && Number(selectedMessageId) === Number(messageId),
              },
              className
            )}
            extraBottomSpace={enableExtraMarginForLastMessage}
            // extraBottomSpace={likeStatus === chatbotLikeDislikeType.like || likeStatus === chatbotLikeDislikeType.dislike || isLast}
            position={position}
            onClick={onClick}
          >
            {!!text && (
              <div
                className={classNames("title markdown-wrapper", {
                  text: format === "plain",
                })}
              >
                {renderText(content, format, position === "right")}
              </div>
            )}
            {children}
            <PalTooltip title={DateHelper.getDateTimeLocal(messageTime).format("LLT")}>
              <div className="balloon__time">{hourInfo}</div>
            </PalTooltip>

            {isLikeDislikeComponentVisible && (
              <MessageLikeDislike
                clickable={isExtraMarginForBottomLike}
                internalLikeStatus={internalLikeStatus}
                isSent={isSent}
                likeStatus={likeStatus}
                messageId={messageId}
                position={position}
                reverseChat={reverseChat}
                setInternalLikeStatus={setInternalLikeStatus}
                onClick={handleClickFooterLikeDislike}
              />
            )}
          </StyledBalloonDiv>
          {/* </SendStatusWrapper> */}
          {footerComponent}
        </Col>
        <Col className="overflow-hidden" xs="auto">
          <Box visibility={isEmoteWheelVisible && isLikeDislikeComponentVisible ? "visible" : "hidden"}>
            <EmoteWheel
              dateTime={messageTime}
              disabled={isReactionDisabled || !messageId}
              position={position}
              setAnchorEl={setAnchorEl}
              visible={isHovered}
              onToggleEmote={handleClickEmote}
            />
          </Box>
        </Col>
      </Row>
    </div>
  );
}
