import { createRef, useCallback, useEffect, useMemo } from "react";

import { MdAdd, MdCode, MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "reactstrap";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import LowCodeEditorModal from "~components/LowCodeEditor";
import { popupSettingsMapper, lowCodeModuleType, apiUrlReservationGenerator } from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import { getResources } from "~store/organization/actions";
import { selectResources } from "~store/organization/selectors";
import { deleteReservationGenerator } from "~store/reservationGenerator/actions";
import { selectCurrentProject } from "~store/user/selectors";

export default function ReservationGeneratorList() {
  const dispatch = useDispatch();
  const tableRef = createRef();

  const resources = useSelector(selectResources);

  const currentProject = useSelector(selectCurrentProject);

  useEffect(() => {
    dispatch(getResources(currentProject.id));
  }, [dispatch, currentProject.id]);

  const columns = useMemo(() => {
    const handleClickDelete = (item) => async () => {
      if (!(await DialogHelper.showQuestionYesNo("Delete", "Are you sure you want to delete this project?"))) return;
      await dispatch(deleteReservationGenerator(currentProject.id, item.id));

      tableRef.current?.refresh();
    };

    const handleClickEditGenerator = (item) => async () => {
      await LowCodeEditorModal.show({
        moduleType: lowCodeModuleType.RESERVATION_MODULES,
        reservationActionGeneratorId: item.id,
        hideContext: true,
        defaultSelectedProcedureName: "main",
      });
      tableRef.current?.refresh();
    };
    return [
      {
        label: "Name",
        key: "name",
      },
      {
        label: "Resource",
        key: "resource",
        render: (row) => resources.find((item) => item.id === row.resource)?.name,
      },
      {
        label: "Modules",
        key: "modules",
        render: (row) => {
          return (
            <>
              {row?.modules?.map((item) => {
                return (
                  <Badge key={item?.id} className="badge-soft-secondary me-1">
                    {item?.name}
                  </Badge>
                );
              })}
            </>
          );
        },
      },
      {
        label: "Actions",
        key: "actions",
        actions: true,
        render: (row) => (
          <div className="d-flex justify-content-center gap-1">
            <PRButton
              outline
              color="primary"
              icon={MdCode}
              size="sm"
              tooltipText="Open LowCode Editor"
              onClick={handleClickEditGenerator(row)}
            />
            <PRButton
              outline
              color="primary"
              icon={MdEdit}
              link={`/organization/reservation-generator/form/${row.id}`}
              size="sm"
              tooltipText="Edit Generator"
            />
            <PRButton
              outline
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText="Delete"
              onClick={handleClickDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [dispatch, currentProject?.id, tableRef, resources]);

  const actions = useMemo(
    () => [
      {
        label: "Create",
        icon: MdAdd,
        color: "success",
        link: "/organization/reservation-generator/form",
      },
    ],
    []
  );
  const parentName = [
    {
      label: `Reservation Generator`,
    },
  ];
  const handleOnLoad = useCallback((results) => {
    return results.map((item) => {
      return {
        ...item,
        settings: {
          ...item.settings,
          ...popupSettingsMapper(item.settings),
        },
      };
    });
  }, []);
  return (
    <PRContainer
      actions={actions}
      description={"Here you can create and manage your projects. You can also create and manage your chatbots."}
      name="Reservations"
      parentName={parentName}
    >
      <div className="mt-2">
        <PRTable
          ref={tableRef}
          columns={columns}
          url={apiUrlReservationGenerator.get.format(currentProject?.id)}
          onLoad={handleOnLoad}
        />
      </div>
    </PRContainer>
  );
}
