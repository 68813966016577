import PRSwitch from "~components/Generic/PRSwitch";

import ReservationGeneratorEdit from "./ReservationGeneratorEdit";
import ReservationGeneratorList from "./ReservationGeneratorList";

function ReservationGenerator() {
  const routes = [
    { path: "/organization/reservation-generator/form/:id?", component: ReservationGeneratorEdit },
    { path: "/organization/reservation-generator/", component: ReservationGeneratorList },
    { path: "/organization/reservation-generator/", to: "/organization/reservation-generator", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}

export default ReservationGenerator; //
