import React, { memo, useEffect } from "react";

import classNames from "classnames";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

import styled from "@emotion/styled";

import useProjectLoad, { projectLoadStatus } from "~common/hooks/useProjectLoad";
import AuthorizedRedirect from "~components/AuthorizedRedirect";
import { GuideDrawer } from "~components/GuideDrawer";
import { selectVisibleAppAnnouncementList } from "~store/announcement/selectors";

import Header from "./Header";
import HeaderAnnouncement from "./HeaderAnnouncement";
import Sidebar from "./Sidebar";

const headerHeight = 100; // $header-height: 100px;
const headerHeightSm = 54; //  $header-height-sm: 54px;

const StyledDiv = styled.div`
  .sidebar {
    height: ${(props) => (props.appAnnouncementMode ? `calc(100vh - 52px);` : `100vh;`)};
    z-index: 1200;
  }
  .vertical-main {
    overflow: hidden;
    // overflow-y: auto;
    height: calc(100 * var(--vh));
    background-color: #fafafa;
    #main-scroll {
      padding-bottom: 20px;

      overflow: hidden;
      overflow-y: auto;

      margin-right: 20px;
      height: calc(var(--vh) * 100 - ${headerHeight}px); // total - header

      @media (max-width: 991.98px) {
        margin-left: 20px;
      }
      @media (max-width: 768px) {
        margin-left: 10px;
        margin-right: 10px;
      }
      @media (max-width: 576px) {
        height: calc(var(--vh) * 100 - ${headerHeightSm}px); // total - header
      }

      &.app-announcement-mode {
        height: calc(var(--vh) * 100 - ${headerHeight}px - 52px); // total - header - announcement
        @media (max-width: 576px) {
          height: calc(var(--vh) * 100 - ${headerHeightSm}px - 52px); // total - header - announcement
        }
      }

      .content {
        position: relative;
        height: auto;

        background-color: #fff;
        border-radius: 26px;
        border: 1px solid #ddd;

        min-height: calc(var(--vh) * 100 - ${headerHeight}px - 30px); // total - header - footer
        @media (max-width: 576px) {
          min-height: calc(var(--vh) * 100 - ${headerHeightSm}px - 30px); // total - header - footer
        }

        &.app-announcement-mode {
          min-height: calc(var(--vh) * 100 - ${headerHeight}px - 30px - 52px); // total - header - footer - announcement
          @media (max-width: 576px) {
            // total - header - footer - announcement
            min-height: calc(var(--vh) * 100 - ${headerHeightSm}px - 30px - 52px);
          }
        }
      }
    }
  }
  footer {
    // margin-top: auto;
    // flex: 0 0 60px;
    // position: absolute;
    // bottom: 0;
    // margin-top: auto;
  }
`;
const Layout = memo(function Layout({ children }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const visibleAppAnnouncementList = useSelector(selectVisibleAppAnnouncementList);

  return (
    <StyledDiv appAnnouncementMode={visibleAppAnnouncementList?.length}>
      <HeaderAnnouncement />
      <Row className="g-0 flex-nowrap">
        <Col className="sidebar" xs={"auto"}>
          <Sidebar />
        </Col>
        <Col xs className="vertical-main">
          <Header />
          <div className={classNames({ "app-announcement-mode": visibleAppAnnouncementList?.length })} id="main-scroll">
            <div
              className={classNames("content", {
                "app-announcement-mode": visibleAppAnnouncementList?.length,
              })}
              id="main-content"
            >
              <GuideDrawer />
              {children}
            </div>
            {/* <Footer /> */}
          </div>
        </Col>
      </Row>
    </StyledDiv>
  );
});

const VerticalLayout = memo(function VerticalLayout({ isProtected, ...rest }) {
  const loadStatus = useProjectLoad();
  if (loadStatus === projectLoadStatus.loading) {
    return null;
  } else if (loadStatus === projectLoadStatus.redirect) {
    return <AuthorizedRedirect />;
  }
  if (!isProtected) {
    return rest.children;
  }
  return <Layout {...rest} />;
});
export default VerticalLayout;
