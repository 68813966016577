import chroma from "chroma-js";
import { detect } from "detect-browser";
import i18next from "i18next";
import {
  actionChannel,
  all,
  call,
  debounce,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import ChatTimeoutCountdownModal from "~components/WebChat/Body/ChatTimeoutCountdownModal";
import {
  LS_WEBCHAT_BACKUP_TOKEN,
  LS_WEBCHAT_OPTIONS,
  LS_WEBCHAT_TOKEN,
  LS_WEBCHAT_TOKEN_EXPIRE,
  WEBCHAT_REDIRECT_KEY,
  WEBCHAT_REDIRECT_VALUE,
  audioList,
  chatbotNoMessageWarningDelayMs,
  chatbotNoMessageWarningTryCount,
  chatbotPingPongIntervalMs,
  getChatbotPingPongMaxTimeoutMs,
} from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import AudioHelper from "~helpers/AudioHelper";
import DateHelper from "~helpers/DateHelper";
import IFrameHelper from "~helpers/IFrameHelper";
import StorageHelper from "~helpers/StorageHelper";
import TokenHelper from "~helpers/TokenHelper";
import Utils from "~helpers/Utils";
import store from "~store";
import { setWebchatTheme } from "~store/actions";
import { selectMuiThemeWebchat } from "~store/theme/selectors";

import {
  disconnectRequest,
  getTokenAndStartChatSession,
  resumeChatSession,
  setInteractionModal,
  setInteractionStatus,
  setMessageList,
  setMinimizedStatus,
  setOnlineStatus,
  setSeenMessageInfo,
  setSessionStatus,
  setSocketInstance,
  setViewMode,
  setViewModeDelayed,
  setWelcomeMsgShowedStatus,
  startChatSession,
  wsActionChannelReceived,
  wsActionChannelSent,
  wsConnect,
  wsConnectFail,
  wsConnectSuccess,
  wsOut,
} from "./actions";
import * as at from "./actionTypes";
import broadcastActionsHandler from "./broadcastActions";
import { chatbotBroadcastSync } from "./broadcastActions/broadcast";
import domHandler from "./handlers/domHandler";
import { handleSocketIncoming, handleSocketOutgoing, socketMessageToAction } from "./handlers/incomingHandler";
import {
  selectConnectionOptions,
  selectInteractionStatus,
  selectIsLogged,
  selectLastOutgoingMsg,
  selectLastPongTime,
  selectMaintenanceMode,
  selectMessages,
  selectOnlineStatus,
  selectPreviousViewMode,
  selectQueryOptions,
  selectSeenMessageInfo,
  selectSocketInstance,
  selectStartChatSessionReason,
  selectViewMode,
  selectWelcomeOptions,
} from "./selectors";
import ChatSocket from "../ChatSocket";
const browser = detect();

let tabAnimationInterval = null;
let tabOriginalTitle = null;
let isTabTitleModified = false;
async function setTabPrefix(prefixStr = "", animate = true) {
  if (isTabTitleModified) {
    clearInterval(tabAnimationInterval);
  } else {
    tabOriginalTitle = await IFrameHelper.getTitle();
  }

  if (animate) {
    let showToggle = false;
    tabAnimationInterval = setInterval(() => {
      if (showToggle) {
        IFrameHelper.setTitle(tabOriginalTitle);
      } else {
        IFrameHelper.setTitle("(" + prefixStr + ") " + tabOriginalTitle);
      }
      showToggle = !showToggle;
    }, 1000);
  } else {
    IFrameHelper.setTitle("(" + prefixStr + ") " + tabOriginalTitle);
  }

  const dispatch = () => {
    clearInterval(tabAnimationInterval);
    IFrameHelper.setTitle(tabOriginalTitle);
  };
  isTabTitleModified = true;
  return dispatch;
}
function clearTabPrefix() {
  isTabTitleModified = false;
  clearInterval(tabAnimationInterval);
  if (tabOriginalTitle) {
    IFrameHelper.setTitle(tabOriginalTitle);
  }
}

function* handleConnect() {
  yield put(setSessionStatus("connecting"));
  /** @type {import("../ChatSocket").default} */
  let ws = yield select(selectSocketInstance);
  try {
    if (ws) {
      ws.forceClose();
    }

    const onMessageCallback = (data = {}) => {
      if (!ws) return;
      if (data?.json) {
        data.json.message_time = data.time;
        data.json.time = data.time;
      }
      const incomingData = data?.json || data;

      if (incomingData?.type === "log") {
        //For only developer logging (not for UI)
        const format = "HH:mm:ss.SSS";
        const time = (
          incomingData.message_time ? DateHelper.getDateTime(incomingData.message_time) : DateHelper.getDateTime()
        ).format(format);
        const { name = "", color, object } = incomingData.payload || {};
        let logText = `${time} - ${name}: `;
        Utils.log(logText, object, color);
        return;
      }

      const incomingMessageAction = socketMessageToAction(incomingData);
      if (incomingMessageAction) {
        store.dispatch(wsActionChannelReceived(incomingMessageAction));
      } else {
        console.warn("Incoming message action is null and not handled. Raw Data:", incomingData);
      }
    };
    const onClosedCallback = () => {
      if (!ws) return;
      // store.dispatch(wsDisconnect());
      store.dispatch(setSocketInstance(null));
      console.log("Socket closing gracefully as normal...");
    };
    const onErrorCallback = (event) => {
      if (!ws) return;
      console.error("Socket error:", event);
      store.dispatch(wsConnectFail());
      setTimeout(() => {
        store.dispatch(wsConnect("initial_error_reconnect"));
      }, 10000);
    };

    ws = new ChatSocket("customer", onMessageCallback, onClosedCallback, onErrorCallback);
    window.popup ??= {};
    window.popup.ws = ws;
    yield put(setSocketInstance(ws));
    yield call(ws.connect);
    yield put(wsConnectSuccess(ws));
  } catch (error) {
    yield put(wsConnectFail());
  }
}

let initialLivechatSoundPlayed = false;
function* handleConnectSuccess() {
  const palmateTkn = TokenHelper.getWebchatProjectToken();
  const alias = TokenHelper.getChatbotAlias();
  let options = yield call(StorageHelper.get, LS_WEBCHAT_OPTIONS.format(palmateTkn || alias));

  const isNotificationsEnabled = options?.sound_notification;
  if (isNotificationsEnabled && !initialLivechatSoundPlayed) {
    //Play initial blank sounds to enable sound while tab is not focused.
    Promise.all([AudioHelper.get(audioList.noSound)]).then(async ([audio]) => {
      audio.replay();
      initialLivechatSoundPlayed = true;
    });
  }

  const wsSession = yield call(TokenHelper.getChatbotSessionIfAvailable);
  const { href, referrer } = yield call(IFrameHelper.getLocationInfo);
  const { token, uuid } = wsSession || {};
  const queryOptions = yield select(selectQueryOptions);
  const startChatSessionReason = yield select(selectStartChatSessionReason);

  const { executed, data } = yield call(IFrameHelper.execEvent, "onBeforeLogin", { reason: startChatSessionReason });

  const extraMetadata = data?.metadata || {};
  const extraQueryOptions = data?.query_options || {};

  yield put(
    wsOut.login(
      token,
      {
        ...extraMetadata,
        user_agent: navigator?.userAgent,
        language: navigator?.language,
        title: document.title,
        platform: navigator?.userAgentData?.platform || navigator?.platform,
        mobile: window.isMobileOrTablet(),
        screen_resolution: window.screen.width + "x" + window.screen.height,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        href: href,
        referrer: referrer,
        core: navigator?.hardwareConcurrency,
        cookie_enabled: navigator?.cookieEnabled,
        do_not_track: navigator?.doNotTrack,
        browser: browser?.name,
        browser_version: browser?.version,
        os: browser?.os || "unknown",
        uuid,
      },
      {
        ...extraQueryOptions,
        ...queryOptions.dynamicOptions,
      }
    )
  );
}

function* handleConnectFail() {
  yield put(setSocketInstance(null));
}

function* handleResumeChatSession() {
  const sessionInfo = yield call(TokenHelper.getChatbotSessionIfAvailable);
  if (sessionInfo) {
    yield put(startChatSession({ session_token: sessionInfo.token, expires_in: sessionInfo.tokenExpire }, "resume"));

    let expandPopupAfterConnect = true;
    if (IFrameHelper.isInIFrame()) {
      const { href: parentUrl } = yield call(IFrameHelper.getLocationInfo);
      const url = new URL(parentUrl);
      const redirectData = url.searchParams.get(WEBCHAT_REDIRECT_KEY);
      if (redirectData === WEBCHAT_REDIRECT_VALUE) {
        expandPopupAfterConnect = false;
      }
    }

    if (expandPopupAfterConnect) {
      yield put(setMinimizedStatus(false));
    }
    yield put(setWelcomeMsgShowedStatus(true));
  }
}

function* handleSetMaintenanceStatusChanged() {
  const maintenanceMode = yield select(selectMaintenanceMode);
  if (maintenanceMode) {
    yield put(disconnectRequest("maintenance"));
  }
}
function* handleSyncTabs() {
  // delay + takeLatest -> 250ms debounce effect
  yield delay(250);
  const isLogged = yield select(selectIsLogged);
  if (isLogged) {
    // chatbotBroadcastSync();
    yield call(chatbotBroadcastSync);
  }
}
function* handleStartChatSession({ payload }) {
  /** Disabled like/dislike status updating due no longer showing old messages when current session is terminated */
  // //invalidate previous like/dislike status

  // const messages = [...(yield select(selectMessages)).map((msg) => ({ ...msg }))];
  // for (const msg of messages) {
  //   msg.likeStatus = chatbotLikeDislikeType.disabled;
  // }
  // yield put(setMessageList(messages));

  const palmateTkn = TokenHelper.getWebchatProjectToken();
  const alias = TokenHelper.getChatbotAlias();

  StorageHelper.set(LS_WEBCHAT_TOKEN.format(palmateTkn || alias), payload?.session_token);
  StorageHelper.set(LS_WEBCHAT_BACKUP_TOKEN.format(palmateTkn || alias), payload?.session_token);
  StorageHelper.set(LS_WEBCHAT_TOKEN_EXPIRE.format(palmateTkn || alias), payload?.expires_in);
  if (payload?.message === "identifier is incorrect.") {
    AlertHelper.show(payload.message, "error");
    return;
  }
  yield put(wsConnect("wsConnect_startSession " + payload?.reason));
  // console.log("startChatSession wsConnect");
}

function* handleGetWelcomeOptions({ payload }) {
  const theme = yield select(selectMuiThemeWebchat);
  const queryOptions = yield select(selectQueryOptions);

  if (!queryOptions.previewMode) {
    if (payload?.language) {
      i18next.changeLanguage(payload?.language);
    }
    document.title = payload.popup_title;
  }

  const main = payload.popup_primary_color || theme?.palette?.primary?.main;
  const light = payload.popup_style_info?.primary_light || chroma(main).brighten(1.5).hex();
  const dark = payload.popup_style_info?.primary_dark || chroma(main).darken(0.7).hex();
  const contrastText = payload.popup_style_info?.contrast_color || "#fff";
  const titleText = payload.popup_style_info?.title_color || "#fff";

  yield put(
    setWebchatTheme({
      ...theme,
      palette: {
        ...theme.palette,
        primary: {
          main,
          light,
          dark,
          contrastText,
          titleText,
        },
      },
    })
  );
  const token = yield call(TokenHelper.getWebchatProjectToken);
  const alias = yield call(TokenHelper.getChatbotAlias);
  const webchatToken = yield call(StorageHelper.get, LS_WEBCHAT_TOKEN.format(token || alias));
  if (!payload.maintenance && !queryOptions.previewMode && webchatToken) {
    yield put(resumeChatSession());
    // console.log("resumeChatSession after getWelcomeOptions");
  }
}

function* handleDisconnectRequest() {
  yield put(wsOut.action.terminateSession());
}

let visibilityChangedTime = DateHelper.getDateTime();
function* loginSuccessPinPongTask() {
  yield put(wsOut.ping());

  const connectionOptions = yield select(selectConnectionOptions);
  const pingIntervalSec =
    connectionOptions?.ping_interval > 0 ? connectionOptions?.ping_interval : chatbotPingPongIntervalMs / 1000;

  let onlineStatus = yield select(selectOnlineStatus);
  let offlineAlertKey = "socketConnectionLost";
  if (onlineStatus === "closed") {
    yield put(setOnlineStatus("connected"));
  } else if (onlineStatus === "disconnected") {
    AlertHelper.close(offlineAlertKey);
    AlertHelper.show(i18next.t("chatbot.chatSceneTexts.connectionRestored"), "success");
    const lastOutgoingMsg = yield select(selectLastOutgoingMsg);
    if (lastOutgoingMsg) {
      yield put({ ...lastOutgoingMsg, resendMessage: true });
    }
    yield put(setOnlineStatus("reconnected"));

    // Sentry.captureMessage("Chatbot reconnected", {
    //   level: "warning",
    //   extra: {
    //     lastOutgoingMsg,
    //     currentTime: DateHelper.getDateTime(),
    //   },
    // });
  }

  const showAlert = () => {
    AlertHelper.show(i18next.t("chatbot.chatSceneTexts.connectionLost"), "error", {
      persist: true,
      key: offlineAlertKey,
    });
  };

  const resetPingPongWhenTabVisible = () => {
    // console.log("resetPingPongWhenTabVisible isHidden:", document.hidden);
    if (!document.hidden) {
      visibilityChangedTime = DateHelper.getDateTime();
    }
  };
  document.addEventListener("visibilitychange", resetPingPongWhenTabVisible);
  try {
    while (true) {
      yield delay(pingIntervalSec * 1000);

      // const sessionStatus = yield select(selectSessionStatus);
      // if (sessionStatus === "closed") break;

      const viewMode = yield select(selectViewMode);
      if (["welcome", "maintenance", "disconnected"].includes(viewMode)) {
        AlertHelper.close(offlineAlertKey);
      } else {
        const lastPongTime = yield select(selectLastPongTime);
        const previousPongDiff = DateHelper.getDateTime().diff(lastPongTime, "milliseconds");
        const lastVisibilityChangedDiff = DateHelper.getDateTime().diff(visibilityChangedTime, "milliseconds");
        onlineStatus = yield select(selectOnlineStatus);
        const chatbotPingPongMaxTimeoutMs = getChatbotPingPongMaxTimeoutMs(pingIntervalSec);
        //TODO: Add support to debugger mode to prevent socket connection lost even if ping pong is not received
        // console.log(
        //   "@@lastVisibilityChangedDiff",
        //   lastVisibilityChangedDiff,
        //   chatbotPingPongMaxTimeoutMs,
        //   "onlineStatus",
        //   onlineStatus,
        //   "previousPongDiff",
        //   previousPongDiff
        // );
        if (
          lastVisibilityChangedDiff > chatbotPingPongMaxTimeoutMs &&
          (!lastPongTime || previousPongDiff > chatbotPingPongMaxTimeoutMs) &&
          onlineStatus !== "closed"
        ) {
          //Do not show alert and force reconnect if tab is not active until visible again
          if (!document.hidden) {
            yield put(setOnlineStatus("disconnected"));
            showAlert();
            yield put(resumeChatSession());
          } else {
            console.log("ping pong timeout but tab is not active", previousPongDiff);
          }
        }
      }

      const ws = yield select(selectSocketInstance);

      if (ws) {
        const isAvailable = yield call(ws.isAvailable);
        if (isAvailable) {
          yield put(wsOut.ping());
        }
      }
    }
  } finally {
    // clearTimeout(appearDelayedPingPongTest);
    document.removeEventListener("visibilitychange", resetPingPongWhenTabVisible);
  }
}

function* loginSuccessNoMessageWarnTask() {
  let counter = 0;
  let alertShowed = false;
  let alertKey = new Date().getTime().toString();

  AlertHelper.close(alertKey);
  while (true) {
    yield delay(chatbotNoMessageWarningDelayMs);
    const isLogged = yield select(selectIsLogged);
    if (!isLogged) {
      AlertHelper.close(alertKey);
      break;
    }

    const viewMode = yield select(selectViewMode);
    if ("chat" !== viewMode) {
      AlertHelper.close(alertKey);
      return;
    }

    const messages = yield select(selectMessages);
    if (counter > chatbotNoMessageWarningTryCount && !alertShowed) {
      if (messages.length === 0) {
        AlertHelper.show(i18next.t("chatbot.chatSceneTexts.noMessageAfterLogin"), "warning", {
          persist: true,
          key: alertKey,
        });
        alertShowed = true;
      }
    }
    if (alertShowed && messages.length > 0) {
      AlertHelper.close(alertKey);
      break;
    }
    counter++;
  }
}
function* handleInteractionStatus() {
  //log when browser location is changed

  while (true) {
    yield delay(3000);

    let isLogged = yield select(selectIsLogged);
    if (!isLogged) {
      break;
    }
    const interactionStatus = yield select(selectInteractionStatus);
    const { href: currentUrl } = yield call(IFrameHelper.getLocationInfo);

    if (interactionStatus.location !== currentUrl) {
      const newInteractionStatus = { ...interactionStatus, location: currentUrl };
      yield put(wsOut.interactionStatus(newInteractionStatus));
      yield put(setInteractionStatus(newInteractionStatus));
    }
  }
}

function* handleTitleNotification() {
  function visibilityHandler() {
    const messages = selectMessages(store.getState());
    const incomingMessages = messages.filter((msg) => msg.position === "left");
    const messageLength = incomingMessages?.length || 0;
    if (document.hidden) {
      const seenMessageLength = selectSeenMessageInfo(store.getState()) || 0;
      const messageDiff = messageLength - seenMessageLength;
      if (messageDiff > 0) {
        setTabPrefix(messageDiff);
      }
    } else {
      store.dispatch(
        setSeenMessageInfo({
          current: messageLength,
          new: 0,
        })
      );
      clearTabPrefix();
    }
  }

  try {
    document.addEventListener("visibilitychange", visibilityHandler);
    while (true) {
      yield delay(1000);
    }
  } finally {
    document.removeEventListener("visibilitychange", visibilityHandler);
  }
}

function* handleSeenMessageInfo({ payload }) {
  const { current = 0, new: newMessages = 0 } = payload || {};
  const messages = yield select(selectMessages);
  const incomingMessages = messages.filter((msg) => msg.position === "left") || [];
  const messageLength = incomingMessages.length || 0;
  if (document.hidden) {
    if (newMessages > 0) {
      setTabPrefix(newMessages);
    }
  } else if (newMessages) {
    store.dispatch(setSeenMessageInfo({ current: messageLength, new: 0 }));
    clearTabPrefix();
  }
}

let failedWaitMs = 1000;
export const setFailedWaitMs = (chatbotFailedWaitMs) => (failedWaitMs = chatbotFailedWaitMs);
export const getFailedWaitMs = () => failedWaitMs;

function* handleLoginFail() {
  yield delay(failedWaitMs);
  const queryOptions = yield select(selectQueryOptions);
  const welcomeOptions = yield select(selectWelcomeOptions);
  // const welcomeFormData = yield select(selectWelcomeFormData);
  // if (welcomeOptions.start_anonymous_chat) {
  //   yield put(getTokenAndStartChatSession(queryOptions.token, { identifier: queryOptions.identifier }));
  // }
  if (queryOptions.identifier || welcomeOptions.start_anonymous_chat) {
    yield put(
      getTokenAndStartChatSession(
        queryOptions.token,
        {
          identifier: queryOptions.identifier,
        },
        queryOptions.dynamicOptions
      )
    );
  }
}
function* handleSendInitialQuestion({ payload }) {
  const initialized = payload;
  if (!initialized) return;

  const queryOptions = yield select(selectQueryOptions);
  if (!queryOptions?.question) return;

  const messages = yield select(selectMessages);
  const hasAnyCustomerMessage = messages.some((msg) => msg?.position === "right");
  if (hasAnyCustomerMessage) return;

  yield put(wsOut.message(queryOptions.question));
}

function* handleDisconnectedDone() {
  //TODO: sending twice disconnection done info: disconnected, session_closed
  // const messages = yield select(selectMessages);
  // const lastMessage = messages?.[messages.messages?.length - 1];
  // if (!lastMessage?.disconnect) {
  // yield put(
  //   appendMessage({
  //     type: "notification",
  //     position: "center",
  //     text: i18next.t("chatbot.disconnected"),
  //     messageTime: DateHelper.getDateTime(),
  //     disconnect: true,
  //   })
  // );
  // }
  yield put(setViewModeDelayed("disconnected"));
  yield put(setMessageList([]));
  yield put(setInteractionModal(false));
  ChatTimeoutCountdownModal.hide();

  const ws = yield select(selectSocketInstance);
  if (ws) {
    ws.forceClose();
  }

  IFrameHelper.execEvent("onTerminated");
}

function* watchDebouncedViewMode() {
  yield debounce(150, at.SET_VIEWMODE_DELAYED, function* (action) {
    yield put(setViewMode(action.payload));
  });
}

function* watchViewMode() {
  const viewMode = yield select(selectViewMode);
  const previousViewMode = yield select(selectPreviousViewMode);

  if (viewMode !== "offline" && previousViewMode === "offline") {
    Promise.all([AudioHelper.get(audioList.customerAgentJoined)]).then(async ([audio]) => {
      audio.replay();
    });
  }
}

function* watchSocketSendReceive() {
  const socketChannel = yield actionChannel([at.WS_MSG_RECEIVED, at.WS_MSG_SENT]);

  while (true) {
    const action = yield take(socketChannel);

    const isIncoming = action.type === at.WS_MSG_RECEIVED;
    const asyncMessageTypes = [at.WS_IN_HISTORY, at.WS_IN_ACTION_INACTIVITY_TIMEOUT, at.WS_IN_PONG, at.WS_OUT_PING];
    const shouldRunAsync = asyncMessageTypes.includes(action.payload.type);

    const handlerFunction = isIncoming ? handleSocketIncoming : handleSocketOutgoing;
    const executorFunction = shouldRunAsync ? fork : call;

    yield executorFunction(handlerFunction, action.payload);
  }
}

function* handleOutgoingMessage(action) {
  yield put(wsActionChannelSent(action));
}

function* chatbotSaga() {
  yield all([
    fork(watchSocketSendReceive),

    // Feed outgoing messages to action channel to ensure synchronized send/receive websocket messages
    takeEvery((action) => {
      if (!action.type) return false;
      return action.type.startsWith(at.WS_OUT);
    }, handleOutgoingMessage),

    takeEvery(at.RESUME_CHAT_SESSION, handleResumeChatSession),
    takeEvery(at.START_CHAT_SESSION, handleStartChatSession),
    takeEvery(at.SET_WELCOME_OPTIONS, handleGetWelcomeOptions),
    takeEvery(at.DISCONNECT_REQUEST, handleDisconnectRequest),
    takeEvery(at.DISCONNECT_DONE, handleDisconnectedDone),
    takeEvery(at.SET_MAINTENANCE_STATUS, handleSetMaintenanceStatusChanged),
    takeEvery(at.SET_INITIALIZED, handleSendInitialQuestion),
    takeEvery(at.SET_VIEWMODE_DELAYED, watchDebouncedViewMode),
    takeEvery(at.SET_VIEWMODE, watchViewMode),
    takeEvery(at.DOM_MSG_ACTION, domHandler),

    // Connection + Login
    takeEvery(at.WS_CONNECT, handleConnect),
    takeEvery(at.WS_CONNECT_SUCCESS, handleConnectSuccess),
    takeEvery(at.WS_CONNECT_FAIL, handleConnectFail),
    takeLatest(at.WS_LOGIN_FAIL, handleLoginFail),

    // Tasks
    takeLatest(at.WS_LOGIN_SUCCESS, loginSuccessPinPongTask),
    takeLatest(at.WS_LOGIN_SUCCESS, loginSuccessNoMessageWarnTask),
    takeLatest(at.WS_LOGIN_SUCCESS, handleInteractionStatus),
    takeLatest(at.WS_LOGIN_SUCCESS, handleTitleNotification),
    takeLatest(at.SET_SEEN_MESSAGE_INFO, handleSeenMessageInfo),

    // Broadcast sync between tabs
    takeLatest(at.WS_LOGIN_SUCCESS, handleSyncTabs),
    takeEvery(at.ON_BROADCAST_MESSAGE, broadcastActionsHandler),
  ]);
}

export default chatbotSaga;
