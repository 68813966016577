import { createRef, useCallback, useEffect, useMemo } from "react";

import { MdAdd, MdChat, MdCircle, MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRImg from "~components/Generic/PRImg";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import { apiUrlProject, popupIconType, projectBotStatus, projectBotStatusMap, popupSettingsMapper } from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import { getPermissionUserList } from "~store/settings/permission/actions";
import { selectPermissionUserList } from "~store/settings/permission/selectors";
import { deleteProject } from "~store/settings/projectSettings/actions";
import { selectCurrentProject } from "~store/user/selectors";

const RenderBotStatus = (value) => {
  const statusText = projectBotStatusMap[value.status];
  return value?.status === projectBotStatus.live ? (
    <PRTooltip placement="top" title={statusText}>
      <MdCircle className="text-success fs-5 ms-1" />
    </PRTooltip>
  ) : value?.status === projectBotStatus.ready ? (
    <PRTooltip placement="top" title={statusText}>
      <MdCircle className="text-primary fs-5 ms-1" />
    </PRTooltip>
  ) : (
    <PRTooltip placement="top" title={statusText}>
      <MdCircle className="text-warning fs-5 ms-1" />
    </PRTooltip>
  );
};
export default function ProjectSettingsList() {
  const dispatch = useDispatch();
  const tableRef = createRef();

  const currentProject = useSelector(selectCurrentProject);
  const permissionUserList = useSelector(selectPermissionUserList);

  useEffect(() => {
    dispatch(getPermissionUserList(currentProject.id));
  }, [dispatch, currentProject.id]);

  const columns = useMemo(() => {
    const handleClickDelete = (item) => async () => {
      if (!(await DialogHelper.showQuestionYesNo("Delete", "Are you sure you want to delete this project?"))) return;
      await dispatch(deleteProject(item.id));

      tableRef.current?.refresh();
    };

    return [
      {
        label: "Logo",
        key: "logo",
        render: (item) => {
          if (item.settings?.popup_style_info?.popup_header_icon_type === popupIconType.icon) {
            return (
              <PRImg alt="Chat Title Icon" src={item.settings?.popup_header_icon_path} style={{ width: "32px" }} />
            );
          }
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: item.settings?.popup_primary_color,
                color: "white",
                width: "32px",
                height: "32px",
                borderRadius: "50%",
              }}
            >
              <MdChat style={{ fontSize: "14px" }} />
            </div>
          );
        },
        style: { width: "32px" },
      },
      {
        label: "Name",
        key: "name",
      },
      {
        label: "Creator",
        key: "creator",
        render: (item) => {
          const permissionUser = permissionUserList.find((user) => user.id === item.creator);
          return permissionUser?.firstname + " " + permissionUser?.lastname;
        },
      },
      {
        label: "Bots",
        key: "bots",
        render: (item) => {
          const botList =
            item.settings.chatbot_list ||
            Object.entries(item.settings.chatbot_status || {}).map(([lang, value]) => ({
              language: lang,
              ...value,
            }));
          return (
            <ul className="m-0">
              {botList.map((item) => {
                return (
                  <li key={item.language + item?.name}>
                    [{item.language}]{item?.name}
                    {RenderBotStatus(item)}
                  </li>
                );
              })}
            </ul>
          );
        },
      },
      {
        label: "Actions",
        key: "actions",
        actions: true,
        render: (row) => (
          <div className="d-flex justify-content-center">
            <PRButton
              outline
              link={`/settings/project/form/${row.id}`}
              size="sm"
              tooltipText="Edit"
              color="primary"
              // onClick={handleClickEdit(row)}
              icon={MdEdit}
            />
            <PRButton
              outline
              className="ms-1"
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText="Delete"
              onClick={handleClickDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [dispatch, permissionUserList, tableRef]);

  const actions = useMemo(
    () => [
      {
        label: "Create",
        icon: MdAdd,
        color: "success",
        link: "/settings/project/form/",
        // onClick: async () => {
        //   const result = await CreateOrUpdateProjectModal.show();
        //   if (result) {
        //     tableRef.current?.refresh();
        //   }
        // },
      },
    ],
    []
  );
  const parentName = [
    {
      label: `Project`,
    },
  ];
  const handleOnLoad = useCallback((results) => {
    return results.map((item) => {
      return {
        ...item,
        settings: {
          ...item.settings,
          ...popupSettingsMapper(item.settings),
        },
      };
    });
  }, []);
  return (
    <PRContainer
      actions={actions}
      description={"Here you can create and manage your projects. You can also create and manage your chatbots."}
      name="Settings"
      parentName={parentName}
    >
      <div className="mt-2">
        <PRTable ref={tableRef} columns={columns} url={apiUrlProject.get} onLoad={handleOnLoad} />
      </div>
    </PRContainer>
  );
}
