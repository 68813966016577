import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import chroma from "chroma-js";
import classNames from "classnames";
import _, { isEqualWith } from "lodash";
import moment from "moment-timezone";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { MdAttachFile, MdClose, MdDownload, MdSend } from "react-icons/md";
import { useSelector } from "react-redux";

import styled from "@emotion/styled";
import { ArrowDownward } from "@mui/icons-material";
import MicIcon from "@mui/icons-material/Mic";
import { InputAdornment, Popper } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { ReactComponent as WebchatHome } from "~assets/images/icons/webchat-home.svg";
import useScrollShadow from "~common/hooks/useScrollShadow";
import PalBadge from "~components/mui/PalBadge";
import PalIconButton from "~components/mui/PalIconButton";
import PalTextField from "~components/mui/PalTextField";
import PalTooltip from "~components/mui/PalTooltip";
import { audioList, chatbotActionMinContrastLimit, chatbotTypingThrottleMs } from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import AudioHelper from "~helpers/AudioHelper";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import SpeechHelper from "~helpers/SpeechHelper";
import Utils from "~helpers/Utils";
import { selectMuiThemeWebchat } from "~store/theme/selectors";

import { scrollChatToBottom } from "./ChatUtils";
import { domChatScrollContainerId } from "./constants";
import { PRContextProvider } from "./context";
import InlineMessages from "./InlineMessages";
import VuMeter from "./InlineMessages/RecognitionMessage/VuMeter";
import "./style.scss";

const ChatToolbar = styled.div`
  ${(props) =>
    props.messages?.[props.messages?.length - 1]?.inputFormat?.type === "captcha" &&
    `
  padding: 0;
  margin: 20px 0;
  overflow: visible !important;

`}
`;

class DistributedContext {
  constructor() {
    this._disableScrollOnMessage = false;
  }

  get disableScrollOnMessage() {
    return this._disableScrollOnMessage;
  }
  set disableScrollOnMessage(value) {
    this._disableScrollOnMessage = value;
  }
}
const distributedRef = { current: new DistributedContext() };
const PRChatMessage = forwardRef(function PRChatMessage(
  {
    id, // It helps to reset scroll state
    messages = [],
    hideLikeDislike,
    showCancel,
    onCancel,
    noMic,
    onMessage,
    onMessageChange,
    onClickChoice,
    onClickReturnToHome,
    onMount,
    showTyping,
    focusOnMount = true,
    focusOnRender = true,
    scrollOnMessage = true,
    colorPrimary,
    popupStyleInfo,
    reverseChat,
    className,
    senderType,
    agentLogo,
    botLogo,
    botJoinedLogo,
    onInteraction,
    interactionOptions,
    previewMode,
    customInput,
    inputPlaceholder,

    showAttachment,
    onClickAttachment,
    onDrop,
    onDropRejected,
    dropAccept = [],
    dropMaxSize = 10 * 1024 * 1024,
    dropMaxFiles = 1,

    disableInputRequest,
    disableTextInput,
    disableButton,
    disableMic,
    disableShadow,
    disableLike,
    disableDislike,
    disableReturnToHome,
    disableAvatar,
    disableAttachment,

    readonlyLikeDislike,
    multiline = false,
    enableBlankMessage,
  },
  ref
) {
  const [newValue, setNewValue] = useState("");
  const interactionStatusRef = useRef({
    typing: false,
    focused: false,
  });

  const [typing, setTyping] = useState(false);
  const [recognizing, setRecognizing] = useState(false);
  const { t } = useTranslation();
  const [recognizeInstance, setRecognizeInstance] = useState(null);

  const [showAppearAnimation, setShowAppearAnimation] = useState(true);
  const [previousMessages, setPreviousMessages] = useState(null);
  const [vuMeterAnchorEl, setVuMeterAnchorEl] = useState(null);
  const [vuMeterWidth, setVuMeterWidth] = useState(0);
  const [chatClicked, setChatClicked] = useState(false);
  const [scrollState, setScrollState] = useState({
    lockedToBottom: true,
    lastScrollTop: 0,
    receivedMessageCountAtLocked: 0,
  });

  const leftMessages = useMemo(() => messages?.filter((message) => message.position === "left") || [], [messages]);
  useEffect(() => {
    if (id) {
      setScrollState((prev) => ({
        ...prev,
        lockedToBottom: true,
        lastScrollTop: 0,
        receivedMessageCountAtLocked: 0,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const lastMessage = previousMessages?.[previousMessages?.length - 1];
    if (messages.indexOf(lastMessage) > -1) {
      setShowAppearAnimation(true);
    } else {
      //In this state context changed, so we need to reset also scroll to the top. Auto scroll to bottom will be handled by observer.
      // const dom = document.getElementById(domChatScrollContainerId);
      // if (dom) {
      //   // dom.scrollTop = dom.scrollHeight;

      //   dom.scrollTo({
      //     top: 0,
      //     behavior: "auto",
      //   });
      // }
      setShowAppearAnimation(false);
    }

    setPreviousMessages(messages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const animationState = useMemo(() => {
    return {
      showAppearAnimation,
    };
  }, [showAppearAnimation]);

  const muiThemeWebchat = useSelector(selectMuiThemeWebchat);

  const { throttledTypingFeedback, cancelFeedback } = useMemo(() => {
    const throttleSetter = _.throttle(() => {
      interactionStatusRef.current = { ...interactionStatusRef.current, typing: true };
      if (!interactionOptions?.listen || interactionOptions.listen.includes("typing")) {
        onInteraction?.(interactionStatusRef.current);
      }
    }, chatbotTypingThrottleMs);

    const debounceClear = _.debounce(() => {
      interactionStatusRef.current = { ...interactionStatusRef.current, typing: null };
      if (!interactionOptions?.listen || interactionOptions.listen.includes("typing")) {
        onInteraction?.(interactionStatusRef.current);
      }
    }, chatbotTypingThrottleMs);

    return {
      throttledTypingFeedback: () => {
        throttleSetter();
        debounceClear();
      },
      cancelFeedback: () => {
        debounceClear.cancel();
        throttleSetter.cancel();
      },
    };
  }, [onInteraction, interactionOptions]);

  const handleFileDrop = useCallback(
    (files) => {
      files?.forEach((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: Utils.formatBytes(file.size),
        })
      );

      onDrop?.(files);
    },
    [onDrop]
  );

  const handleDropRejected = useCallback(
    (fileRejections, e) => {
      let tooManyFiles = false;
      for (const fileRejection of fileRejections) {
        for (const rejection of fileRejection.errors) {
          if (rejection.code === "file-invalid-type") {
            AlertHelper.show(t("chatbot.invalidFileType").format((dropAccept || []).join(", ")), "error");
          } else if (rejection.code === "file-too-large") {
            AlertHelper.show(t("chatbot.fileSizeTooLarge").format(Utils.formatBytes(dropMaxSize)), "error");
          }
          //  else if (rejection.code === "file-too-small") {
          //   AlertHelper.show("File is too small. Minimum size is {0}.".format(Utils.formatBytes(minSize)), "error");
          // }
          else if (rejection.code === "too-many-files") {
            if (!tooManyFiles) {
              AlertHelper.show(t("chatbot.tooManyFiles").format(1), "error");
            }
            tooManyFiles = true;
          }
        }
      }
      onDropRejected?.(fileRejections, e);
    },
    [t, dropAccept, dropMaxSize, onDropRejected]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openDropzone,
  } = useDropzone({
    onDrop: handleFileDrop,
    onDropRejected: handleDropRejected,
    noClick: true,
    noKeyboard: true,
    disabled: !showAttachment,
    multiple: dropMaxFiles > 1,
    maxFiles: dropMaxFiles,
    maxSize: dropMaxSize,
    ...(dropAccept?.length ? { accept: dropAccept } : {}),
  });
  // const dropzoneProps = useMemo(() => ({ open }), [open]);

  useEffect(() => {
    if (noMic) return;
    const speech = new SpeechHelper();
    let errorStatus = false;
    speech.setListener((type, e) => {
      if (type === "audiostart") {
        setRecognizing(true);
        errorStatus = false;
        Promise.all([AudioHelper.get(audioList.recognition), AudioHelper.get(audioList.recognitionError)]).then(
          async ([audio, audioError]) => {
            audioError.pause();
            audio.replay();
          }
        );
      } else if (type === "audioend") {
        setRecognizing(false);
      } else if (type === "error") {
        setRecognizing(false);
        DialogHelper.close();

        Promise.all([AudioHelper.get(audioList.recognition), AudioHelper.get(audioList.recognitionError)]).then(
          async ([audio, audioError]) => {
            audio.pause();
            audioError.replay();
          }
        );

        if (e.error === "not-allowed") {
          errorStatus = true;
          DialogHelper.showOk(
            //
            t("chatbot.accessDenied"),
            t("chatbot.micPermissionAlert")
          );
        }
      } else if (type === "result") {
        const result = e.results?.[0]?.[0];
        // const resultGuess = e.results?.[1]?.[0];
        //   DialogHelper.close();
        if (result?.transcript) {
          setNewValue(result.transcript);
          //     DialogHelper.show(
          //       "",
          //       <RecordingModal transcript={result.transcript} guessTranscript={resultGuess?.transcript} />,
          //       [],
          //       {
          //         bare: true,
          //         disableBackdropClick: true,
          //       }
          //     );
        }
      } else if (type === "end") {
        if (errorStatus) return;
        DialogHelper.close();
      } else if (type === "end-result") {
        setRecognizing(false);
        const result = e[0][0];
        setNewValue(result.transcript);
        onMessageChange?.(result.transcript);
      }
    });
    setRecognizeInstance(speech);
  }, [t, noMic]);

  useEffect(() => {
    if (recognizing) {
      const toolbarWrapperDom = document.getElementById("chat-toolbar");
      const toolbarInputDom = document.getElementById("chat-toolbar-inner").childNodes[0];
      const toolbarInputStyles = window.getComputedStyle(toolbarInputDom);
      const paddingLeftRight = parseFloat(toolbarInputStyles.paddingLeft) + parseFloat(toolbarInputStyles.paddingRight);
      setVuMeterWidth(toolbarInputDom.clientWidth - paddingLeftRight);
      setVuMeterAnchorEl(toolbarWrapperDom);
    } else {
      setVuMeterAnchorEl(null);
    }
  }, [recognizing]);

  useEffect(() => {
    if (newValue.length > 0 && !typing) {
      setTyping(true);
    } else if (newValue.length == 0) {
      setTyping(false);
    }
  }, [typing, newValue]);

  useEffect(() => {
    onMount?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClientMessageSend = useCallback(
    async function onClientMessageSend(e, outgoingText) {
      if (previewMode) {
        return;
      }
      if (!customInput) {
        const msgValue = outgoingText || newValue;
        if (msgValue?.length == 0 && !enableBlankMessage) {
          return;
        }
        const event = new CustomEvent("onClientMessageSend", { target: { value: msgValue }, cancelable: true });
        await onMessage?.(
          { type: "text", position: "right", text: msgValue, messageTime: DateHelper.getDateTime() },
          event
        );

        cancelFeedback();
        interactionStatusRef.current = { ...interactionStatusRef.current, typing: false };
        if (!interactionOptions?.listen || interactionOptions.listen.includes("typing")) {
          onInteraction?.(interactionStatusRef.current);
        }
        if (event.defaultPrevented) {
          return;
        }
        setNewValue("");
      } else {
        onMessage();
      }
    },
    [
      newValue,
      onMessage,
      previewMode,
      customInput,
      interactionOptions,
      onInteraction,
      cancelFeedback,
      enableBlankMessage,
    ]
  );

  const handleSpeechRecognition = async (e) => {
    if (previewMode) {
      return;
    }
    if (recognizing) {
      Promise.all([AudioHelper.get(audioList.recognition), AudioHelper.get(audioList.recognitionError)]).then(
        async ([audio, audioError]) => {
          audio.pause();
          audioError.replay();
        }
      );

      recognizeInstance?.stop();
    } else {
      recognizeInstance?.start();
    }
  };

  const setFocusedDebounced = useMemo(
    () =>
      _.debounce((value) => {
        interactionStatusRef.current = { ...interactionStatusRef.current, focused: value };
        if (!interactionOptions?.listen || interactionOptions.listen.includes("focus")) {
          onInteraction?.(interactionStatusRef.current);
        }
      }, 1000),
    []
  );

  const handleChangeChatTextbox = (e) => {
    const value = e.target.value;
    setNewValue(value);
    onMessageChange?.(value);
    if (!value?.length) {
      cancelFeedback();
      interactionStatusRef.current = { ...interactionStatusRef.current, typing: false };
      if (!interactionOptions?.listen || interactionOptions.listen.includes("typing")) {
        onInteraction?.(interactionStatusRef.current);
      }
    } else {
      throttledTypingFeedback();
    }
  };

  const handleInputFocus = useCallback(() => {
    setFocusedDebounced(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setFocusedDebounced(false);
  }, []);

  const handleInputKeyDown = useCallback(
    (e) => {
      if (!multiline && e.key === "Enter") {
        onClientMessageSend();
      }
    },
    [onClientMessageSend, multiline]
  );

  const chatOptionsProps = useMemo(
    () => ({
      disableInputRequest: disableInputRequest,
      hideLikeDislike: hideLikeDislike,
      disableTextInput: disableTextInput,
      disableLike: disableLike,
      disableDislike: disableDislike,
      readonlyLikeDislike: readonlyLikeDislike,
      botLogo: botLogo,
      botJoinedLogo: botJoinedLogo,
      agentLogo: agentLogo,
    }),
    [
      disableInputRequest,
      hideLikeDislike,
      disableTextInput,
      disableLike,
      disableDislike,
      readonlyLikeDislike,
      botJoinedLogo,
      botLogo,
      agentLogo,
    ]
  );

  // const handleToolbarUnmount = useCallback(() => {
  //   setToggleToolbar(true);
  //   setActiveToolbarId((prev) => (prev % 2) + 1);
  // }, []);

  useImperativeHandle(
    ref,
    () => ({
      // scrollToBottom: (mode, scrollToTop) => {
      //   scrollChatToBottom(mode, scrollToTop);
      // },
      openFileUpload: () => {
        open();
      },
      setText: (text) => {
        setNewValue(text);
      },
      send: (text) => {
        onClientMessageSend(null, text);
      },
      disableScrollOnMessage: () => {
        distributedRef.current.disableScrollOnMessage = true;
      },
      enableScrollOnMessage: () => {
        distributedRef.current.disableScrollOnMessage = false;
      },
    }),
    [open, onClientMessageSend]
  );

  // const handlePerfectScrollbarRef = useCallback((ref) => {
  //   window.__ps ??= {};
  //   window.__ps[ref._container.id] = ref;
  // }, []);

  const muiTheme = useMemo(() => {
    const main = colorPrimary || muiThemeWebchat?.palette?.primary?.main;
    const light = popupStyleInfo?.primary_light || chroma(main).brighten(1.5).hex();
    const dark = popupStyleInfo?.primary_dark || chroma(main).darken(0.7).hex();
    const contrastText = popupStyleInfo?.contrast_color || "#fff";
    const titleText = popupStyleInfo?.title_color || "#fff";

    const themeObj = {
      ...muiThemeWebchat,
      palette: {
        ...muiThemeWebchat.palette,
        primary: {
          ...muiThemeWebchat.palette.primary,

          main,
          light,
          dark,
          contrastText,
          titleText,
        },
      },
    };

    const theme = createTheme(themeObj);
    return theme;
  }, [muiThemeWebchat, colorPrimary, popupStyleInfo]);

  const handleClickAttachment = useCallback(() => {
    onClickAttachment?.();
    openDropzone();
  }, [onClickAttachment, openDropzone]);

  const handleClickReturnToHome = useCallback(() => {
    onClickReturnToHome();
  }, [onClickReturnToHome]);

  const handlePaste = useCallback(
    async (e) => {
      const clipboardData = e.clipboardData || window.clipboardData;
      const items = clipboardData.items;
      let shouldPreventDefault = false;
      let count = 0;
      if (items) {
        for (let i = 0; i < items.length; i++) {
          if (count >= dropMaxFiles) break;
          if (items[i].kind === "file") {
            shouldPreventDefault = true;
            const blob = items[i].getAsFile();
            if (blob.size > dropMaxSize) {
              AlertHelper.show(t("chatbot.fileSizeTooLarge").format(Utils.formatBytes(dropMaxSize)), "error");
              break;
            }
            //check mime type
            if (dropAccept?.length) {
              const isValid = Utils.isValidMimeType(dropAccept, blob);
              if (!isValid) {
                AlertHelper.show(t("chatbot.invalidFileType").format((dropAccept || []).join(", ")), "error");
                break;
              }
            }

            await onDrop?.([blob]);
            count++;
          }
        }
      }
      if (shouldPreventDefault) {
        e.preventDefault();
      }
    },
    [t, dropAccept, dropMaxSize, dropMaxFiles, onDrop]
  );

  const handleScrollToBottom = () => {
    scrollChatToBottom("auto");
  };

  const handleClickInputTextField = () => {
    setChatClicked(true);
  };

  const handleClickChatArea = () => {
    setChatClicked(false);
  };

  const {
    ref: containerRef,
    topProps,
    bottomProps,
  } = useScrollShadow({
    listenEventKey: "chat-container-scrolling",
    disabled: disableShadow,
  });
  return (
    <PRContextProvider
      valueAnimation={animationState}
      valueChatOptions={chatOptionsProps}
      valueDropzone={null}
      valueMessages={messages}
      valueOnClickChoice={onClickChoice}
      valuePreviewMode={previewMode}
      valueRecognition={recognizing}
      valueRef={distributedRef}
      valueScrollState={{ state: { ...scrollState, scrollOnMessage }, setState: setScrollState }}
      valueTyping={showTyping}
    >
      <ThemeProvider theme={muiTheme}>
        <div
          {...getRootProps({})}
          aria-label="Chatbot"
          className={classNames("pr-webchat-chat-message position-relative", className)}
          id="chat-message"
          role="region"
        >
          {isDragActive && (
            <div
              className="file-dropping-overlay"
              style={{
                borderColor: "#28aae1",
              }}
            >
              <MdDownload
                style={{
                  color: "#28aae1",
                  fontSize: "4rem",
                }}
              />
            </div>
          )}
          {!scrollState.lockedToBottom && (
            <PalBadge
              badgeContent={leftMessages.length - scrollState.receivedMessageCountAtLocked}
              color="error"
              overlap="circular"
              sx={{ position: "absolute", zIndex: 1000, bottom: 100, right: 20 }}
            >
              <PalIconButton
                sx={(theme) => {
                  let primaryColorChroma = theme.palette.primary.main;
                  const contrastDifference = chroma.contrast(theme.palette.primary.main, "#fff");
                  if (contrastDifference < chatbotActionMinContrastLimit) {
                    primaryColorChroma = chroma(theme.palette.primary.main)
                      .darken(chatbotActionMinContrastLimit - contrastDifference)
                      .hex();
                  }

                  return {
                    color: "#fff",
                    backgroundColor: primaryColorChroma,
                    // border: "1px solid " + theme.palette.primary.light,
                    "&:hover": {
                      backgroundColor: Utils.changeColorBrightness(primaryColorChroma, 25),
                    },

                    "&:disabled": {
                      backgroundColor: Utils.changeColorBrightness(primaryColorChroma, 50),
                    },
                  };
                }}
                onClick={handleScrollToBottom}
              >
                <ArrowDownward
                  style={{
                    color: "#FFFFFF",
                    width: 28,
                    height: 28,
                  }}
                />
              </PalIconButton>
            </PalBadge>
          )}

          <div ref={containerRef} className="chat-area" id={domChatScrollContainerId} onClick={handleClickChatArea}>
            <div {...topProps}></div>
            <div {...bottomProps}></div>

            <InlineMessages
              agentLogo={agentLogo}
              botJoinedLogo={botJoinedLogo}
              botLogo={botLogo}
              disableAvatar={disableAvatar}
              reverseChat={reverseChat}
              scrollOnMessage={scrollOnMessage}
              senderType={senderType}
            />
          </div>
          <Popper
            anchorEl={vuMeterAnchorEl}
            elevation={0}
            open={!!vuMeterAnchorEl}
            placement="top"
            sx={{
              zIndex: 1000,
              paddingBottom: "20px",
            }}
          >
            <VuMeter enable={true} width={vuMeterWidth} />
          </Popper>
          <ChatToolbar className="chat-toolbar" id="chat-toolbar" messages={messages}>
            <div id="chat-toolbar-inner" style={{ height: 52, width: "100%" }}>
              <div className="input-area">
                <div className="text-input" id="chat-textbox">
                  {customInput ? (
                    customInput
                  ) : (
                    <PalTextField
                      id="chat-textbox-input"
                      {...(focusOnMount ? { autoFocus: chatClicked } : {})}
                      fullWidth
                      disabled={!previewMode && disableTextInput}
                      InputProps={{
                        disableUnderline: true,
                        // autoComplete: "none",
                        autoComplete: "off",
                        onFocus: handleInputFocus,
                        onBlur: handleInputBlur,
                        ...(showAttachment && { onPaste: handlePaste }),
                        endAdornment: showAttachment ? (
                          <InputAdornment position="end">
                            <PalIconButton
                              aria-label="Attach file"
                              disabled={disableAttachment || disableTextInput}
                              edge="start"
                              sx={{
                                margin: "5px",
                                "&.MuiIconButton-sizeMedium": {
                                  width: 42,
                                  height: 42,
                                },
                              }}
                              onClick={handleClickAttachment}
                            >
                              <input {...getInputProps()} />
                              <MdAttachFile />
                            </PalIconButton>
                          </InputAdornment>
                        ) : (
                          <>
                            {!noMic && (previewMode || SpeechHelper.isSpeechSupport()) && (
                              <PalTooltip
                                PopperProps={{
                                  modifiers: [
                                    {
                                      name: "offset",
                                      options: {
                                        offset: [0, 16],
                                      },
                                    },
                                  ],
                                }}
                                title={t("chatbot.chatSceneTexts.speechRecognition")}
                              >
                                <InputAdornment position="end">
                                  <PalIconButton
                                    aria-label={t("chatbot.chatSceneTexts.speechRecognition")}
                                    disabled={disableTextInput || disableMic}
                                    edge="start"
                                    sx={{
                                      "&.MuiIconButton-sizeMedium": {
                                        width: 36,
                                        height: 36,
                                        marginRight: disableReturnToHome ? 1 : 0,
                                      },
                                    }}
                                    onClick={handleSpeechRecognition}
                                  >
                                    <MicIcon
                                      style={{
                                        // color: "#000000",
                                        width: 28,
                                        height: 28,
                                      }}
                                    />
                                  </PalIconButton>
                                </InputAdornment>
                              </PalTooltip>
                            )}
                            {!disableReturnToHome && (
                              <PalTooltip
                                PopperProps={{
                                  modifiers: [
                                    {
                                      name: "offset",
                                      options: {
                                        offset: [0, 16],
                                      },
                                    },
                                  ],
                                }}
                                title={t("chatbot.chatSceneTexts.returnToHome")}
                              >
                                <InputAdornment position="end">
                                  <PalIconButton
                                    aria-label={t("chatbot.chatSceneTexts.returnToHome")}
                                    disabled={disableTextInput}
                                    edge="start"
                                    onClick={handleClickReturnToHome}
                                  >
                                    <WebchatHome />
                                  </PalIconButton>
                                </InputAdornment>
                              </PalTooltip>
                            )}
                          </>
                        ),
                      }}
                      inputRef={(input) => chatClicked && focusOnRender && input?.focus()}
                      maxRows={1}
                      multiline={multiline}
                      name="noAutoFill"
                      placeholder={inputPlaceholder ?? t("chatbot.chatSceneTexts.inputPlaceHolder")}
                      value={newValue}
                      variant="standard"
                      onChange={handleChangeChatTextbox}
                      onClick={handleClickInputTextField}
                      onKeyDown={handleInputKeyDown}
                    />
                  )}
                </div>
                <div className="actions">
                  <PalIconButton
                    disabled={disableButton || (showCancel ? false : !previewMode && disableTextInput)}
                    sx={(theme) => {
                      let primaryColorChroma = theme.palette.primary.main;
                      const contrastDifference = chroma.contrast(theme.palette.primary.main, "#fff");
                      if (contrastDifference < chatbotActionMinContrastLimit) {
                        primaryColorChroma = chroma(theme.palette.primary.main)
                          .darken(chatbotActionMinContrastLimit - contrastDifference)
                          .hex();
                      }

                      return {
                        backgroundColor: primaryColorChroma,
                        "&:hover": {
                          backgroundColor: Utils.changeColorBrightness(primaryColorChroma, 25),
                        },
                        "&:disabled": {
                          backgroundColor: Utils.changeColorBrightness(primaryColorChroma, 50),
                        },
                      };
                    }}
                    onClick={showCancel ? onCancel : onClientMessageSend}
                  >
                    {showCancel ? (
                      <MdClose
                        style={{
                          color: "#FFFFFF",
                          width: 28,
                          height: 28,
                        }}
                      />
                    ) : (
                      <MdSend
                        style={{
                          color: "#FFFFFF",
                          width: 28,
                          height: 28,
                        }}
                      />
                    )}
                  </PalIconButton>
                </div>
              </div>

              {/* {activeToolbarId === 2 &&
                (typeof toolbarComponent === "function"
                  ? toolbarComponent({ theme })
                  : toolbarComponent || activeToolbar)} */}
            </div>
          </ChatToolbar>
        </div>
      </ThemeProvider>
    </PRContextProvider>
  );
});

export default memo(PRChatMessage, (prevProps, nextProps) => {
  const { messages: prevMessages, ...prevRest } = prevProps;
  const { messages: nextMessages, ...nextRest } = nextProps;

  const momentCustomizer = (objValue, othValue) => {
    if (moment.isMoment(objValue) && moment.isMoment(othValue)) {
      return objValue.isSame(othValue);
    }
  };
  const areMessagesEqual = prevMessages?.every((message, index) =>
    isEqualWith(message, nextMessages?.[index], momentCustomizer)
  );

  const areRestEqual = Object.keys(prevRest).every((key) => prevRest[key] === nextRest[key]);

  return areMessagesEqual && areRestEqual;
});

// export default memo(PRChatMessage);
