import React, { useLayoutEffect, useMemo, useState } from "react";

import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";

import { useDebouncedEffect } from "~common/hooks/useDebounceEffect";
const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => !["smallSpacing", "borderRadius"].includes(prop),
})`
  // text-align: center;

  border-radius: ${(props) => props.borderRadius}px;
  .MuiInputBase-root {
    min-height: 52px;
    border-radius: ${(props) => props.borderRadius}px;
    background: #f8f8f8;
    padding-left: 0px;
    // padding-right: 0px;
  }
  .MuiInputBase-input {
    padding-left: ${(props) => (props.smallSpacing ? "15px" : "25px")};
    padding-right: ${(props) => (props.type !== "number" ? "0px" : props.smallSpacing ? "15px" : "25px")};
    // padding-right: ${(props) => (props.smallSpacing ? "15px" : "25px")};
    padding-top: 14.5px;
    padding-bottom: 14.5px;
  }

  .MuiFormLabel-root {
    padding: 0px ${(props) => (props.smallSpacing ? "0px" : "12px")};
  }
  .MuiOutlinedInput-notchedOutline,
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
  }
  .MuiOutlinedInput-notchedOutline {
    padding: 0px ${(props) => (props.smallSpacing ? "12px" : "18px")};

    span {
      padding-right: ${(props) => (!props.label ? "0px" : props.smallSpacing ? "10px" : "5px")};
    }
  }
  .MuiFormHelperText-root {
    margin-left: ${(props) => (props.smallSpacing ? "15px" : "25px")};
    font: normal normal normal 14px/20px Museo Sans;
  }
`;

/** @param {import("@mui/material/TextField").TextFieldProps} rest */
const PalTextField = ({
  inputRef: inputRefProp,
  invalid,
  focused,
  error,
  helperText,
  smallSpacing,
  borderRadius,
  ...rest
}) => {
  const isInvalid = !!invalid;
  const [inputRef, setInputRef] = React.useState(null);
  const [wasInitiallyAutoFilled, setWasInitiallyAutoFilled] = useState(false);
  const [tempInternalFocused, setTempInternalFocused] = useState(false);
  const [initFocus, setInitFocus] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const isManageInternalFocus = focused === undefined;

  const hasOnChange = !!rest.onChange;
  useLayoutEffect(() => {
    /**
     * https://github.com/mui/material-ui/issues/36448
     *
     * You can access chrome://flags/#unsafely-treat-insecure-origin-as-secure in your navigation bar and add your
     * domain there for enable autofill in local development
     */
    if (!inputRef || wasInitiallyAutoFilled) return;
    let autoFilled = false;
    const checkAutofill = () => {
      if (autoFilled) return;
      autoFilled = inputRef.matches("*:-webkit-autofill");

      if (wasInitiallyAutoFilled === autoFilled) return;
      setWasInitiallyAutoFilled(autoFilled);
      setTempInternalFocused(true);
    };

    // The time when it's ready is not very stable, so check few times
    setTimeout(checkAutofill, 100);
    setTimeout(checkAutofill, 250);
    setTimeout(checkAutofill, 750);
    setTimeout(checkAutofill, 1500);
    setTimeout(checkAutofill, 3000);
  }, [inputRef, wasInitiallyAutoFilled]);

  const handleInputRef = (node) => {
    setInputRef(node);
    if (typeof inputRefProp === "function") {
      inputRefProp(node);
    } else if (inputRefProp && hasOwnProperty.call(inputRefProp, "current")) {
      inputRefProp.current = node;
    }
  };

  const inputProps = useMemo(() => {
    return {
      ...rest.inputProps,
      ...(hasOnChange && rest.inputProps?.value === null && { value: "" }),
    };
  }, [rest.inputProps, hasOnChange]);

  const handleFocus = (e) => {
    if (tempInternalFocused !== undefined) {
      setTempInternalFocused(undefined);
    }
    rest?.onFocus?.(e);
    if (isManageInternalFocus) {
      setIsFocused(true);
    }
  };
  const handleBlur = (e) => {
    if (tempInternalFocused !== undefined) {
      setTempInternalFocused(undefined);
    }
    rest?.onBlur?.(e);
    if (isManageInternalFocus) {
      setIsFocused(false);
    }
  };

  useDebouncedEffect(
    () => {
      if (!initFocus) return;
      if (rest.value || rest.defaultValue) {
        setTempInternalFocused(true);
        setTimeout(() => {
          setTempInternalFocused(false);
          setInitFocus(true);
        }, 250);
      }
    },
    [rest.value, rest.defaultValue, initFocus],
    250
  );

  return (
    <StyledTextField
      borderRadius={borderRadius || 26}
      focused={
        (isManageInternalFocus && isFocused) || (focused ?? tempInternalFocused) //|| !!rest.value || !!rest.defaultValue
      }
      inputRef={handleInputRef}
      smallSpacing={smallSpacing}
      {...rest}
      error={error || isInvalid}
      helperText={helperText ?? (isInvalid ? invalid : "")}
      inputProps={inputProps}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  );
};

export default PalTextField;
