import { apiUrlStatistics } from "~constants";
import Network from "~helpers/Network";

import * as at from "./actionTypes";

//Old functions
export const setChatbotStatistics = (payload) => ({
  type: at.SET_CHATBOT_STATISTICS,
  payload,
});

export const setWeeklySessions = (payload) => ({
  type: at.SET_WEEKLY_SESSIONS,
  payload,
});

export const setWeeklyTickets = (payload) => ({
  type: at.SET_WEEKLY_TICKETS,
  payload,
});

export const setAgentStatistics = (payload) => ({
  type: at.SET_AGENT_STATISTICS,

  payload,
});

export const setAgentTicketStatistics = (payload) => ({
  type: at.SET_AGENT_TICKET_STATISTICS,
  payload,
});

export const setTicketStatistics = (payload) => ({
  type: at.SET_TICKET_STATISTICS,
  payload,
});
export const setGenericStatistics = (payload) => ({
  type: at.SET_GENERIC_STATISTICS,
  payload,
});

export const getChatbotStatistics = (projectId, botId) => (_dispatch, _getState) => {
  const url = `${apiUrlStatistics.getChatbot.format(projectId, botId)}`;
  return Network.request(url, {
    onSuccess: setChatbotStatistics,
  });
};

export const getWeeklyTickets = (projectId) => (_dispatch, _getState) => {
  const url = `${apiUrlStatistics.getWeeklyTickets.format(projectId)}`;
  return Network.request(url, {
    loading: true,
    onSuccess: setWeeklyTickets,
  });
};

export const getAgentStatistics = (projectId, agentId) => (_dispatch, _getState) => {
  const url = `${apiUrlStatistics.getAgent.format(projectId, agentId)}`;
  return Network.request(url, {
    onSuccess: setAgentStatistics,
  });
};

export const getAgentTicketStatistics = (projectId, agentId) => (_dispatch, _getState) => {
  const url = `${apiUrlStatistics.getAgentTicket.format(projectId, agentId)}`;
  return Network.request(url, {
    loading: true,
    onSuccess: setAgentTicketStatistics,
  });
};

export const getTicketStatistics =
  (projectId, data = {}) =>
  () => {
    const url = `${apiUrlStatistics.getTicketStatistics.format(projectId)}`;

    return Network.request(url, {
      method: "POST",
      loading: true,
      onSuccess: setTicketStatistics,
      data,
    });
  };

let getGenericStatisticsAbortController = null;
export const getGenericStatistics =
  (projectId, data, options = {}) =>
  (_dispatch, _getState) => {
    if (getGenericStatisticsAbortController) {
      getGenericStatisticsAbortController.abort();
    }
    getGenericStatisticsAbortController = new AbortController();
    const url = `${apiUrlStatistics.getGenericStatistics.format(projectId)}`;
    return Network.request(url, {
      loading: false,
      method: "POST",
      data,
      onSuccess: setGenericStatistics,
      ...options,
      signal: getGenericStatisticsAbortController.signal,
    });
  };

export const downloadStatistics =
  (projectId, data, options = {}) =>
  (_dispatch, _getState) => {
    const url = `${apiUrlStatistics.getGenericStatistics.format(projectId)}?excel=True`;
    return Network.request(url, {
      method: "POST",
      responseType: "blob",
      data,
      loading: true,
      ...options,
    });
  };
//Old functions end

export const setStatistics = (payload) => ({
  type: at.SET_STATISTICS,
  payload,
});

export const setStatisticsFilter = (payload) => ({
  type: at.SET_STATISTICS_FILTER,
  payload,
});

export const setAgentSpesificStatistics = (payload) => ({
  type: at.SET_AGENT_SPESIFIC_STATISTICS,
  payload,
});

export const setAgentFilterIds = (payload) => ({
  type: at.SET_AGENT_FILTER_IDS,
  payload,
});

export const getStatistics =
  (projectId, keys, beginDate, endDate, isAggregate = true, params, options = {}) =>
  async (_dispatch, _getState) => {
    const url = `${apiUrlStatistics.getStatistics.format(projectId)}`;
    const result = await Network.request(url, {
      method: "POST",
      data: {
        ticket_category_ids: [],
        begin_date: beginDate,
        end_date: endDate,
        stat_type: isAggregate ? "aggregate" : "list",
        keys,
      },
      responseType: params?.excel ? "blob" : "json",
      params,
      ...options,
    });
    if (params?.excel) {
      return result;
    }
    const data = result.data.total || (isAggregate ? {} : []);
    if (!isAggregate) {
      for (const key of Object.keys(data)) {
        const list = data[key].reduce((acc, cur) => {
          acc = { ...acc, ...cur };
          return acc;
        });
        data[key] = list;
      }
    }
    _dispatch(setStatistics(data));
  };

export const getAgentSpesificStatistics =
  (projectId, keys, beginDate, endDate, agentIds = [], isAggregate = true, params, options = {}) =>
  async (_dispatch, _getState) => {
    const url = `${apiUrlStatistics.getStatistics.format(projectId)}`;
    const result = await Network.request(url, {
      method: "POST",
      data: {
        agent_ids: agentIds,
        ticket_category_ids: [],
        begin_date: beginDate,
        end_date: endDate,
        stat_type: isAggregate ? "aggregate" : "list",
        keys,
      },
      responseType: params?.excel ? "blob" : "json",
      params,
      ...options,
    });
    if (params?.excel) {
      return result;
    }
    const data = result.data || (isAggregate ? {} : []);
    for (const id of Object.keys(data)) {
      if (!isAggregate) {
        for (const key of Object.keys(data[id])) {
          const list = data[id][key].reduce((acc, cur) => {
            acc = { ...acc, ...cur };
            return acc;
          });
          data[id][key] = list;
        }
      }
      // const state = _getState().statistics.agentSpesificStatistics;
      // const total = {}
      // for (const key of Object.keys(state)) {
      //   if (key!== "total"){

      // }
      _dispatch(setAgentSpesificStatistics({ id, data: data[id] }));
    }
  };

const SANKEY_KEY_LIST = [
  "SESSION_COUNT_SANKEY_APP",
  "SESSION_COUNT_SANKEY_DESKTOP",
  "SESSION_COUNT_SANKEY_MOBILE",
  "SESSION_COUNT_SANKEY_APP_TICKET",
  "SESSION_COUNT_SANKEY_APP_AGENT",
  "SESSION_COUNT_SANKEY_APP_BOT",
  "SESSION_COUNT_SANKEY_DESKTOP_TICKET",
  "SESSION_COUNT_SANKEY_DESKTOP_AGENT",
  "SESSION_COUNT_SANKEY_DESKTOP_BOT",
  "SESSION_COUNT_SANKEY_MOBILE_TICKET",
  "SESSION_COUNT_SANKEY_MOBILE_AGENT",
  "SESSION_COUNT_SANKEY_MOBILE_BOT",
  "SESSION_COUNT_SANKEY_APP_AGENT_SUCCESSFUL",
  "SESSION_COUNT_SANKEY_APP_AGENT_UNSUCCESSFUL",
  "SESSION_COUNT_SANKEY_APP_AGENT_NOT_GRADED",
  "SESSION_COUNT_SANKEY_APP_BOT_SUCCESSFUL",
  "SESSION_COUNT_SANKEY_APP_BOT_UNSUCCESSFUL",
  "SESSION_COUNT_SANKEY_APP_BOT_NOT_GRADED",
  "SESSION_COUNT_SANKEY_DESKTOP_AGENT_SUCCESSFUL",
  "SESSION_COUNT_SANKEY_DESKTOP_AGENT_UNSUCCESSFUL",
  "SESSION_COUNT_SANKEY_DESKTOP_AGENT_NOT_GRADED",
  "SESSION_COUNT_SANKEY_DESKTOP_BOT_SUCCESSFUL",
  "SESSION_COUNT_SANKEY_DESKTOP_BOT_UNSUCCESSFUL",
  "SESSION_COUNT_SANKEY_DESKTOP_BOT_NOT_GRADED",
  "SESSION_COUNT_SANKEY_MOBILE_AGENT_SUCCESSFUL",
  "SESSION_COUNT_SANKEY_MOBILE_AGENT_UNSUCCESSFUL",
  "SESSION_COUNT_SANKEY_MOBILE_AGENT_NOT_GRADED",
  "SESSION_COUNT_SANKEY_MOBILE_BOT_SUCCESSFUL",
  "SESSION_COUNT_SANKEY_MOBILE_BOT_UNSUCCESSFUL",
  "SESSION_COUNT_SANKEY_MOBILE_BOT_NOT_GRADED",
];

export const getSessionCountSankeyStatistics = (projectId, beginDate, endDate, excel = false) => {
  return getStatistics(projectId, SANKEY_KEY_LIST, beginDate, endDate, true, excel ? { excel: true } : {});
};

const PIE_CHART_KEY_LIST = [
  "SESSION_COUNT_APP",
  "SESSION_COUNT_DESKTOP",
  "SESSION_COUNT_MOBILE",
  "SESSION_COUNT_APP_WHATSAPP",
  "SESSION_COUNT_APP_TELEGRAM",
  "SESSION_COUNT_APP_TEAMS",
  "SESSION_COUNT_DESKTOP_WINDOWS",
  "SESSION_COUNT_DESKTOP_MAC",
  "SESSION_COUNT_DESKTOP_OTHER",
  "SESSION_COUNT_MOBILE_IOS",
  "SESSION_COUNT_MOBILE_ANDROID",
  "SESSION_COUNT_MOBILE_OTHER",
];

export const getSessionCountPieChartStatistics = (projectId, beginDate, endDate, excel = false) => {
  return getStatistics(projectId, PIE_CHART_KEY_LIST, beginDate, endDate, true, excel ? { excel: true } : {});
};

export const getChatbotQuestionCountStatistics = (projectId, beginDate, endDate, excel = false) => {
  return getStatistics(projectId, ["CHATBOT_QUESTION_COUNT"], beginDate, endDate, false, excel ? { excel: true } : {});
};

export const getChatbotSessionCountStatistics = (projectId, beginDate, endDate, excel = false) => {
  return getStatistics(
    projectId,
    ["CHATBOT_SESSION_COUNT_BOT_ONLY", "CHATBOT_SESSION_COUNT_AGENT", "CHATBOT_SESSION_COUNT_TICKET"],
    beginDate,
    endDate,
    false,
    excel ? { excel: true } : {}
  );
};

export const getChatbotSessionRateStatistics = (projectId, beginDate, endDate, excel = false) => {
  return getStatistics(
    projectId,
    [
      "CHATBOT_SESSION_RATE_1",
      "CHATBOT_SESSION_RATE_2",
      "CHATBOT_SESSION_RATE_3",
      "CHATBOT_SESSION_RATE_4",
      "CHATBOT_SESSION_RATE_5",
    ],
    beginDate,
    endDate,
    false,
    excel ? { excel: true } : {}
  );
};

export const getChatbotLikeDislikeStatistics = (projectId, beginDate, endDate, excel = false) => {
  return getStatistics(
    projectId,
    ["CHATBOT_LIKE_COUNT", "CHATBOT_DISLIKE_COUNT"],
    beginDate,
    endDate,
    false,
    excel ? { excel: true } : {}
  );
};

export const getChatbotIntentReviewStatistics = (projectId, beginDate, endDate, excel = false) => {
  return getStatistics(
    projectId,
    ["CHATBOT_INTENT_REVIEW_REQUIRED", "CHATBOT_INTENT_REVIEWED"],
    beginDate,
    endDate,
    false,
    excel ? { excel: true } : {}
  );
};

export const getCallCenterQueueWaitStatistics = (projectId, beginDate, endDate, excel = false) => {
  return getStatistics(
    projectId,
    [
      "CALLCENTER_QUEUE_WAIT_30",
      "CALLCENTER_QUEUE_WAIT_60",
      "CALLCENTER_QUEUE_WAIT_300",
      "CALLCENTER_QUEUE_WAIT_300_PLUS",
      "CALLCENTER_QUEUE_WAIT_30_MISSED",
      "CALLCENTER_QUEUE_WAIT_30_CATCHED",
      "CALLCENTER_QUEUE_WAIT_30_TICKET",
      "CALLCENTER_QUEUE_WAIT_60_MISSED",
      "CALLCENTER_QUEUE_WAIT_60_CATCHED",
      "CALLCENTER_QUEUE_WAIT_60_TICKET",
      "CALLCENTER_QUEUE_WAIT_300_MISSED",
      "CALLCENTER_QUEUE_WAIT_300_CATCHED",
      "CALLCENTER_QUEUE_WAIT_300_TICKET",
      "CALLCENTER_QUEUE_WAIT_300_PLUS_MISSED",
      "CALLCENTER_QUEUE_WAIT_300_PLUS_CATCHED",
      "CALLCENTER_QUEUE_WAIT_300_PLUS_TICKET",
    ],
    beginDate,
    endDate,
    true,
    excel ? { excel: true } : {}
  );
};

export const getTicketResolveStatistics = (projectId, beginDate, endDate, excel = false) => {
  return getStatistics(
    projectId,
    [
      "TICKET_RESOLVE_15",
      "TICKET_RESOLVE_60",
      "TICKET_RESOLVE_120",
      "TICKET_RESOLVE_360",
      "TICKET_RESOLVE_1440",
      "TICKET_RESOLVE_2880",
      "TICKET_RESOLVE_2880_PLUS",
    ],
    beginDate,
    endDate,
    true,
    excel ? { excel: true } : {}
  );
};

export const getTicketUnassignedStatistics = (projectId, beginDate, endDate, excel = false) => {
  return getStatistics(
    projectId,
    [
      "TICKET_UNASSIGNED_15",
      "TICKET_UNASSIGNED_60",
      "TICKET_UNASSIGNED_120",
      "TICKET_UNASSIGNED_360",
      "TICKET_UNASSIGNED_1440",
      "TICKET_UNASSIGNED_2880",
      "TICKET_UNASSIGNED_2880_PLUS",
    ],
    beginDate,
    endDate,
    true,
    excel ? { excel: true } : {}
  );
};

export const getTicketStackBarStatistics = (projectId, beginDate, endDate, excel = false) => {
  return getStatistics(
    projectId,
    ["TICKET_OPEN_COUNT", "TICKET_INPROGRESS_COUNT", "TICKET_CLOSED_COUNT"],
    beginDate,
    endDate,
    false,
    excel ? { excel: true } : {}
  );
};

export const getAgentRateStatistics = (projectId, agentIds, beginDate, endDate, excel = false) => {
  return getAgentSpesificStatistics(
    projectId,
    ["AGENT_RATING_1", "AGENT_RATING_2", "AGENT_RATING_3", "AGENT_RATING_4", "AGENT_RATING_5"],
    beginDate,
    endDate,
    agentIds,
    false,
    excel ? { excel: true } : {}
  );
};

export const getAgentResponseTimeStatistics = (projectId, agentIds, beginDate, endDate, excel = false) => {
  return getAgentSpesificStatistics(
    projectId,
    ["AGENT_RESPONSE_TIME_15", "AGENT_RESPONSE_TIME_60", "AGENT_RESPONSE_TIME_300", "AGENT_RESPONSE_TIME_300_PLUS"],
    beginDate,
    endDate,
    agentIds,
    false,
    excel ? { excel: true } : {}
  );
};

export const getAgentLikeDislikeStatistics = (projectId, agentIds, beginDate, endDate, excel = false) => {
  return getAgentSpesificStatistics(
    projectId,
    ["AGENT_LIKE_COUNT", "AGENT_DISLIKE_COUNT"],
    beginDate,
    endDate,
    agentIds,
    false,
    excel ? { excel: true } : {}
  );
};

export const getAgentSessionCountStatistics = (projectId, agentIds, beginDate, endDate, excel = false) => {
  return getAgentSpesificStatistics(
    projectId,
    ["AGENT_SESSION_COUNT"],
    beginDate,
    endDate,
    agentIds,
    false,
    excel ? { excel: true } : {}
  );
};

export const getAgentAvailableTimeStatistics = (projectId, agentIds, beginDate, endDate, excel = false) => {
  return getAgentSpesificStatistics(
    projectId,
    ["AGENT_AVAILABLE_TIME"],
    beginDate,
    endDate,
    agentIds,
    false,
    excel ? { excel: true } : {}
  );
};

export const getAgentTotalChatTimeStatistics = (projectId, agentIds, beginDate, endDate, excel = false) => {
  return getAgentSpesificStatistics(
    projectId,
    ["AGENT_TOTAL_CHAT_TIME"],
    beginDate,
    endDate,
    agentIds,
    false,
    excel ? { excel: true } : {}
  );
};

export const getAgentTicketResolveCountStatistics = (projectId, agentIds, beginDate, endDate, excel = false) => {
  return getAgentSpesificStatistics(
    projectId,
    ["AGENT_TICKET_RESOLVE_COUNT"],
    beginDate,
    endDate,
    agentIds,
    false,
    excel ? { excel: true } : {}
  );
};

export const getAgentTicketResolveStatistics = (projectId, agentIds, beginDate, endDate, excel = false) => {
  return getAgentSpesificStatistics(
    projectId,
    [
      "AGENT_TICKET_RESOLVE_15",
      "AGENT_TICKET_RESOLVE_60",
      "AGENT_TICKET_RESOLVE_120",
      "AGENT_TICKET_RESOLVE_360",
      "AGENT_TICKET_RESOLVE_1440",
      "AGENT_TICKET_RESOLVE_2880",
      "AGENT_TICKET_RESOLVE_2880_PLUS",
    ],
    beginDate,
    endDate,
    agentIds,
    false,
    excel ? { excel: true } : {}
  );
};
