import React, { useState } from "react";

import { BiLoaderAlt } from "react-icons/bi";
import { Label } from "reactstrap";

import PRButton from "~components/Generic/PRButton";
import PRInput from "~components/Generic/PRInput";
import PRNumber from "~components/Generic/PRNumber";

let mediaRecorder, socket;
export default function Microphone() {
  const [isRecording, setIsRecording] = useState(false);
  const [timeslice, setTimeslice] = useState(1000);
  const [ws, setWs] = useState("ws://localhost:8000/ws/sound/");

  async function startStopMicrophoneStream() {
    if (isRecording) {
      mediaRecorder.stop();
      setIsRecording(false);
    } else {
      try {
        if (!mediaRecorder) {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

          mediaRecorder = new MediaRecorder(stream);

          mediaRecorder.ondataavailable = (event) => {
            const soundChunk = event.data;

            if (soundChunk.size > 0) {
              console.log("Data chunk", event);
              socket.send(soundChunk);
            }
          };
        }

        //   mediaRecorder.onstop = () => {
        //     // Ses verilerini birleştir ve byte dizisine dönüştür
        //     const blob = new Blob(chunks, { type: "audio/ogg; codecs=opus" });
        //     const reader = new FileReader();
        //     reader.onload = () => {
        //       const byteArray = new Uint8Array(reader.result);

        //       // Byte dizisini konsola yazdır
        //       console.log(byteArray);

        //       // Opsiyonel olarak WebSocket'e göndermek için kullanabilirsiniz
        //       // const socket = new WebSocket("ws://backend-server-url");
        //       // socket.addEventListener("open", () => {
        //       //     socket.send(byteArray);
        //       // });
        //     };
        //     reader.readAsArrayBuffer(blob);
        //   };

        mediaRecorder.start();
        socket = new WebSocket(ws, "audio");
        setIsRecording(true);

        // Ses kaydını durdurmak için butona tıklandığında
      } catch (error) {
        console.error("Mikrofon izni alınamadı:", error);
      }
    }
  }
  return (
    <div className="text-center m-5">
      <Label>Timeslice</Label>
      <PRNumber value={timeslice} onChange={(e) => setTimeslice(e.floatValue)} />
      <br />
      <br />
      <Label>SW</Label>

      <PRInput value={ws} onChange={(e) => setWs(e.target.value)} />

      <br />
      <br />
      <PRButton onClick={startStopMicrophoneStream}>
        {isRecording && <BiLoaderAlt className="spin me-2" />}
        {isRecording ? "Stop" : "Start"}
      </PRButton>
    </div>
  );
}
