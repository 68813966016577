// import {
//   MdOutlineBarChart,
//   MdOutlineChat,
//   MdOutlineExtension,
//   MdOutlineGroup,
//   MdOutlineHeadsetMic,
//   MdOutlineHelpCenter,
//   MdOutlineHome,
//   MdOutlineSettings,
//   MdOutlineSms,
// } from "react-icons/md";

import { MdOutlineExtension } from "react-icons/md";

import { ReactComponent as ChatbotIcon } from "~assets/images/icons/sidebar-icon-chatbot.svg";
import { ReactComponent as ContactCenterIcon } from "~assets/images/icons/sidebar-icon-contact-center.svg";
import { ReactComponent as HelpDeskIcon } from "~assets/images/icons/sidebar-icon-help-desk.svg";
import { ReactComponent as HomeIcon } from "~assets/images/icons/sidebar-icon-home.svg";
import { ReactComponent as OrganizationIcon } from "~assets/images/icons/sidebar-icon-organization.svg";
import { ReactComponent as SettingsIcon } from "~assets/images/icons/sidebar-icon-settings.svg";
import { ReactComponent as StatisticsIcon } from "~assets/images/icons/sidebar-icon-statistics.svg";
import { ReactComponent as TryChatbotIcon } from "~assets/images/icons/sidebar-icon-try-chatbot.svg";
import { PrivacyPolicy, TermsOfUse } from "~components/Layout/Footer";
import { userRole } from "~constants";
import BotSettings from "~pages/ChatBot/BotSettings";
import { BotMessages } from "~pages/ChatBot/DialogComponents/bot.messages";
import Database from "~pages/ChatBot/DialogComponents/Database";
import Dictionaries from "~pages/ChatBot/DialogComponents/Dictionaries";
import EntityTypes from "~pages/ChatBot/DialogComponents/EntityTypes";
import IntentRecommend from "~pages/ChatBot/DialogComponents/IntentRecommend";
import IntentRecords from "~pages/ChatBot/DialogComponents/IntentRecords";
import IntentTags from "~pages/ChatBot/DialogComponents/IntentTags";
import Media from "~pages/ChatBot/DialogComponents/Media";
import ScenarioManager from "~pages/ChatBot/DialogComponents/ScenarioManager";
import Variables from "~pages/ChatBot/DialogComponents/Variables";
import IntentLeaderboard from "~pages/ChatBot/IntentLeaderboard";
import TryChatbot from "~pages/ChatBot/TryChatbot";
import ContactCenter from "~pages/ContactCenter";
import HelpDesk from "~pages/HelpDesk";
import Integration from "~pages/Integration";
import Microphone from "~pages/Microphone";
import Organization from "~pages/Organization";
import CustomerTicketDetail from "~pages/public/CustomerTicketDetail";
import ForgotPassword2 from "~pages/public/ForgotPassword";
import Register2 from "~pages/public/Register";
import Settings from "~pages/Settings";
import AgentStatistics from "~pages/Statistics/AgentStatistics";
import CallCenterStatistics from "~pages/Statistics/CallCenterStatistics";
import ChatbotStatistics from "~pages/Statistics/ChatbotStats2";
import SessionStatistics from "~pages/Statistics/SessionStats";
import StatisticGenerator from "~pages/Statistics/StatisticGenerator";
import TicketStatistics from "~pages/Statistics/TicketStatistics";
import Welcome from "~pages/Welcome";

import Dashboard from "../pages/Homepage/index";
import JoinProject from "../pages/public/JoinProject";
import Login2 from "../pages/public/Login";
import Logout from "../pages/public/Logout";

export const publicRoutes = [
  {
    path: "/logout",
    component: Logout,
  },
  {
    path: "/login",
    component: Login2,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword2,
  },
  {
    path: "/join-project",
    component: JoinProject,
  },
  {
    path: "/ticketdetail/:alias?/",
    component: CustomerTicketDetail,
  },
  {
    path: "/register",
    component: Register2,
  },
  {
    path: "/mic",
    component: Microphone,
  },
  {
    path: "/term-of-use",
    component: function () {
      return <TermsOfUse className="m-5" />;
    },
  },
  {
    path: "/privacy-policy",
    component: function () {
      return <PrivacyPolicy className="m-5" />;
    },
  },
];

export const sidebarMenuData = [
  // {
  //   name: "Menu",
  //   isLabel: true,
  //   permissions: [userRole.admin],
  //   noPermission: true,
  // },

  {
    name: "Welcome",
    icon: <HomeIcon className="sidebar-icon" />,
    path: "/welcome",
    component: Welcome,
    noPermission: true,
  },
  {
    name: "Homepage",
    icon: <HomeIcon className="sidebar-icon" />,
    path: "/home",
    component: Dashboard,
    permissions: [userRole.admin],
  },
  {
    isDivider: true,
    permissions: [userRole.admin],
    noPermission: true,
  },
  // {
  //   name: "Apps",
  //   isLabel: true,
  //   permissions: [userRole.admin],
  // },
  {
    name: "Try Chatbot",
    icon: <TryChatbotIcon className="sidebar-icon" />,
    // iconColor: getColor(), //"hsl(84deg 100% 62%)",
    permissions: [userRole.admin, userRole.chatbotModerator],
    path: "/try-chatbot/",
    component: TryChatbot,
  },
  {
    isDivider: true,
    permissions: [userRole.admin, userRole.chatbotModerator],
  },
  {
    name: "Chatbot",
    icon: <ChatbotIcon className="sidebar-icon" />,
    // iconColor: getColor(), //"hsl(84deg 100% 62%)",
    permissions: [userRole.admin, userRole.chatbotModerator],
    path: "/chatbot/",
    children: [
      {
        name: "Bot Settings",
        path: "/chatbot/settings/",
        component: BotSettings,
      },
      // {
      //   name: "Try Chatbot",
      //   path: "/chatbot/try-chatbot/",
      //   component: TryChatbot,
      // },
      {
        name: "Intent",
        path: `/chatbot/intent/`,
        //icon: "??? mdi-book-open-variant",
        component: ScenarioManager,
      },
      {
        name: "Entity Types",
        path: `/chatbot/entity-types/`,
        //icon: "??? bx-customize",
        component: EntityTypes,
      },
      {
        name: "Variables",
        path: `/chatbot/variables/`,
        //icon: "??? mdi-variable",
        component: Variables,
      },
      {
        name: "Media",
        path: `/chatbot/media/`,
        //icon: "??? mdi-file-image",
        component: Media,
      },
      {
        name: "Database",
        path: `/chatbot/database/`,
        //icon: "??? mdi-database",
        component: Database,
      },
      {
        name: "Bot Messages",
        path: `/chatbot/bot-messages/`,
        //icon: "??? bx-message-add",
        component: BotMessages,
      },
      {
        name: "Intent Tags",
        path: `/chatbot/intent-tags/`,
        //icon: "??? mdi-code-tags",
        component: IntentTags,
      },
      {
        name: "Intent Recommend",
        path: `/chatbot/intent-recommend/`,
        //icon: "??? mdi-code-tags",
        component: IntentRecommend,
      },
      {
        name: "Intent Records",
        path: `/chatbot/intent-records/`,
        //icon: "??? mdi-code-tags",
        component: IntentRecords,
      },
      {
        name: "Dictionaries",
        path: `/chatbot/dictionaries/`,
        //icon: "??? mdi-book-alphabet",
        component: Dictionaries,
      },
      {
        name: "Intent Leaderboard",
        path: `/chatbot/intent-leaderboard/`,
        //icon: "??? mdi-book-alphabet",
        component: IntentLeaderboard,
      },
    ],
  },
  {
    name: "Contact Center",
    icon: <ContactCenterIcon className="sidebar-icon" />,
    // iconColor: getColor(), //"hsl(132deg 100% 62%)",
    permissions: [userRole.admin, userRole.callCenterAgent, userRole.ticketAgent, userRole.AgentManager],
    component: ContactCenter,
    path: "/contactcenter/",
    children: [
      {
        name: "Live Chat",
        path: "/contactcenter/livechat/",
        permissions: [userRole.admin, userRole.callCenterAgent, userRole.AgentManager],
      },
      {
        name: "Chat History",
        path: "/contactcenter/history/",
      },
      {
        name: "Chat Messages",
        path: "/contactcenter/chat-messages/",
      },
      {
        name: "Chat Labels",
        path: "/contactcenter/chat-labels/",
      },
      {
        name: "Wallboard",
        path: "/contactcenter/wallboard/",
        permissions: [userRole.admin, userRole.callCenterAgent, userRole.AgentManager],
      },
      {
        name: "Agent Leaderboard",
        path: "/contactcenter/agent-leaderboard/",
        permissions: [userRole.admin, userRole.callCenterAgent, userRole.AgentManager],
      },
      {
        name: "Statistics",
        path: "/contactcenter/statistics/",
        permissions: [userRole.admin, userRole.callCenterAgent, userRole.AgentManager],
      },
    ],
  },
  {
    name: "Statistics",
    icon: <StatisticsIcon className="sidebar-icon" />,
    // iconColor: getColor(), //"hsl(300deg 100% 62%)",
    permissions: userRole.admin,
    path: "/stats/",
    children: [
      {
        name: "Chatbot Statistics",
        path: "/stats/chatbot/",
        component: ChatbotStatistics,
      },
      {
        name: "Call Center Statistics",
        path: "/stats/callcenter/",
        component: CallCenterStatistics,
      },
      {
        name: "Ticket Statistics",
        path: "/stats/ticket/",
        component: TicketStatistics,
      },
      {
        name: "Agent Statistics",
        path: "/stats/agent/",
        component: AgentStatistics,
      },
      // {
      //   name: "OLD Chatbot Statistics",
      //   path: "/stats/chatbot2/",
      //   component: ChatbotStats,
      // },

      // {
      //   name: "Intent Statistics",
      //   path: "/stats/intent/",
      //   component: IntentStats,
      // },
      // {
      //   name: "Help Desk Statistics",
      //   path: "/stats/helpdesk/",
      //   component: HelpDeskStats,
      // },
      // {
      //   name: "Survey Statistics",
      //   path: "/stats/survey/",
      //   component: SurveyStatistics,
      // },
      // {
      //   name: "OLD Agent Statistics",
      //   path: "/stats/agent2/",
      //   component: AgentStats,
      // },
      // {
      //   name: "Intent Leaderboard",
      //   path: "/stats/intent-leaderboard/",
      //   component: IntentLeaderboard,
      //   componentProps: { scope: "statistics" },
      // },
      {
        name: "Session Statistics",
        path: "/stats/session/",
        component: SessionStatistics,
      },
      {
        name: "Statistic Generator",
        path: "/stats/statistic-generator/",
        component: StatisticGenerator,
      },
      // {
      //   name: "Ticket Statistics",
      //   path: "/stats/ticket/",
      //   component: TicketStats,
      // },
    ],
  },
  {
    name: "Help Desk",
    icon: <HelpDeskIcon className="sidebar-icon" />,
    // iconColor: getColor(), //"hsl(174deg 100% 62%)",
    permissions: [userRole.admin, userRole.ticketAgent],
    path: "/helpdesk/",
    component: HelpDesk,
    children: [
      {
        name: "Ticket",
        path: "/helpdesk/ticket/",
      },
      {
        name: "Ticket Category",
        path: "/helpdesk/ticket-category/",
      },
    ],
  },
  {
    name: "Organization",
    icon: <OrganizationIcon className="sidebar-icon" />,
    // iconColor: getColor(), //"hsl(216deg 100% 62%)",

    path: "/organization/",
    component: Organization,
    permissions: [userRole.admin, userRole.organizationManager, userRole.ResourceManager],
    children: [
      {
        name: "Customer Data Format",
        path: "/organization/customer-data-format/",
        permissions: [userRole.admin, userRole.organizationManager],
      },
      {
        name: "Customers",
        path: "/organization/customers/",
        permissions: [userRole.admin, userRole.organizationManager],
      },
      {
        name: "Reservations",
        path: "/organization/reservations/",
        permissions: [userRole.admin, userRole.organizationManager, userRole.ResourceManager],
      },
      {
        name: "Reservation Generator",
        path: "/organization/reservation-generator/",
        permissions: [userRole.admin, userRole.organizationManager],
      },
      {
        name: "Announcement",
        path: "/organization/announcement/",
        permissions: [userRole.admin, userRole.organizationManager],
      },
      {
        name: "Surveys",
        path: "/organization/surveys/",
        permissions: [userRole.admin, userRole.organizationManager],
      },
      {
        name: "Filter Templates",
        path: "/organization/filter-templates/",
        permissions: [userRole.admin, userRole.organizationManager],
      },
      {
        name: "Action Templates",
        path: "/organization/action-templates/",
        permissions: [userRole.admin, userRole.organizationManager],
      },
    ],
  },
  process.env.BUILD_ENV === "development" && {
    name: "Integration",
    icon: <MdOutlineExtension className="fs-3 className=" />,
    // iconColor: getColor(), //"hsl(258deg 100% 62%)",
    permissions: userRole.admin,
    path: "/integration/",
    component: Integration,
    children: [
      {
        name: "Module",
        path: "/integration/module/",
      },
      {
        name: "Module Integration",
        path: "/integration/module-integration/",
      },
    ],
  },
  // {
  //   name: "Projects",
  //   isLabel: true,
  //   permissions: userRole.admin,
  // },
  {
    name: "Settings",
    icon: <SettingsIcon className="sidebar-icon" />,
    // iconColor: getColor(), //"hsl(342deg 100% 62%)",
    permissions: userRole.admin,
    path: "/settings/",
    component: Settings,
    position: "bottom",
    children: [
      {
        name: "Channels",
        path: "/settings/channels/",
      },
      {
        name: "Project",
        path: "/settings/project/",
      },
      {
        name: "Popup",
        path: "/settings/popup/",
      },
      {
        name: "User",
        path: "/settings/user/",
      },
      {
        name: "Templates",
        path: "/settings/message-template/",
      },
      {
        name: "Assets",
        path: "/settings/assets/",
      },
      {
        name: "System Templates",
        path: "/settings/system-message-template/",
        onlySuperuser: true,
      },
      {
        name: "Guide Definition",
        path: "/settings/guide-definition/",
        onlySuperuser: true,
      },
    ].filter(Boolean),
  },
].filter(Boolean);

export const authProtectedRoutes = [];

//traverse nested and push authProtectedRoutes
function getRoutesFromMenu(
  menuItem,
  parentItem,
  {
    parentOrCurrentPermission: parentOrCurrentPermissionProp,
    parentOrCurrentOnlySuperuser: parentOrCurrentOnlySuperuserProp,
    parentOrCurrentNoPermission: parentOrCurrentNoPermissionProp,
  } = {}
) {
  const children = menuItem?.children || [];

  const parentOrCurrentPermission = menuItem?.permissions || parentOrCurrentPermissionProp;
  const parentOrCurrentOnlySuperuser = menuItem?.onlySuperuser || parentOrCurrentOnlySuperuserProp;
  const parentOrCurrentNoPermission = menuItem?.noPermission || parentOrCurrentNoPermissionProp;

  const activeComponent = menuItem?.component || parentItem?.component;

  if (activeComponent && menuItem?.path) {
    authProtectedRoutes.push({
      name: menuItem.name,
      path: menuItem.path,
      component: activeComponent,
      componentProps: menuItem.componentProps,
      permissions: parentOrCurrentPermission,
      onlySuperuser: parentOrCurrentOnlySuperuser,
      noPermission: parentOrCurrentNoPermission,
    });
  }
  if (children?.length) {
    for (const child of children) {
      getRoutesFromMenu(child, menuItem, {
        parentOrCurrentPermission,
        parentOrCurrentOnlySuperuser,
        parentOrCurrentNoPermission,
      });
    }
    authProtectedRoutes.push({
      name: menuItem.name,
      redirect: true,
      path: menuItem.path,
      to: children[0].path,
      permissions: parentOrCurrentPermission,
      onlySuperuser: parentOrCurrentOnlySuperuser,
      noPermission: parentOrCurrentNoPermission,
    });
  }
}

function loadRoutes() {
  for (const menuItem of sidebarMenuData) {
    getRoutesFromMenu(menuItem);
  }
}
loadRoutes();
