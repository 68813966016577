import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

import styled from "@emotion/styled";

import { ReactComponent as HamburgerIcon } from "~assets/images/icons/sidebar-icon-hamburger.svg";
import PRButton from "~components/Generic/PRButton";
import LanguageDropdown from "~components/LanguageDropdown";
import NotificationDropdown from "~components/VerticalLayout/Header/NotificationDropdown";
import { userRole } from "~constants";
import { setSidebar } from "~store/actions";
import { selectSidebar } from "~store/layout/selectors";
import { selectCurrentProject } from "~store/user/selectors";

import HeaderDivider from "./HeaderDivider";
import LiveChatHeader from "./LiveChatHeader";
import MyProfile from "./MyProfile";
import ProjectSelector from "./ProjectSelector";

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fafafa;
  z-index: 1100;

  .navbar-header {
    padding-left: 2.25rem;
    /* padding-right: 1.25rem; */

    @media (max-width: 575.98px) {
      padding-left: 1.5rem 0.75rem;
    }
    @media (max-width: 767.98px) {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
    @media (max-width: 991.98px) {
      padding-left: 20px !important;
    }
    .collapse-button {
      padding-left: 0px !important;
    }
  }
  .col-auto {
    min-width: 32px;
    @media (max-width: 575.98px) {
      min-width: 20px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const livechatValidRoles = [userRole.admin, userRole.callCenterAgent];
const Header = () => {
  const dispatch = useDispatch();

  const sidebar = useSelector(selectSidebar);

  function handleClickToggleSidebar() {
    const sidebarData = {
      open: sidebar.mobile ? !sidebar.open : false,
      minimized: sidebar.mobile ? false : !sidebar.minimized,
    };

    dispatch(setSidebar(sidebarData));
  }

  const currentProject = useSelector(selectCurrentProject);

  const hasLivechatAccess = livechatValidRoles.some((role) => currentProject?.permissions?.includes(role));
  return (
    <StyledHeader>
      <Row className="g-0 navbar-header flex-nowrap">
        <Col xs="auto">
          <PRButton
            className="font-size-16 collapse-button"
            color="link"
            icon={() => <HamburgerIcon />}
            tooltipDelay={1000}
            onClick={handleClickToggleSidebar}
            {...(!sidebar.mobile && {
              tooltipText: "Toggle Sidebar",
            })}
          />
        </Col>
        <Col xs className="me-2">
          <ProjectSelector />
        </Col>
        <Col xs="auto">
          <LanguageDropdown />
        </Col>
        {hasLivechatAccess && (
          <>
            <Col xs="auto">
              <HeaderDivider />
            </Col>
            <Col xs="auto">
              <LiveChatHeader />
            </Col>
          </>
        )}
        <Col xs="auto">
          <HeaderDivider />
        </Col>
        <Col xs="auto">
          <NotificationDropdown />
        </Col>
        <Col xs="auto">
          <HeaderDivider />
        </Col>
        <Col xs="auto">
          <MyProfile />
        </Col>
      </Row>
    </StyledHeader>
  );
};

export default Header;
