import { useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { useSelector } from "react-redux";
import { Col, Label, Row } from "reactstrap";

import useScrollDirection from "~common/hooks/useScrollDirection";
import PRContainer from "~components/Generic/PRContainer";
import PRPage from "~components/Generic/PRPage";
import PRTab from "~components/Generic/PRTab";
import PRTable from "~components/Generic/PRTable";
import PalLink from "~components/mui/PalLink";
import { apiUrlIntentLeaderboard, tableFilterStorageKey } from "~constants";
import DateHelper from "~helpers/DateHelper";
import { selectPopupSettingsState } from "~store/settings/popupSettings/selectors";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

export default function IntentLeaderboard({ scope }) {
  // const bots = useSelector(selectBots);
  const isFromStatistics = scope === "statistics";
  const [filterSticked, setFilterSticked] = useState(false);
  const popupSettings = useSelector(selectPopupSettingsState);
  const currentBot = useSelector(selectCurrentBot);
  const scrollDirection = useScrollDirection(document.getElementById("main-scroll"));

  const [genericQueryFilter, setGenericQueryFilter] = useState({
    from_date: DateHelper.getDateTimeLocal().startOf("day"),
    filter_day: 1,
    view_type: 1,
  });

  const currentProject = useSelector(selectCurrentProject);

  useEffect(() => {
    const positionHandler = () => {
      const filterDom = document.getElementById("filter-col");
      if (!filterDom) return;

      const { top } = filterDom.getBoundingClientRect();
      const limit = 110; // header + padding-top
      // const limit = 70 + 24; // header + padding-top
      if (top <= limit) {
        setFilterSticked(true);
      } else if (scrollDirection === "up") {
        setFilterSticked(false);
      }
    };

    const filterDom = document.getElementById("filter-col");
    if (!filterDom) return;

    const mainDom = document.getElementById("main-scroll");
    mainDom.addEventListener("scroll", positionHandler);

    return () => {
      mainDom.removeEventListener("scroll", positionHandler);
    };
  }, [scrollDirection]);

  const handleChangeGenericFilter = (key) => (value) => {
    let newFilter = {};

    const newState = {
      ...genericQueryFilter,
      [key]: value,
      ...newFilter,
    };
    setGenericQueryFilter(newState);
  };

  const { selectedListUrl, columns } = useMemo(() => {
    const url = genericQueryFilter?.view_type === 1 ? apiUrlIntentLeaderboard.listTag : apiUrlIntentLeaderboard.list;

    const columns =
      genericQueryFilter?.view_type === 1
        ? [
            {
              label: "Tag",
              key: "key",
              render: (row) => {
                return (
                  <PalLink newTab to={`/chatbot/intent-tags/form/${row.id}/`}>
                    {row.key}
                  </PalLink>
                );
              },
            },
            {
              label: "Show Count",
              key: "show_count",
            },
            popupSettings.show_thumps_up && {
              label: "Like Count",
              key: "like_count",
            },

            popupSettings.show_thumps_up && {
              label: "Dislike Count",
              key: "dislike_count",
            },
            // {
            //   label: "Bot",
            //   key: "bot",
            //   render: (row) => {
            //     const bot = bots.find((bot) => bot.id === row.bot);
            //     return <>{bot?.name}</>;
            //   },
            // },
          ].filter(Boolean)
        : [
            {
              label: "Intent",
              key: "text",
              render: (row) => {
                return (
                  <PalLink newTab to={`/chatbot/intent/form/${row.id}/`}>
                    {row.text}
                  </PalLink>
                );
              },
            },
            {
              label: "Display Text",
              key: "display_text",
            },
            {
              label: "View Count",
              key: "view_count",
            },
            popupSettings.show_thumps_up && {
              label: "Like Count",
              key: "like_count",
            },
            popupSettings.show_thumps_up && {
              label: "Dislike Count",
              key: "dislike_count",
            },
            // {
            //   label: "Bot",
            //   key: "bot",
            //   render: (row) => {
            //     const bot = bots.find((bot) => bot.id === row.bot);
            //     return <>{bot?.name}</>;
            //   },
            // },
          ].filter(Boolean);
    return {
      selectedListUrl: url,
      columns,
    };
  }, [genericQueryFilter?.view_type, popupSettings.show_thumps_up]);

  return (
    <PRContainer
      noAction
      className={"pr-agent-stats"}
      description={"Here you can see the statistics of intent usage."}
      name={isFromStatistics ? "Statistics" : "Chatbot"}
      parentName={"Intent Leaderboard"}
    >
      <Row>
        <Col
          className={classNames("pr-stats-filter-col", {
            "pr-stats-filter-col-sticked": filterSticked,
          })}
          id="filter-col"
          sm={12}
        >
          <PRPage headerColor="primary" title={!filterSticked && "Filter"}>
            <Row className="mt-2 g-2">
              <Col md={8} sm={12}>
                <Label>Period</Label>
                <PRTab
                  border
                  fullWidth
                  pill
                  tab={genericQueryFilter?.filter_day}
                  tabList={[
                    // { id: 1, label: "Hourly" },
                    { id: 1, label: "Today" },
                    { id: 7, label: "Last Week" },
                    { id: 30, label: "Last Month" },
                    { id: 365, label: "Last Year" },
                  ]}
                  onChange={handleChangeGenericFilter("filter_day")}
                />
              </Col>
              <Col md={4} sm={12}>
                <Label>View Type</Label>
                <PRTab
                  border
                  fullWidth
                  pill
                  tab={genericQueryFilter?.view_type}
                  tabList={[
                    // { id: 1, label: "Hourly" },
                    { id: 1, label: "Tag" },
                    { id: 2, label: "Intent" },
                  ]}
                  onChange={handleChangeGenericFilter("view_type")}
                />
              </Col>
            </Row>
          </PRPage>
        </Col>
        <Col xs={12}>
          <PRPage title={`${genericQueryFilter?.view_type === 2 ? "Intents" : "Intent Tags"} for ${currentBot?.name}`}>
            <PRTable
              inline
              columns={columns}
              memoizeAsQuery={false}
              storageKey={tableFilterStorageKey.intentLeaderboardList}
              url={`${selectedListUrl.format(currentProject?.id)}?day=${genericQueryFilter?.filter_day}&bot=${
                currentBot?.id
              }`}
            />
          </PRPage>
        </Col>
      </Row>
    </PRContainer>
  );
}
