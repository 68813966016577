import { apiUrlReservationGenerator } from "~constants";
import Network from "~helpers/Network";

import * as at from "./actionTypes";

export const setReservationGenerator = (payload) => ({
  type: at.SET_RESERVATION_GENERATOR,
  payload,
});

export const getReservationGenerator = (projectId, id) => (_dispatch, _getState) => {
  const url = `${apiUrlReservationGenerator.get.format(projectId)}${id}/`;
  return Network.request(url, {
    loading: true,
    onSuccess: setReservationGenerator,
  });
};

export const createOrUpdateReservationGenerator =
  (projectId, data, options = {}) =>
  (_dispatch, _getState) => {
    let url = `${apiUrlReservationGenerator.get.format(projectId)}`;
    if (data.id) {
      url += data.id + "/";
    }

    return Network.request(url, {
      method: data.id ? "PUT" : "POST",
      data,
      loading: true,
      successMsg: true,
      ...options,
    });
  };

export const deleteReservationGenerator =
  (projectId, generatorId, options = {}) =>
  (_dispatch, _getState) => {
    const url = `${apiUrlReservationGenerator.get.format(projectId)}${generatorId}/`;
    return Network.request(url, {
      method: "DELETE",
      loading: true,
      ...options,
    });
  };

export const generateReservationGenerator =
  (projectId, generatorId, options = {}) =>
  (_dispatch, _getState) => {
    const url = `${apiUrlReservationGenerator.generate.format(projectId, generatorId)}`;
    return Network.request(url, {
      method: "POST",
      loading: true,
      successMsg: false,
      ...options,
    });
  };
