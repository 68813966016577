import { useMemo } from "react";

import styled from "@emotion/styled";

import PRTooltip from "~components/Generic/PRTooltip";
import Utils from "~helpers/Utils";

import SimpleMessage from "../SimpleMessage";

const StyledDiv = styled.div`
  width: 300px;
  .image-container {
    height: auto;
    min-height: 50px;
    max-height: 70vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 2px 2px 0px #0000000a;
    border: 1px solid #e8e8e8;
    border-radius: ${(props) => (props.position !== "right" ? "14px 14px 14px 0px" : "14px 14px 0px 14px")};
    opacity: 1;
    overflow: hidden;
    user-select: none;
    cursor: ${(props) => (props.isBroken ? "default" : "pointer")};
    &img {
      height: auto;
      //stretch width
      width: 100%;
      //align center image
      object-fit: contain;
    }

    color: ${(props) => (props.position === "right" ? props.theme.palette.primary.contrastText : "inherit")};
  }
  .address-container {
    margin-top: 10px;
    div {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .address-small {
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

const getStaticMapUrl = (lat, lng) => {
  const baseUrl = "https://maps.googleapis.com/maps/api/staticmap";
  const params = {
    center: `${lat},${lng}`,
    zoom: 15,
    size: "300x200",
    markers: `color:red|${lat},${lng}`,
    key: window.pr_config.google,
  };
  return `${baseUrl}${Utils.qs(params)}`;
};
function createGoogleMapsLink(latitude, longitude) {
  const myLocationParams = {
    query: `${latitude?.toFixed(6)},${longitude?.toFixed(6)}`,
  };
  const googleMapsURL = Utils.qs(myLocationParams, "https://www.google.com/maps/search/?api=1");
  return googleMapsURL;
}

export default function LocationMessage({
  format,
  position,
  messageTime,
  isSent,
  messageId,
  likeStatus,
  locationInfo = {},
  historyId,
  isLast,
  sender,
}) {
  const { name, address, latitude, longitude } = locationInfo;

  const { mapUrl, mapLink } = useMemo(() => {
    return {
      mapUrl: getStaticMapUrl(latitude, longitude),
      mapLink: createGoogleMapsLink(latitude, longitude),
    };
  }, [latitude, longitude]);

  return (
    <SimpleMessage
      format={format}
      historyId={historyId}
      isLast={isLast}
      isSent={isSent}
      likeStatus={likeStatus}
      messageId={messageId}
      messageTime={messageTime}
      position={position}
      sender={sender}
    >
      <StyledDiv position={position}>
        <a href={mapLink} rel="noreferrer" target="_blank">
          <img
            className="image-container"
            src={mapUrl}
            //   onClick={handleClickImagePreview}
            //   onBroken={handleBroken}
          />
        </a>

        <div className="address-container">
          {name && <div className="address-name">{name}</div>}
          <PRTooltip title={address}>
            <div className="address-small">{address}</div>
          </PRTooltip>
        </div>
      </StyledDiv>
    </SimpleMessage>
  );
}
