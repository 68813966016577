import { memo, useEffect, useMemo, useState } from "react";

import chroma from "chroma-js";
import classNames from "classnames";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";

import { ReactComponent as WebChatCheckboxCheckedIcon } from "~assets/images/icons/webchat-checkbox-checked.svg";
import { ReactComponent as WebChatCheckboxIcon } from "~assets/images/icons/webchat-checkbox.svg";
import PRButton from "~components/Generic/PRButton";
import { selectButtonSelectLoading } from "~store/socket/chatbot/selectors";

import { usePRMessagesContext, usePROnClickChoiceContext, usePRPreviewModeContext } from "../../context";
import SimpleMessage from "../SimpleMessage";

function AutoDisableButton({ messageTime, disabled, style, position, ...props }) {
  const messages = usePRMessagesContext();
  const styles = {};
  let isLastMessage = true;
  if (messages?.[messages?.length - 1]?.messageTime !== messageTime || disabled) {
    isLastMessage = false;
  }
  return (
    <div
      className="disabled-50 d-flex align-items-center"
      disabled={disabled || !isLastMessage}
      style={{ position: "relative" }}
    >
      <PRButton {...props} style={{ ...style, ...styles }} />
    </div>
  );
}

const StyledDiv = styled.div`
  gap: 10px;
  .chip-button {
    border-radius: 5px;
    background-color: #fff;
    border: 2px solid #bcbcbc;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #535353;

    text-align: start;
    padding: calc(9px - 2px) 10px; // -2 for border
    min-height: 36px;
    line-height: 1;
    background-color: #f8f8f8;
    &-inline {
      gap: 8px;
    }
  }
  .chat-checkbox {
    width: 18px;
    height: 18px;
    flex: 1 0 18px;
    overflow: visible;
    /* margin-right: 7px; */
    /* color: #979797; */
    /* border-width: 1px; */
  }

  .button-selected {
    border-color: ${(props) => props.selectedColor};
    color: ${(props) => props.selectedColor};
  }
  .svg-primary {
    color: ${(props) => props.selectedColor} !important;
  }
  .button-row {
    gap: 10px;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  /* margin: 2px;
  margin-right: 6px; */
`;

const StyledWebChatCheckboxCheckedIcon = styled(WebChatCheckboxCheckedIcon)`
  & > path:first-of-type {
    fill: ${(props) => props.selectedColor} !important ;
  }
`;

function ChoicesMessage({
  historyId,
  isSent,
  format,
  text,
  position,
  choices,
  messageTime,
  messageId,
  likeStatus,
  isLast,
}) {
  const theme = useTheme();
  const [isClicked, setIsClicked] = useState(false);
  const onClickChoice = usePROnClickChoiceContext();
  const buttonSelectLoading = useSelector(selectButtonSelectLoading);
  const hasAnySelected = choices.some((choice) => choice.isSelected);
  const [inlineSelected, setInlineSelected] = useState(null);
  const [loadingDone, setLoadingDone] = useState(-2);

  const previewMode = usePRPreviewModeContext();

  useEffect(() => {
    //Prevent to showing loading icon when user even click on never selection buttons
    if (loadingDone === -2 && !buttonSelectLoading) {
      setLoadingDone(hasAnySelected ? 1 : -1);
    } else if (loadingDone === -1 && buttonSelectLoading) {
      setLoadingDone(0);
    } else if (loadingDone === 0 && !buttonSelectLoading) {
      setLoadingDone(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonSelectLoading, loadingDone]);

  const selectedColor = useMemo(() => {
    let colorValue = theme.palette.primary.main;
    const defaultLeftBackgroundColor = position === "left" ? "#f8f8f8" : theme.palette.primary.main;

    const primarySelectedColorChroma = theme.palette.primary.main; //position === "left" ? theme.palette.primary.main : theme.palette.primary.contrastText; // "rgba(0, 0, 0, 0.7)"

    const contrastDifference = chroma.contrast(primarySelectedColorChroma, defaultLeftBackgroundColor);
    if (4 > contrastDifference) {
      colorValue = chroma(primarySelectedColorChroma)
        .darken(4 - contrastDifference)
        .hex();
    }
    return colorValue;
  }, [position, theme.palette.primary.main]);

  const loading = loadingDone < 1 && buttonSelectLoading;
  return (
    <StyledDiv
      className={classNames("d-flex flex-column ", {
        "align-items-start": position === "left",
        "align-items-end": position === "right",
      })}
      selectedColor={selectedColor}
    >
      {text && (
        <SimpleMessage
          format={format}
          historyId={historyId}
          isLast={isLast}
          isSent={isSent}
          likeStatus={likeStatus}
          messageId={messageId}
          messageTime={messageTime}
          position={position}
          text={text}
        />
      )}

      <Row
        className={classNames("button-row g-0 d-flex", {
          "flex-row": position === "left",
          "flex-row-reverse": position === "right",
        })}
      >
        {choices.map((choice, i) => {
          const handleClick = (choice) => () => {
            if (previewMode) return;
            setIsClicked(true);
            onClickChoice(choice.value, choice);
            setInlineSelected(choice.value);
          };
          const isSelected = choice.isSelected || inlineSelected === choice.value;
          return (
            <Col key={i} xs={"auto"}>
              <AutoDisableButton
                className={classNames("chip-button d-flex align-items-center justify-content-center", {
                  "button-selected": isSelected,
                })}
                disabled={!!choice.disabled || isClicked || hasAnySelected || loading}
                messageTime={messageTime}
                onClick={handleClick(choice)}
              >
                <span className="chip-button-inline d-flex align-items-center">
                  {!isSelected && <WebChatCheckboxIcon className="chat-checkbox" />}
                  {!loading && isSelected && (
                    <StyledWebChatCheckboxCheckedIcon
                      className="svg-primary chat-checkbox"
                      selectedColor={selectedColor}
                    />
                  )}
                  {loading && isSelected && <StyledCircularProgress color="primary" size={18} />}

                  <span>{choice.text}</span>
                </span>
              </AutoDisableButton>
            </Col>
          );
        })}
      </Row>
    </StyledDiv>
  );
}

const ChoicesMessageMemoized = memo(ChoicesMessage, (prevProps, nextProps) => {
  return (
    prevProps.text === nextProps.text &&
    prevProps.position === nextProps.position &&
    prevProps.colorPrimary === nextProps.colorPrimary &&
    prevProps.messageId === nextProps.messageId &&
    prevProps.historyId === nextProps.historyId &&
    prevProps.isLast === nextProps.isLast &&
    prevProps.isSent === nextProps.isSent &&
    prevProps.format === nextProps.format &&
    prevProps.messageTime === nextProps.messageTime &&
    prevProps.likeStatus === nextProps.likeStatus &&
    _.isEqual(prevProps.choices, nextProps.choices)
  );
});

export default ChoicesMessageMemoized;
