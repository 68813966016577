import { useMemo } from "react";

import { withCardon } from "cardon";
import classNames from "classnames";
import { useFormik } from "formik";
import { Col, Label, Modal, Row } from "reactstrap";
import * as Yup from "yup";

import PRButton from "~components/Generic/PRButton";
import PRInput from "~components/Generic/PRInput";
import { modalZIndex } from "~components/Generic/PRModal";

function DynamicForm({ get, title, submitText = "Submit", fields = [], onSubmit }) {
  const initialValues = useMemo(
    () =>
      fields.reduce((acc, field) => {
        acc[field.name] = field.defaultValue;
        return acc;
      }, {}),
    [fields]
  );

  const validationSchema = useMemo(() => {
    const yupFields = fields.reduce((acc, field) => {
      let defaultValidation;
      if (field.required) {
        defaultValidation = Yup.mixed().required(`${field.label} is required field`);
      }
      acc[field.name] = field.validation || defaultValidation;
      return acc;
    }, {});
    return Yup.object(yupFields).shape();
  }, [fields]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        onSubmit && (await onSubmit?.(values));
      } catch {}
      get(values)();
    },
  });

  return (
    <Modal centered isOpen={true} toggle={get(false)} zIndex={modalZIndex}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">{title}</h5>
        <button aria-label="Close" className="close" data-dismiss="modal" onClick={get(false)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body mb-3">
        <Row>
          {fields.map((field, index) => {
            const { colProps, label, type, name, placeholder, ...rest } = field;
            const isInvalid = formik.touched[name] && formik.errors[name];
            return (
              // <div className="form-group" key={index}>

              <Col key={index} xs={12} {...colProps} className={classNames(colProps?.className, "mb-2")}>
                <Label>{label}</Label>
                <PRInput
                  invalid={isInvalid}
                  name={name}
                  type={type}
                  value={formik.values[name]}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder={placeholder}
                  //   disabled={formik.isSubmitting}
                  {...rest}
                />
              </Col>
              // </div>
            );
          })}
        </Row>
      </div>
      <div className="modal-footer">
        <PRButton outline color="primary" data-dismiss="modal" onClick={get(false)}>
          Close
        </PRButton>
        <PRButton color="primary" type="button" onClick={formik.handleSubmit}>
          {submitText}
        </PRButton>
      </div>
    </Modal>
  );
}
const DynamicFormModal = withCardon(DynamicForm, { destroyOnHide: true });
export default DynamicFormModal;
