import PRSwitch from "~components/Generic/PRSwitch";

import SurveyEdit from "./SurveyEdit";
import SurveyList from "./SurveyList";

export default function Surveys() {
  const routes = [
    { path: "/organization/surveys/form/:id?", component: SurveyEdit },
    { path: "/organization/surveys", component: SurveyList },
  ];
  return <PRSwitch routes={routes} />;
}
