import { useEffect } from "react";

import { useStorage } from "./useStorage";

/**
 * Hook to track copy-paste history
 *
 * @example
 *   const copyPasteHistory = useCopyPasteHistory(10);
 *   console.log(copyPasteHistory);
 *   // [
 *   //   { type: "copy", text: "Hello", timestamp: "2021-09-01T12:00:00.000Z" },
 *   //   { type: "paste", text: "World", timestamp: "2021-09-01T12:01:00.000Z" },
 *   //   ...
 *   // ]
 *
 * @param {number} historyCount - Number of history items to keep
 * @returns {Array} - Copy-paste history
 */
const useCopyPasteHistory = (historyCount = 10) => {
  const [copyPasteHistory, setCopyPasteHistory] = useStorage("lc_copy_history", []);

  const addToHistory = (newEntry) => {
    setCopyPasteHistory((prevHistory) => {
      const updatedHistory = [newEntry, ...prevHistory];
      return updatedHistory.slice(0, historyCount);
    });
  };

  useEffect(() => {
    const handleCopy = (event) => {
      const copiedText = event.clipboardData.getData("Text");
      addToHistory({ type: "copy", text: copiedText, timestamp: new Date() });
    };

    const handlePaste = (event) => {
      const pastedText = event.clipboardData.getData("Text");
      addToHistory({ type: "paste", text: pastedText, timestamp: new Date() });
    };

    document.addEventListener("copy", handleCopy);
    document.addEventListener("paste", handlePaste);

    // Cleanup the event listeners on unmount
    return () => {
      document.removeEventListener("copy", handleCopy);
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

  return copyPasteHistory;
};

export default useCopyPasteHistory;
